.emailverifyfailed{
   background-color: #f7f8fc;
   min-height: 790px;

   .smallbox2{
       background-color: #fff;
       border-radius: 20px;
       height: 410px !important;
       width:38%;
       transform: translate(82%, 1%);
       padding: 40px 40px;
       box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
       text-align: center;
       display: block;

       .tick{
           width: 25%;
           transform:translate(-10%, 30%);
       }
       h3{
           font-size: 16px;
           font-weight: 550;
           color: #606060;
           margin-bottom:20px;
       }
       .signupbtn{
         background-color: #fff;
         color: #3377ef;
         text-align:center;
         padding: 10px 40px;
         border-radius: 5px;
         // margin-left: 40px;
         font-size: 13px;
         font-weight: 600;
         border: 1px solid #3377ef;
     }
       
   }
}
.reset{
    background-color: #f7f8fc;
    .bigboxes{
        
        height: 600px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            // margin: 50px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: -30px; 
           margin-left: 210px;
           width:70%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 35px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 90%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 50px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 70px;
                width:79%;
                margin-top: 25px;
                font-size: 17px;
                font-weight: 600;
                border: 0px;
            }
            
              .disable{
                 background-color: #D7E5FE;
                 color: #606060;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 width:79%;
                 margin-left:50px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
@media(min-width: 280px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 25px;
            margin-left: 0px;
            font-size: 15px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 0px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -300px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 150px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 0px; 
           
             .form-text{
                 font-size: 10px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 12px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 5px;
                 padding-bottom: 5px;
                 padding-left: 12px;

              }
              i{
                  position: absolute;
                  left: 80%;
                  top: 12%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 20px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 14px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 10px 10px!important;
                font-size: 17px;
                margin-left:15px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 10px 10px!important;
                 font-size: 14px;
                 margin-left:15px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

} 
}
@media (min-width: 320px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            font-size: 23px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -340px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 15px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 360px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 20px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 20px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -380px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 375px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -390px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 411px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -430px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 411px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -430px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 430px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -450px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 450px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -470px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 470px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -490px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 490px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -510px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 500px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -520px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 520px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -540px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 540px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -430px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: -20px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 91%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 91%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 768px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 750px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            margin-left: 40px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 22px;
            margin-left: 40px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -690px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 250px; 
           margin-left: 45px;
           width:90%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 55px; 
           
             .form-text{
                 font-size: 25px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 75px!important;
                font-size: 18px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 78%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:70px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 1024px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 850px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            font-size: 55px;
            line-height: 70px;
            margin-left: 70px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 30px;
            margin-left: 70px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -830px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 350px; 
           margin-left: 20px;
           width:90%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 55px; 
           
             .form-text{
                 font-size: 25px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                font-size: 20px!important;
                margin-left: 75px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 79%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:70px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 79%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:70px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}

@media (min-width: 1200px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 5%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
            font-size: 35px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 500px;
            margin-top: -0px;
            width:55%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .forgot{
            margin-left: 55px!important;
            font-size: 15px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 15px;
            width: 79%;
            border-radius: 10px;
            margin-left: 58px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 15px;
          width: 79%;
          border-radius: 10px;
          margin-left: 50px!important;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 1400px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 5%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
            font-size: 35px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 500px;
            margin-top: -0px;
            width:55%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .forgot{
            margin-left: 55px!important;
            font-size: 15px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 15px;
            width: 79%;
            border-radius: 10px;
            margin-left: 58px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 15px;
            width: 79%;
          border-radius: 10px;
          margin-left: 50px!important;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}

}


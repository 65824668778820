html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Sofia Pro', sans-serif !important;
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./_assets/fonts/Sofia\ Pro\ Regular.ttf');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 600;
  src: url('./_assets/fonts/Sofia\ Pro\ Semi\ Bold.ttf');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 700;
  src: url('./_assets/fonts/Sofia\ Pro\ Bold.ttf');
}
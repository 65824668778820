.pin{
   
    

   .bigboxes{
   background-color: #f7f8fc;
   margin-top: 0px;
   display: flex;
   .text{
      margin-top: 7%;
  }
  h3{
      color: #000;
      text-align: left;
      font-weight: 700;
      line-height: 40px;
      margin-left: 100px;
  }
  p{
      color: #000;
      font-size: 14px;
      margin-left: 100px;

      &:hover a{
          text-decoration: none!important;
      }
  }
      
    

   .smallbox{
       float: right;
      p{
          color: #000000!important;
          
       //    margin-left: 350px!important;
      }
      .another{
          margin-top: 5%;
          font-size: 16px;
          font-weight: 700;
      }
       .smallbox2{
           background-color: #fff;
           border-radius: 20px;
           // min-height: 510px !important;
        //    margin-top: 3%;
           margin-left: 155px;
           width:60%;
           margin-right: 80px;
           padding: 30px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{
                    
            margin-left: 45px; 

            
            .form-text{
                font-size: 15px!important;
                font-weight: 700;
                
            }
            .input {
                outline: none;
                font-size: 14px; 
                font-weight: 600;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 42px;
                text-align: center;
                margin-bottom: 20px;

                
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 
                
                
            }
            //  i{
                //      position: absolute;
                //      left: 90%;
                //      top: 5%;
                //      cursor:pointer;
                //      color: #c4c4c4!important;
                
                //  }
                
                
                
                .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                    
                }
            }

    
            .signupbtn{
    background-color: #3377ef;
    color: #fff;
    text-align:center;
    padding: 15px 80px;
    border-radius: 10px;
    margin-left: 65px;
    font-size: 15px;
    font-weight: 600;
    border: 0px;
}
           .button{
               margin-left: 50px;
               margin-top: 35px;
               display: block!important;
               
               .btn{
                   text-transform: none!important;
               }
               .btn-primary{
                   background-color: #3377ef!important;
               }

               .pad{
                   padding: 15px 150px;
               }
           }
           

           p{
               color: #3377ef !important;;
               font-size: 15px;
               font-weight:700;
               text-align: center;
               margin-top: 20%!important;
               padding-top: 20px;
               
           }
       //     .button{
       //         background-color: #3377ef;
       //         border-radius: 5px;
       //         border: 0px;
       //         padding: 15px 30px;
       //         color: #fff;
       //         font-size: 17px;
       //         font-weight: 600;
       //         text-align: center;
       //         width: 75%;
       //         cursor: pointer;
       //         margin-top: 30px;
       //         margin-left: 60px;
       //         z-index: 1001;
       //         align-items: center;
       //         justify-content: center;

       //         &:hover{
       //             background-color: rgba( 51,119,239, 0.85);
       //             color: #fff;
       //         }
       // }
           .images{
               display: flex;
               
               
               .custom{
                   margin-top: 20px;
                   margin-left: 60px;
                   transition: all 1.5s ease;
                   

                   &:hover{
                       transform: scale(1.1);
                       
                   }
               }
               .merchant{
                   margin-top: 20px;
                   margin-left: 50px;
                   transition: all 1.5s ease;

                   &:hover{
                       transform: scale(1.1);
               }
           }
               .customer{
                   font-weight: 500;
                   text-align: center;
                   margin-left: 55px;
                   margin-top: -10px!important;

                   &:hover{
                       color: #0e52c1!important;
                   }
               }
           }
           
   }    
   

       
       h4{
           text-align: center;
           font-weight: 650;
           font-size: 20px;
       }
       p{
           font-size: 12px;
           line-height: 20px;
           color:#777679;
           font-weight: 600;
           font-style: normal;
           text-align: center;
           margin-top: 20px;
       }
   }

   }

@media(min-width: 280px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 25px;
            margin-left: 0px;
            font-size: 18px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 0px;
        }
        .forgot{
            margin-left: 0px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -300px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 150px; 
           margin-left: 50px;
          
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 20px; 
            margin-right: -40px!important;
           
             .form-text{
                 font-size: 10px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 12px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 5px;
                 padding-bottom: 5px;
                 padding-left: 12px;
                 width:20px;

              }
              i{
                  position: absolute;
                  left: 80%;
                  top: 12%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .otps{
                margin-left: -20px!important;
                font-size: 5px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 10px!important;
                font-weight: 600;
                border: 0px;
                width: 70%;
                padding: 7px 7px!important;
                font-size: 17px;
                margin-left:35px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 10px 10px!important;
                 font-size: 14px;
                 margin-left:15px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

} 
}
@media (min-width: 320px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 10px;
            font-size: 23px;
            width: 100%!important;
            
        }
        .forgot{
            margin-left: 15px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -340px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 15px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 1px;
                 padding-bottom: 1px;
                 padding-left: 5px;
                 width: 30px;
              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 73%;
                padding: 15px 15px!important;
                font-size: 17px!important;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 360px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 20px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 20px;
        }
        .forgot{
            margin-left: 25px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -380px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 5px;
                 padding-bottom: 5px;
                 padding-left: 12px;
                 width: 40px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
           
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 79%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disable{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 375px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -390px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 12px;
                 width:46px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 83%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:20px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 411px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -430px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 40px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 5px;
                 padding-bottom: 5px;
                 padding-left: 10px!important;
                 width: 40px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 67%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:55px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}

@media (min-width: 430px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -450px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 450px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -470px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 470px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -490px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 490px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -510px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 500px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -520px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 520px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -540px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: 50px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 5px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 87%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:25px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 540px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 670px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 30px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 30px;
        }
        .forgot{
            margin-left: 30px!important;
        }
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -430px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 200px; 
           margin-left: -20px;
           width:100%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 65px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;
                 width: 50px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .otps{
                margin-left: -30px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 62%;
                padding: 15px 15px!important;
                font-size: 17px!important;
                margin-left:80px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 91%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:25px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 768px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 750px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            margin-left: 40px;
            width: 100%!important;
            
        }
        .forgot{
            margin-left: 50px!important;
            font-size: 20px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        p{
            color: #000;
            font-size: 22px;
            margin-left: 40px;
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -690px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 250px; 
           margin-left: 45px;
           width:90%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 115px; 
           
             .form-text{
                 font-size: 25px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 10px;
                 padding-top: 15px;
                 padding-bottom: 10px;
                 padding-left: 12px;
                 width:57px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .otps{
                margin-left: 10px!important;
                font-size: 25px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 50%;
                padding: 15px 15px!important;
                font-size: 17px!important;
                margin-left:130px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 78%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:70px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}
@media (min-width: 992px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 850px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            font-size: 55px;
            line-height: 70px;
            margin-left: 70px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 30px;
            margin-left: 70px;
        }
        .forgot{
            margin-left: 75px!important;
            font-size: 20px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -830px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 350px; 
           margin-left: 20px;
           width:90%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 55px; 
           
             .form-text{
                 font-size: 25px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 24px; 
                 font-weight: 400;
                 border-radius: 15px;
                 padding-top: 24px;
                 padding-bottom: 20px;
                 padding-left: 12px;
                 width: 84px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 15px;
                 margin-left: 15px!important;
                 
              }
     }
            .forgot{
                font-size: 20px!important;
                margin-left: 75px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 79%;
                padding: 15px 15px!important;
                font-size: 17px;
                margin-left:70px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 79%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:70px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}   
}
@media (min-width: 1024px) {
    background-color: #f7f8fc;
    .bigboxes{
        
        min-height: 850px!important;
        margin-top: 0px;
        display: flex;
        .text{
            margin-top: 7%;
        &:hover a{
    text-decoration: none!important;
}
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            font-size: 55px;
            line-height: 70px;
            margin-left: 70px;
            width: 100%!important;
            
        }
        p{
            color: #000;
            font-size: 30px;
            margin-left: 70px;
        }
        .forgot{
            margin-left: 75px!important;
            font-size: 20px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        
        .smallbox{
            float: right;
            // margin-top: 50px!important;
            margin-left: -830px;
            p{
                color: #000000!important;
                
                //    margin-left: 350px!important;
            }
            .another{
                margin-top: 7%;
                font-size: 16px;
                font-weight: 700;
            }
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   height: 350px !important;
           margin-top: 350px; 
           margin-left: 20px;
           width:90%;
           padding: 50px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

            margin-left: 155px; 
           
             .form-text{
                 font-size: 25px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 24px; 
                 font-weight: 400;
                 border-radius: 15px;
                 padding-top: 24px;
                 padding-bottom: 20px;
                 padding-left: 12px;
                 width: 84px;

              }
              i{
                  position: absolute;
                  left: 85%;
                  top: 16%;
                  cursor:pointer;
                  color: #c4c4c4!important;
                  
              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 15px;
                 margin-left: 15px!important;
                 
              }
     }
            .forgot{
                font-size: 20px!important;
                margin-left: 75px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
           
            .signupbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
                width: 53%;
                padding: 15px 15px!important;
                font-size: 25px!important;
                margin-left:170px!important;
                margin-top: 10px;
               
              }
              .disabled{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 79%;
                 padding: 15px 15px!important;
                 font-size: 17px;
                 margin-left:70px!important;
                 margin-top: 10px;
              }
            
            
            
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }    
        
       
   h4{
       text-align: center;
       font-weight: 650;
       font-size: 20px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color:#777679;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

}
}

@media (min-width: 1200px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 5%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 50px;
            font-size: 35px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 50px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        .forgot{

        }
        
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 500px;
            margin-top: -0px;
            width:55%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 100px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
                    width:45px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .forgot{
            margin-left: 55px!important;
            font-size: 15px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 15px;
            width: 45%;
            border-radius: 10px;
            margin-left: 125px!important;
            font-size: 13px!important;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 15px;
            width: 79%;
          border-radius: 10px;
          margin-left: 50px!important;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 1400px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 5%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 50px;
            font-size: 35px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 50px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        .forgot{
            
        }
        
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 500px;
            margin-top: -0px;
            width:55%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 100px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
                    width:45px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .forgot{
            margin-left: 55px!important;
            font-size: 15px!important;
            
            &:hover a{
                text-decoration: none!important;
            }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 15px;
            width: 45%;
            border-radius: 10px;
            margin-left: 125px!important;
            font-size: 13px!important;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 15px;
            width: 79%;
          border-radius: 10px;
          margin-left: 50px!important;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
}


.notice{
   position: absolute;
   top: 0;
   left: 0;

   p{
      //  font: 600 .85rem 'SofiaPro';
       padding: .4rem 0;
       margin: 0;
       color: #000;
   }

&.success {
   background-color: #ACE4B9;
}
&.error {
   background-color: #Fc7272;
}

}
.company {
  .company-tab {
     p {
         font-size: 18px;
         font-weight: 600;
         color: #000;
     }
     .tab {
        background-color: #fff;
        border-radius: 10px;
     }
  }
}
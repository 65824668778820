.history-page {
   padding: 2.6rem 0;
   padding-right: 2.5rem;
   margin-left: 50px;
   // background-color: #f7f8fc;
   // height: 100vh;

   .history {
      margin-bottom: 2rem;
      .menu {
         background: #3377EF;
         color: #ffffff;
         margin-left: 1rem;
         font-size: 12px;
         border: 1px solid #3377EF;
         transition: all .4s ease;
         padding: 10px 17px;
         border-radius: 10px;
         
         &:hover {
            background: transparent;
            color: #3377EF;
            border: 1px solid #3377EF;
            text-decoration: none!important;
         }
         &:hover a{
            text-decoration: none!important;
         }
      }


      ul {
         list-style: none;
         padding-left: 0;
         margin-left: 45px;
         // margin-top: 20px;

         li {
            position: relative;

            &:first-child {
               margin-right: 2rem;
            }

            div {
              font-size: 14px;
              font-weight: 600;
               color: #C4C4C4;
               cursor: pointer;

               &:hover {
                  color: #3377EF98;
               }

               &.active {
                  color: #3377EF;

                  &::before {
                     content: "";
                     height: 2px;
                     width: 40%;
                     background: #3377EF;
                     position: absolute;
                     left: 0;
                     top: 1.3rem;
                     transition: 0.5s;
                  }

                  &:hover::before {
                     width: 100%;
                  }
               }
            }
         }
      }

      .price {
         font: 600 1.05rem 'SofiaPro';
         color: #000000;
      }
   }

   .search-filter {
      padding-bottom: 2rem;

      .search {
         .input-group {
            input {
               background: #FFFFFF;
               border-radius: 10px 0 0 10px;
               border: none;
               padding: .7rem 1rem .7rem 1rem;

               &::placeholder {
                  font-size: .8rem ;
                  color: #C4C4C4;
               }
            }

            .i-x {
               margin-left: -3rem;
            }

            .i-search {
               background: #3377EF;
               border-radius: 0px 10px 10px 0px;
               padding: 1.5rem;
               margin-left: 2rem;
            }
         }
      }

      .filter {
         .sort-by{
            // position: absolute;
            // top:200px;
            // right: 53px!important;
            position: relative;
            padding: 5px 15px;
            border: 1px solid #606060!important;
            color: #606060!important;
            font-size: 12px;
            font-weight: 600;
            border-radius: 5px;

            &:hover{
               border:1px solid #606060!important;
               color: #606060!important;
            }

            .sorted{
               list-style-type: none;
               right: 0px;
               left: -10px;
               top: 45px;
               width:150px;
               max-height: 180px!important;
               overflow-y: scroll;
               overflow-x: hidden;
               font-size: 12px;
               color:#000;
               // padding: 10px 10px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               li{
                  background-color: #fff;
                  padding: 13px 20px;
                  // width: 85%;
                // justify-content: center;
                  cursor: pointer;
                  // border: 1px solid #606060;
                  // position: absolute;
                  // left: 80px;
                  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

                  &:active{
                    margin-right: -40px!important;
                  // text-align: left;
                     background-color: #3377EF;
                     color: #fff;
                  }
                  &:hover{
                     background-color: #3377EF;
                     color: #fff;
                  }
               }
            }
         
         }
         .menu {
            border-radius: 13px;
            // font: 600 .8rem 'SofiaPro';
            padding: .3rem .8rem;
            color: #3377EF;
            cursor: pointer;

            &:first-child {
               border: 1px solid #3377EF;
               transition: all .4s ease;
               
              
            }
            &:hover a{
               text-decoration: none!important;
            }
         }

         

         .collaps {
            // display: inline-flex;
            // position: absolute;
            // left: 0;
            // top: 18%;
            display: inline-flex;
            position: absolute;
            left: 0;
            margin-bottom: 2rem;
               
            .collaps-item {
               background: #FFFFFF;
               border-radius: 10px;
               font-size:.9rem;
               color: #606060;
               padding: .7rem 1.5rem;
               cursor: pointer;

               .status-list {
                  top: 100%;
                  left: 0;
                  right: 0;
                  border-radius: 10px;
                  background: #ffffff;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  
                  ul {
                     list-style: none;
                     margin-bottom: 0;
                     padding-left: 0;
                     
                     li {
                        a {
                           font: normal .85rem 'SofiaPro';
                           border-radius: 10px;
                           color: #000000;
                           padding: .8rem 1.5rem;
                           
                           &:hover {
                              background: #3377EF;
                              color: #FFFFFF;
                           }
                        }
                     }
                  }
               }

               .date {
                  background: #ffffff;
                  top: 90%;
                  left: 0;
                  border-radius: 10px;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
               }
               .react-datepicker__input-container {
                  input {
                     border-radius: 10px;
                     border: 1px solid #c4c4c4;
                     margin-top: 10px;
                     padding: 5px 10px;
                     outline: 0px;
                     width: 70%;

                     &:focus-within{
                        border: 1px solid #3377ef!important;  
                     }
                  }
               }
            }

            .date-range {
               padding: 1rem .2rem ;

               .input {
                  background: #ffffff;
                  // border: none;

                  &::placeholder {
                     color: #000000;
                     padding: .5rem;
                  }
               }

               .date-range_arrow {
                  color: #fff !important;
               }
            }
         }
      }
   }

   .customer-list {
      background: #FFFFFF;
      border-radius: 20px;
      margin-left: 60px;
      width: 90%;
      padding-bottom: -60px;
      .profile {
         padding-left: 2rem !important;
         .name{
           
         }
         thead {
            margin-right: 120px!important;
            tr {
               
               
               th {
                  font-size:13px;
                  font-weight: 700;
                  color: #000000;
                  padding: 1.5rem 1rem 1rem 1.5rem;
               }
            }
         }
         
         tbody {
            .d-table-row {

               &:nth-child(odd) {                  
                  background: #fbfcff;
               }

               th {
                  font-size: 12px;
                  // padding: 1.2rem;
                  color: #000000;
               }
               td {
                  font-size: 12px;
                  color:#000;
                  vertical-align: middle;
               }

               th {
                  span {
                     padding-left: .6rem;
                  }
               }

               &:hover {
                  background: #FbFbFC;
               }

               .form-check {
                  padding: 0;

                  input {
                     margin: 0;
                     margin-left: -.8rem;
                  }
               }
            }

            .t-row {
               th,td {
                  padding: 1.7rem 0 1.7rem 2rem;
               }
            }
         }
      }
   }

   .pagination {
      margin-left: 65px;;
      ul {
         list-style: none;
         background: #FFFFFF;
         border-radius: 4px;
         margin-bottom: 0;
         padding-left: 0;
         
         li {
            font-size: 11px;
            color: #000000;
            padding: 0.2rem 0;
            
            a {
               border-radius: 4px;
               color: #000000;
               padding: 0.2rem .8rem;
               
               &.active {
                  color: #FFFFFF;
                  background: #3377EF;
               }

               &:hover {
                  background: #3377EF98;
                  border-radius: 4px;
                  color: #FFFFFF;
               }
            }
         }
      }

      a.arrow {
         background: #3377EF;
         border-radius: 50%;
         height: 1.5rem;
         width: 1.5rem;
         
         &:hover {
            background: #3377EF98;
         }
      }
   }

   @media (min-width: 280px) {
      .history {
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            margin-top: 20px;
            font-size: 9px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 17px;
            border-radius: 20px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
            &:hover a {
               text-decoration: none!important;
            }
         }
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 5px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:first-child {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 8px;
                 margin-bottom: 10px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 1px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.0rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 80%;
                     }
                  }
               }
            }
         }
   
      }
      .search-filter {
         padding-bottom: 2rem;
   
         .search {
            .input-group {
               input {
                  background: #FFFFFF;
                  border-radius: 10px 0 0 10px;
                  border: none;
                  padding: .7rem 1rem .7rem 1rem;
   
                  &::placeholder {
                     font-size:.8rem;
                     color: #C4C4C4;
                  }
               }
   
               .i-x {
                  margin-left: -3rem;
               }
   
               .i-search {
                  background: #3377EF;
                  border-radius: 0px 10px 10px 0px;
                  padding: 1.5rem;
                  margin-left: 2rem;
               }
            }
         }
   
         .filter {
            .sort-by{
               // position: absolute;
               // top:200px;
               // right: 53px!important;
               position: relative;
               padding: 3px 7px;
               border: 1px solid #606060!important;
               color: #606060!important;
               font-size: 10px;
               font-weight: 600;
               border-radius: 5px;
   
               &:hover{
                  border:1px solid #606060!important;
                  color: #606060!important;
               }
   
               .sorted{
                  list-style-type: none;

                  right: 0px;
                  left: -10px;
                  top: 45px;
                  width:120px;
                  max-height: 180px!important;
                  overflow-y: scroll;
                  font-size: 10px;
                  color:#000;
                  // margin-right: -120px!important;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     
                     margin-right: -30px!important;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            
            }
            .menu {
               border-radius: 13px;
               // font: 600 .8rem 'SofiaPro';
               padding: .3rem .8rem;
               color: #3377EF;
               cursor: pointer;
   
               &:first-child {
                  border: 1px solid #3377EF;
                  transition: all .4s ease;
                  
                 
               }
               &:hover a{
                  text-decoration: none!important;
               }
            }
   
            
   
            .collaps {
               display: inline-flex;
               position: absolute;
               left: 0;
               top: 18%;
               margin-bottom: 2rem;
                  
               .collaps-item {
                  background: #FFFFFF;
                  border-radius: 10px;
                  font-size: .9rem;
                  color: #606060;
                  padding: .7rem 1.5rem;
                  cursor: pointer;
   
                  .status-list {
                     top: 100%;
                     left: 0;
                     right: 0;
                     border-radius: 10px;
                     background: #ffffff;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                     
                     ul {
                        list-style: none;
                        margin-bottom: 0;
                        padding-left: 0;
                        
                        li {
                           a {
                              font: normal .85rem 'SofiaPro';
                              border-radius: 10px;
                              color: #000000;
                              padding: .8rem 1.5rem;
                              
                              &:hover {
                                 background: #3377EF;
                                 color: #FFFFFF;
                              }
                           }
                        }
                     }
                  }
   
                  .date {
                     background: #ffffff;
                     top: 90%;
                     left: 0;
                     border-radius: 10px;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  }
               }
   
               .date-range {
                  padding: 1rem .2rem ;
   
                  .input {
                     background: #ffffff;
                     // border: none;
   
                     &::placeholder {
                        color: #000000;
                        padding: .5rem;
                     }
                  }
   
                  .date-range_arrow {
                     color: #fff !important;
                  }
               }
            }
         }
      }
      .customer-list {
         background: #FFFFFF;
         border-radius: 20px;
         margin-left: 20px;
         width: 100%; 
         padding-bottom: -60px;
         .profile {
            padding-left: 2rem !important;
            thead {
               margin-right: 120px!important;
               tr {
                  
                  
                  th {
                     font-size:13px;
                     font-weight: 700;
                     color: #000000;
                     padding: 1.5rem 1rem 1rem 1.5rem;
                  }
               }
            }
            
            tbody {
               .d-table-row {
   
                  &:nth-child(odd) {                  
                     background: #fbfcff;
                  }
   
                  th {
                     font-size: 12px;
                     // padding: 1.2rem;
                     color: #000000;
                  }
                  td {
                     font-size: 12px;
                     color:#000;
                     vertical-align: middle;
                  }
   
                  th {
                     span {
                        padding-left: .6rem;
                     }
                  }
   
                  &:hover {
                     background: #FbFbFC;
                  }
   
                  .form-check {
                     padding: 0;
   
                     input {
                        margin: 0;
                        margin-left: -.8rem;
                     }
                  }
               }
   
               .t-row {
                  th,td {
                     padding: 1.7rem 0 1.7rem 2rem;
                  }
               }
            }
         }
      }
   }
   @media (min-width: 320px) {
      .history {
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            margin-top: 20px;
            font-size: 9px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 17px;
            border-radius: 20px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               text-decoration: none!important;
               border: 1px solid #3377EF;
            }
            &:hover a{
               text-decoration: none!important;
            }
         }
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 5px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:first-child {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 12px;
                 margin-bottom: 10px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 1px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 80%;
                     }
                  }
               }
            }
         }
   
      }
      .search-filter {
         padding-bottom: 2rem;
   
         .search {
            .input-group {
               input {
                  background: #FFFFFF;
                  border-radius: 10px 0 0 10px;
                  border: none;
                  padding: .7rem 1rem .7rem 1rem;
   
                  &::placeholder {
                     font-size:.8rem;
                     color: #C4C4C4;
                  }
               }
   
               .i-x {
                  margin-left: -3rem;
               }
   
               .i-search {
                  background: #3377EF;
                  border-radius: 0px 10px 10px 0px;
                  padding: 1.5rem;
                  margin-left: 2rem;
               }
            }
         }
   
         .filter {
            .sort-by{
               // position: absolute;
               // top:200px;
               // right: 53px!important;
               position: relative;
               padding: 5px 10px;
               border: 1px solid #606060!important;
               color: #606060!important;
               font-size: 10px;
               font-weight: 600;
               border-radius: 5px;
   
               &:hover{
                  border:1px solid #606060!important;
                  color: #606060!important;
               }
   
               .sorted{
                  list-style-type: none;

                  right: 0px;
                  left: -10px;
                  top: 45px;
                  width:120px;
                  max-height: 180px!important;
                  overflow-y: scroll;
                  font-size: 10px;
                  color:#000;
                  // margin-right: -120px!important;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     
                     margin-right: -30px!important;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            
            }
            .menu {
               border-radius: 13px;
               // font: 600 .8rem 'SofiaPro';
               padding: .3rem .8rem;
               color: #3377EF;
               cursor: pointer;
   
               &:first-child {
                  border: 1px solid #3377EF;
                  transition: all .4s ease;
                  
                 
               }
               &:hover a{
                  text-decoration: none!important;
               }
            }
   
            
   
            .collaps {
               display: inline-flex;
               position: absolute;
               left: 0;
               top: 18%;
               margin-bottom: 2rem;
                  
               .collaps-item {
                  background: #FFFFFF;
                  border-radius: 10px;
                  font-size: .9rem;
                  color: #606060;
                  padding: .7rem 1.5rem;
                  cursor: pointer;
   
                  .status-list {
                     top: 100%;
                     left: 0;
                     right: 0;
                     border-radius: 10px;
                     background: #ffffff;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                     
                     ul {
                        list-style: none;
                        margin-bottom: 0;
                        padding-left: 0;
                        
                        li {
                           a {
                              font: normal .85rem 'SofiaPro';
                              border-radius: 10px;
                              color: #000000;
                              padding: .8rem 1.5rem;
                              
                              &:hover {
                                 background: #3377EF;
                                 color: #FFFFFF;
                              }
                           }
                        }
                     }
                  }
   
                  .date {
                     background: #ffffff;
                     top: 90%;
                     left: 0;
                     border-radius: 10px;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  }
               }
   
               .date-range {
                  padding: 1rem .2rem ;
   
                  .input {
                     background: #ffffff;
                     // border: none;
   
                     &::placeholder {
                        color: #000000;
                        padding: .5rem;
                     }
                  }
   
                  .date-range_arrow {
                     color: #fff !important;
                  }
               }
            }
         }
      }
      .customer-list {
         background: #FFFFFF;
         border-radius: 20px;
         margin-left: 20px;
         width: 100%; 
         padding-bottom: -60px;
         .profile {
            padding-left: 2rem !important;
            .name{
              
            }
            thead {
               margin-right: 120px!important;
               tr {
                  
                  
                  th {
                     font-size:13px;
                     font-weight: 700;
                     color: #000000;
                     padding: 1.5rem 1rem 1rem 1.5rem;
                  }
               }
            }
            
            tbody {
               .d-table-row {
   
                  &:nth-child(odd) {                  
                     background: #fbfcff;
                  }
   
                  th {
                     font-size: 12px;
                     // padding: 1.2rem;
                     color: #000000;
                  }
                  td {
                     font-size: 12px;
                     color:#000;
                     vertical-align: middle;
                  }
   
                  th {
                     span {
                        padding-left: .6rem;
                     }
                  }
   
                  &:hover {
                     background: #FbFbFC;
                  }
   
                  .form-check {
                     padding: 0;
   
                     input {
                        margin: 0;
                        margin-left: -.8rem;
                     }
                  }
               }
   
               .t-row {
                  th,td {
                     padding: 1.7rem 0 1.7rem 2rem;
                  }
               }
            }
         }
      }
   }
   @media (min-width: 360px) {
      .history {
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            margin-top: 20px;
            font-size: 9px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 17px;
            border-radius: 20px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
            &:hover a {
               text-decoration: none!important;
            }
         }
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 10px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:first-child {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 12px;
                 margin-bottom: 10px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 1px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 80%;
                     }
                  }
               }
            }
         }
   
      }
      .search-filter {
         padding-bottom: 2rem;
   
         .search {
            .input-group {
               input {
                  background: #FFFFFF;
                  border-radius: 10px 0 0 10px;
                  border: none;
                  padding: .7rem 1rem .7rem 1rem;
   
                  &::placeholder {
                     font-size: .8rem;
                     color: #C4C4C4;
                  }
               }
   
               .i-x {
                  margin-left: -3rem;
               }
   
               .i-search {
                  background: #3377EF;
                  border-radius: 0px 10px 10px 0px;
                  padding: 1.5rem;
                  margin-left: 2rem;
               }
            }
         }
   
         .filter {
            .sort-by{
               // position: absolute;
               // top:200px;
               // right: 53px!important;
               position: relative;
               padding: 5px 10px;
               border: 1px solid #606060!important;
               color: #606060!important;
               font-size: 10px;
               font-weight: 600;
               border-radius: 5px;
   
               &:hover{
                  border:1px solid #606060!important;
                  color: #606060!important;
               }
   
               .sorted{
                  list-style-type: none;
                  right: 0px;
                  left: -10px;
                  top: 45px;
                  width:120px;
                  max-height: 180px!important;
                  overflow-y: scroll;
                  font-size: 10px;
                  color:#000;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     
                     margin-right: -30px!important;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            
            }
            .menu {
               border-radius: 13px;
               // font: 600 .8rem 'SofiaPro';
               padding: .3rem .8rem;
               color: #3377EF;
               cursor: pointer;
   
               &:first-child {
                  border: 1px solid #3377EF;
                  transition: all .4s ease;
                  
                 
               }
            }
   
            
   
            .collaps {
               display: inline-flex;
               position: absolute;
               left: 0;
               top: 18%;
               margin-bottom: 2rem;
                  
               .collaps-item {
                  background: #FFFFFF;
                  border-radius: 10px;
                  font-size: .9rem;
                  color: #606060;
                  padding: .7rem 1.5rem;
                  cursor: pointer;
   
                  .status-list {
                     top: 100%;
                     left: 0;
                     right: 0;
                     border-radius: 10px;
                     background: #ffffff;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                     
                     ul {
                        list-style: none;
                        margin-bottom: 0;
                        padding-left: 0;
                        
                        li {
                           a {
                              font: normal .85rem 'SofiaPro';
                              border-radius: 10px;
                              color: #000000;
                              padding: .8rem 1.5rem;
                              
                              &:hover {
                                 background: #3377EF;
                                 color: #FFFFFF;
                              }
                           }
                        }
                     }
                  }
   
                  .date {
                     background: #ffffff;
                     top: 90%;
                     left: 0;
                     border-radius: 10px;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  }
               }
   
               .date-range {
                  padding: 1rem .2rem ;
   
                  .input {
                     background: #ffffff;
                     // border: none;
   
                     &::placeholder {
                        color: #000000;
                        padding: .5rem;
                     }
                  }
   
                  .date-range_arrow {
                     color: #fff !important;
                  }
               }
            }
         }
      }
      .customer-list {
         background: #FFFFFF;
         border-radius: 20px;
         margin-left: 20px;
         width: 100%; 
         padding-bottom: -60px;
         .profile {
            padding-left: 2rem !important;
            .name{
              
            }
            thead {
               margin-right: 120px!important;
               tr {
                  
                  
                  th {
                     font-size:13px;
                     font-weight: 700;
                     color: #000000;
                     padding: 1.5rem 1rem 1rem 1.5rem;
                  }
               }
            }
            
            tbody {
               .d-table-row {
   
                  &:nth-child(odd) {                  
                     background: #fbfcff;
                  }
   
                  th {
                     font-size: 12px;
                     // padding: 1.2rem;
                     color: #000000;
                  }
                  td {
                     font-size: 12px;
                     color:#000;
                     vertical-align: middle;
                  }
   
                  th {
                     span {
                        padding-left: .6rem;
                     }
                  }
   
                  &:hover {
                     background: #FbFbFC;
                  }
   
                  .form-check {
                     padding: 0;
   
                     input {
                        margin: 0;
                        margin-left: -.8rem;
                     }
                  }
               }
   
               .t-row {
                  th,td {
                     padding: 1.7rem 0 1.7rem 2rem;
                  }
               }
            }
         }
      }
   }
   @media (min-width: 768px) {
      .history {
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 40px;
            // margin-top: 20px;
   
            
         }
   
      }
     
      .customer-list {
         background: #FFFFFF;
         border-radius: 20px;
         margin-left: 20px;
         width: 97%; 
         padding-bottom: -60px;
        
      }
   }
}
.phoneagain{
    background-color: #f7f8fc;
    .bigboxes{
       
    height: 600px!important;
    margin-top: 0px;
    display: flex;
        .text{
            margin-top: 7%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
        }
        .carousel-indicators{
            margin-bottom: 150px;
            border-radius: 5px;
            justify-content: center;
            li{
                height: 5px;
                width: 15px;
                background-color: rgba(196, 196, 196, 0.37);
                border-radius: 50%;

                &.active{
                    background-color: #3377ef;
                }
            }
        }
        .customerone{
            height: 700px;
        }
        .customertwo{
            height: 700px;
        }
        .customerthree{
            height: 700px;
        }
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        .firstbox{
            .image{
                .over{
                    .word{
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        margin-top: 37%;
                        h3{
                            font-size: 33px;
                            color: #fff;
                        }
                        p{
                            color: #fff;
                            font-size: 25px;
                        }
                    }
                }
            }
        }

    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
           
           margin-top: 50px; 
            margin-left: 210px;
            width:70%;
           
            padding: 30px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

            .forms{

               margin-left: 40px; 
              
                .form-text{
                    font-size: 12px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 14px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-left: 22px;

                    
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 145px;
            border-radius: 5px;
            margin-left: 40px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }

                .pad{
                    padding: 15px 150px;
                }
            }
            

            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
        //     .button{
        //         background-color: #3377ef;
        //         border-radius: 5px;
        //         border: 0px;
        //         padding: 15px 30px;
        //         color: #fff;
        //         font-size: 17px;
        //         font-weight: 600;
        //         text-align: center;
        //         width: 75%;
        //         cursor: pointer;
        //         margin-top: 30px;
        //         margin-left: 60px;
        //         z-index: 1001;
        //         align-items: center;
        //         justify-content: center;

        //         &:hover{
        //             background-color: rgba( 51,119,239, 0.85);
        //             color: #fff;
        //         }
        // }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    

                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;

                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;

                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    

        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }

    }
}


.new-company {
      background-color: #f7f8fc;
      .bigboxes{       
          height: 100vh;
          margin-top: 0px;
           
          .smallbox{
              .title {
                  
                font-size: 20px;
                font-weight: 800;
              }
              p{
                  color: #000000!important;
              }
              
              .smallbox2{
  
             .error{
              color: #f5390a;
              font-size: 13px;
             }
             background-color: #fff;
             border-radius: 20px;
            //  width:60%;
             padding: 30px 20px;
             box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
  
             .forms{
  
            //   margin-left: 45px; 
             
               .form-text{
                   font-size: 18px!important;
                   font-weight: 700;
                   margin-bottom: -5px;
               }
               .input {
                   outline: none;
                   font-size: 18px; 
                   font-weight: 400;
                   border-radius: 5px;
                   padding-top: 10px;
                   padding-bottom: 10px;
                   padding-left: 22px;
  
                }
                
                .input-wrapper {
                   border: 1px solid #c4c4c4 !important;
                   border-radius: 5px;
                   position: relative;
                  
                   &:focus-within{
                     border: 1px solid #3377ef!important;  
                   }
                }
           
                .error-text {
                   color: #f5390a!important;
                   font-weight: 400;
                   font-size: 13px;
                   
                }
       }
              .forgot{
                  margin-left: 50px!important;
                  
                  &:hover a{
                      text-decoration: none!important;
                  }
              }
              
            
              
              p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
              }
              
            }       
          }
          .signbtn{
              background-color: #3377ef;
              color: #fff;
              text-align:center;
            //   padding: 13px 55px!important;
              border-radius: 10px;
              font-weight: 600;
              border: 0px;
            //   padding: 10px 207px!important;
              font-size: 20px;
              margin-top: 10px;
             
            }
  
  }
  }

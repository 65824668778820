.customer{
   overflow-x: hidden;
    
@media (min-width: 280px) {
        .bigboxes{
            background-color: #f7f8fc;
            margin-top: -50px;
            margin-left: -15px!important;
            margin-right: -20px!important;
            
            display: flex;
            position: relative;
                .brand{
                position: absolute;
                left: 0px;
                right: 0px;
                z-index: 2;
                }
                .carousel-indicators{
                    margin-bottom: 50px;
                    border-radius: 5px;
                    justify-content: center;
                    li{
                        height: 5px;
                        width: 20px;
                        background-color: rgba(196, 196, 196, 0.37);
                        border-radius: 50%;
        
                        &.active{
                            background-color: #3377ef;
                        }
                    }
                }
    
                .customerone{
                    height: 355px;
                    width: 100%!important;
                   
                    // margin-top: -150px;
                }
                .customertwo{
                    height: 355px;
                    width: 100%!important;
                }
                .customerthree{
                    height: 355px;
                    width: 100%!important;
                }
                .text{
                    vertical-align: middle!important;
                    align-items: center!important;
                    justify-content: center!important;
                    // margin-bottom: -300px;
                    margin-top: -250px!important;
                    // margin-left: -300px;
                    
                    h5{
    
                    }
                    p{
                        margin-bottom: 5rem!important;;
                    }
                }
                
            .smallbox{
                float: right;
                position: relative;
                margin-top: 400px;
                margin-bottom: 30px;
                margin-left: -365px;
               p{
                   color: #000000!important;
                   position: absolute;
                   font-size: 15px;
                   top: -4%;
                   left: 34%;
                //    margin-left: 350px!important;
               }
               .another{
                //    margin-top: 3%;
                //    margin-left: 10px;
                   text-align: center;
                   font-size: 13px;
                   font-weight: 700;
                   margin-bottom: -3%;
               }
                .smallbox2{
                    background-color: #fff;
                    border-radius: 20px;
                    // min-height: 510px !important;
                    margin-top: 7%;
                    margin-left: 60px;
                    width:90%;
                    margin-right: 80px;
                    padding: 30px 20px;
                    box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        
                    .forms{
        
                        margin-left: 5px; 
                       
                         .form-text{
                             font-size: 10px!important;
                             font-weight: 700;
                             margin-bottom: -5px;
                         }
                         .input {
                             outline: none;
                             font-size: 15px; 
                             font-weight: 400;
                             border-radius: 5px;
                             padding-top: 5px;
                             padding-bottom: 5px;
                             padding-left: 22px;
            
                          }
                          i{
                              position: absolute;
                              left: 85%;
                              top: 16%;
                              cursor:pointer;
                              color: #c4c4c4!important;
                              
                          }
                          
                          .input-wrapper {
                             border: 1px solid #c4c4c4 !important;
                             border-radius: 5px;
                             position: relative;
                            
                             &:focus-within{
                               border: 1px solid #3377ef!important;  
                             }
                          }
                     
                          .error-text {
                             color: #f5390a!important;
                             font-weight: 400;
                             font-size: 13px;
                             
                          }
                 }
                 .sign{
                    background-color: #3377ef;
                    color: #fff;
                    text-align:center;
                    width: 87%;
                    padding: 10px 10px;
                    border-radius: 5px;
                    margin-left: 50px;
                    margin-bottom: 15px;
                    font-size: 13px;
                    font-weight: 600;
                    border: 0px;
                }
                // .signupbtn{
                //     background-color: #3377ef;
                //     color: #fff;
                //     text-align:center;
                //     padding: 13px 55px!important;
                //     border-radius: 10px;
                //     font-size: 13px;
                //     font-weight: 600;
                //     border: 0px;
                //     margin-left: 180px;
                   
                //   }
                 .able{
                     background-color: #D7E5FE;
                     color: #606060;
                     width: 87%;
                     padding: 10px 10px;
                    border-radius: 5px;
                    margin-left: 20px;
                    margin-bottom: 15px;
                    font-size: 13px;
                    font-weight: 600;
                    border: 0px;
                  }
                    .button{
                        margin-left: 50px;
                        margin-top: 35px;
                        display: block!important;
                        
                        .btn{
                            text-transform: none!important;
                        }
                        .btn-primary{
                            background-color: #3377ef!important;
                        }
        
                        .pad{
                            padding: 15px 150px;
                        }
                    }
                    
        
                    p{
                        color: #3377ef !important;;
                        font-size: 15px;
                        font-weight:700;
                        text-align: center;
                        margin-top: 20%!important;
                        padding-top: 20px;
                        
                    }
                //     .button{
                //         background-color: #3377ef;
                //         border-radius: 5px;
                //         border: 0px;
                //         padding: 15px 30px;
                //         color: #fff;
                //         font-size: 17px;
                //         font-weight: 600;
                //         text-align: center;
                //         width: 75%;
                //         cursor: pointer;
                //         margin-top: 30px;
                //         margin-left: 60px;
                //         z-index: 1001;
                //         align-items: center;
                //         justify-content: center;
        
                //         &:hover{
                //             background-color: rgba( 51,119,239, 0.85);
                //             color: #fff;
                //         }
                // }
                    .images{
                        display: flex;
                        
                        
                        .custom{
                            margin-top: 20px;
                            margin-left: 60px;
                            transition: all 1.5s ease;
                            
        
                            &:hover{
                                transform: scale(1.1);
                                
                            }
                        }
                        .merchant{
                            margin-top: 20px;
                            margin-left: 50px;
                            transition: all 1.5s ease;
        
                            &:hover{
                                transform: scale(1.1);
                        }
                    }
                        .customer{
                            font-weight: 500;
                            text-align: center;
                            margin-left: 55px;
                            margin-top: -10px!important;
        
                            &:hover{
                                color: #0e52c1!important;
                            }
                        }
                    }
                    
            }    
            
        
                
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 20px;
                }
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
}   
    
@media (min-width: 320px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -365px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 24%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 87%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}
@media (min-width: 360px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -405px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 24%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 87%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}
@media (min-width: 375px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -415px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 24%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 90%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 90%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}

@media (min-width: 411px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -450px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 27%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 91%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 91%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}
@media (min-width: 411px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -450px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 27%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 15px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 91%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 91%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}

@media (min-width: 520px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px!important;
        margin-right: -20px!important;
        
        display: flex;
        position: relative;
            .brand{
            width: 25%!important;
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 50px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }

            .customerone{
                height: 355px;
                width: 100%!important;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 355px;
                width: 100%!important;
            }
            .customerthree{
                height: 355px;
                width: 100%!important;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                // margin-bottom: -300px;
                margin-top: -250px!important;
                // margin-left: -300px;
                
                h5{

                }
                p{
                    margin-bottom: 5rem!important;;
                }
            }
            
        .smallbox{
            float: right;
            position: relative;
            margin-top: 400px;
            margin-bottom: 30px;
            margin-left: -575px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 27%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 18px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 93%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 93%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}

@media (min-width: 576px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 150px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 795px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 795px;
                width: 300px;
            }
            .customerthree{
                height: 795px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
            }
            .firstbox{
                .image{
                    .over{
                        .word{
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin-top: 37%;
                            h3{
                                font-size: 33px;
                                color: #fff;
                            }
                            p{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
    
        .smallbox{
            float: right;
            position: relative;
            margin-top: 50px;
            margin-left: -20px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -4%;
               left: 24%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 13px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 7%;
                margin-left: 25px;
                width:90%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 5px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 74%;
                padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 87%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 20px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
 }
@media (min-width: 768px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            width: 40%!important;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 250px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 1100px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 1100px;
                width: 300px;
            }
            .customerthree{
                height: 1100px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
                h5{
                    font-size: 25px;
                }
                p{
                    margin-bottom: 20rem!important;
                    font-size: 20px;
                }
            }
            .firstbox{
                .image{
                    .over{
                        .word{
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin-top: 37%;
                            h3{
                                font-size: 33px;
                                color: #fff;
                            }
                            p{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
    
        .smallbox{
            float: right;
            position: relative;
            margin-top: 200px;
            margin-left: -50px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 15px;
               top: -3%;
               left: 28%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 14px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 5%;
                margin-left: 50px;
                width:80%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 25px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 73%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 73%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 38px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
 }

@media (min-width: 992px) { 
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            width: 40%!important;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 150px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 795px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 795px;
                width: 300px;
            }
            .customerthree{
                height: 795px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
            }
            .firstbox{
                .image{
                    .over{
                        .word{
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin-top: 37%;
                            h3{
                                font-size: 33px;
                                color: #fff;
                            }
                            p{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
    
        .smallbox{
            float: right;
            position: relative;
           p{
               color: #000000!important;
               position: absolute;
               top: -2%;
               left: 33%;
               
            //    margin-left: 70px!important;
           }
           .another{
               margin-top: -1%;
               margin-left: 10px;
               text-align: center;
               font-size: 16px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 2%;
                margin-left: 70px;
                width:80%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 50px; 
                   
                     .form-text{
                         font-size: 14px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 18px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 90%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 75%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 75%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}

@media (min-width: 1024px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            width: 40%!important;
            left: 0px;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 250px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 1370px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 1370px;
                width: 300px;
            }
            .customerthree{
                height: 1370px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
                h5{
                    font-size: 30px;
                }
                p{
                    margin-bottom: 20rem!important;
                    font-size: 25px;
                }
            }
           
    
        .smallbox{
            float: right;
            position: relative;
            margin-top: 350px;
            margin-left: -50px;
           p{
               color: #000000!important;
               position: absolute;
               font-size: 20px;
               top: 0%;
               left: 20%;
            //    margin-left: 350px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: 10px;
               text-align: center;
               font-size: 20px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 5%;
                margin-left: 55px;
                width:80%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 25px; 
                   
                     .form-text{
                         font-size: 13px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 15px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 15px;
        
                      }
                      i{
                          position: absolute;
                          left: 85%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
             .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 75%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 50px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 75%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 38px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                .button{
                    margin-left: 50px;
                    margin-top: 35px;
                    display: block!important;
                    
                    .btn{
                        text-transform: none!important;
                    }
                    .btn-primary{
                        background-color: #3377ef!important;
                    }
    
                    .pad{
                        padding: 15px 150px;
                    }
                }
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}

@media (min-width: 1200px) { 
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            width: 25%!important;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 150px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 795px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 795px;
                width: 300px;
            }
            .customerthree{
                height: 795px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
                h5{

                }
                p{
                    margin-bottom: 2rem!important;;
                }
            }
            .firstbox{
                .image{
                    .over{
                        .word{
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin-top: 37%;
                            h3{
                                font-size: 33px;
                                color: #fff;
                            }
                            p{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
    
        .smallbox{
            float: right;
            margin-top: 45px;
            
           p{
               color: #000000!important;
               
               margin-left: 120px!important;
           }
           .another{
               margin-top: -3%;
            //    margin-left: -70px;
               text-align: center;
               font-size: 16px;
               font-weight: 700;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 2%;
                margin-left: 80px;
                width:80%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 50px; 
                   
                     .form-text{
                         font-size: 18px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 18px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 90%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
            .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 78%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 78%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
}
@media (min-width: 1400px) {
    .bigboxes{
        background-color: #f7f8fc;
        margin-top: -50px;
        margin-left: -15px;
        display: flex;
        position: relative;
            .brand{
            position: absolute;
            left: 0px;
            width: 25%!important;
            right: 0px;
            z-index: 2;
            }
            .carousel-indicators{
                margin-bottom: 150px;
                border-radius: 5px;
                justify-content: center;
                li{
                    height: 5px;
                    width: 20px;
                    background-color: rgba(196, 196, 196, 0.37);
                    border-radius: 50%;
    
                    &.active{
                        background-color: #3377ef;
                    }
                }
            }
            .customerone{
                height: 795px;
                width: 300px;
               
                // margin-top: -150px;
            }
            .customertwo{
                height: 795px;
                width: 300px;
            }
            .customerthree{
                height: 795px;
                width: 300px;
            }
            .text{
                vertical-align: middle!important;
                align-items: center!important;
                justify-content: center!important;
                margin-bottom: 250px;
                h5{

                }
                p{
                    margin-bottom: 2rem!important;;
                }
            }
            .firstbox{
                .image{
                    .over{
                        .word{
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin-top: 37%;
                            h3{
                                font-size: 33px;
                                color: #fff;
                            }
                            p{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
    
        .smallbox{
            float: right;
            margin-top: 45px;
            
           p{
               color: #000000!important;
               
               margin-left: 120px!important;
           }
           .another{
            //    margin-top: 3%;
            //    margin-left: -70px;
               text-align: center;
               font-size: 16px;
               font-weight: 700;
               margin-bottom: -3%;
           }
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                // min-height: 510px !important;
                margin-top: 2%;
                margin-left: 140px;
                width:65%;
                margin-right: 80px;
                padding: 30px 20px;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
    
                .forms{
    
                    margin-left: 50px; 
                   
                     .form-text{
                         font-size: 18px!important;
                         font-weight: 700;
                         margin-bottom: -5px;
                     }
                     .input {
                         outline: none;
                         font-size: 18px; 
                         font-weight: 400;
                         border-radius: 5px;
                         padding-top: 10px;
                         padding-bottom: 10px;
                         padding-left: 22px;
        
                      }
                      i{
                          position: absolute;
                          left: 90%;
                          top: 16%;
                          cursor:pointer;
                          color: #c4c4c4!important;
                          
                      }
                      
                      .input-wrapper {
                         border: 1px solid #c4c4c4 !important;
                         border-radius: 5px;
                         position: relative;
                        
                         &:focus-within{
                           border: 1px solid #3377ef!important;  
                         }
                      }
                 
                      .error-text {
                         color: #f5390a!important;
                         font-weight: 400;
                         font-size: 13px;
                         
                      }
             }
            .sign{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                width: 78%;
                padding: 15px 15px;
                margin-left: 65px;
                // padding: 15px 175px;
                border-radius: 5px;
                // margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
            }
            // .signupbtn{
            //     background-color: #3377ef;
            //     color: #fff;
            //     text-align:center;
            //     padding: 13px 55px!important;
            //     border-radius: 10px;
            //     font-size: 13px;
            //     font-weight: 600;
            //     border: 0px;
            //     margin-left: 180px;
               
            //   }
             .able{
                 background-color: #D7E5FE;
                 color: #606060;
                 width: 78%;
                 padding: 15px 15px;
                border-radius: 5px;
                margin-left: 65px;
                margin-bottom: 15px;
                font-size: 13px;
                font-weight: 600;
                border: 0px;
              }
                
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 15px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 20%!important;
                    padding-top: 20px;
                    
                }
            //     .button{
            //         background-color: #3377ef;
            //         border-radius: 5px;
            //         border: 0px;
            //         padding: 15px 30px;
            //         color: #fff;
            //         font-size: 17px;
            //         font-weight: 600;
            //         text-align: center;
            //         width: 75%;
            //         cursor: pointer;
            //         margin-top: 30px;
            //         margin-left: 60px;
            //         z-index: 1001;
            //         align-items: center;
            //         justify-content: center;
    
            //         &:hover{
            //             background-color: rgba( 51,119,239, 0.85);
            //             color: #fff;
            //         }
            // }
                .images{
                    display: flex;
                    
                    
                    .custom{
                        margin-top: 20px;
                        margin-left: 60px;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                    }
                    .merchant{
                        margin-top: 20px;
                        margin-left: 50px;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: 55px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
        
    
            
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
 }
}

@import "~bootstrap/scss/bootstrap";

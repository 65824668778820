.existinghome{
   height: 100%;
   margin-left: 60px;
   margin-top: 30px;
   margin-bottom: 30px;
   
   display: block;
   // display: inline;
  
   .first{
      width: 90%;
      min-height: 50px;
      display: flex;
      margin-bottom:30px;
      // margin-right: 20px;

      .balance{
         background-color: #fff;
         border-radius: 30px;
         height: 340px !important;
           //margin-top: 40px; 
           //margin-left: 210px;
         margin-right: 80px;
         width:110%;
         padding: 30px 20px;
         position: relative;
         // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

         .content{
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            // position: absolute;
            
            p{
               color: #c4c4c4;
               font-size: 22px;
               font-weight: 600;
               margin-left: 15px;
            }
            .emptybalance {
               background-color: #c4c4c4;
               width: 100px;
               height: 30px;
            }
            .image{
              
              margin-top: -10px;
              margin-left: 300px;
            }
            .no-image{
               width: 40px;
               height: 40px;
               border-radius: 100%;
               margin-top: -10px;
               margin-left: 250px;
               background-color: #c4c4c4;
            }
         }
         .text{
            margin-left: 15px;
            margin-top: 40px;
            h3{
               color: #000;
               font-weight: 600;
               font-size: 35px;
            }
            .nomoney {
               background-color: #c4c4c4;
               margin-bottom: 50px;
               margin-top: 20px;
               height: 50px!important;
               width: 100px;
            }
         }
         .cards{
            margin-left: 20px;
            margin-top: 55px;
            width: 42%!important;
            height: 22%!important;
            box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
            border-radius: 10px;
            border: 0px;
            
            .leftside{
               background-color: #efda33;
               height: 81%;
               margin-left: 14px;
               border-radius: 10px;
               .arrow{
                  margin-left: 5px;
                  margin-top: 15px;
               }
            }
            .rightside{
               // padding-bottom: 5px;
               p{
                  color: #c4c4c4;
                  font-size: 8px;
                  font-weight: 600;
                  margin-top: -10px;
               }
               h5{
                  color: #606060;
                  font-size: 10px;
                  font-weight: 600;
                  
               }
            }
         }
         .no-received {
            background-color: #c4c4c4;
            height: 50px!important;
            width: 100px;
            display: flex;
         }
         
         .cardss{
            margin-left: 250px;
            margin-top: -60px;
            width: 42%!important;
            height: 22%!important;
            box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
            border-radius: 10px;
            border: 0px;
            .leftside{
               background-color: #ef338a;
               height: 81%;
               margin-left: 13px;
               border-radius: 10px;
               .arrow{
                  margin-left: 5px;
                  margin-top: 15px;
               }
            }
            .no-settled {
               background-color: #c4c4c4;
               height: 150%!important;
               width: 70px;
            }
            .rightside{
               // padding-bottom: 5px;
               p{
                  color: #c4c4c4;
                  font-size: 8px;
                  font-weight: 600;
                  margin-top: -10px;
               }
               h5{
                  color: #606060;
                  font-size: 10px;
                  font-weight: 600;
                  
               }
            }
         }
      }
      .year{
         background-color: #fff;
           border-radius: 30px;
           height: 320px !important;
           //margin-top: 40px; 
           //margin-left: 210px;
           width:110%;
           padding: 30px 20px;
           position: relative;
         //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

         .duration-select{
            position: absolute;
            top: 23px;
            right: 43px;
            padding: 7px 20px;
            border: 1px solid #606060;
            color: #606060;
            font-size: 16px;
            font-weight: 600;
            border-radius: 10px;
            width: 190px!important;

            .custom-selected{
               list-style-type: none;
               right: 0;
               left: 0;
               top: 105%;
               // padding: 10px 10px;
               // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               li{
                  background-color: #fff;
                  padding: 13px 20px;
                  width: 100%;
                  // text-align: left;
                  // justify-content: center;
                  cursor: pointer;
                  // border: 1px solid #606060;
                  // position: absolute;
                  // left: 80px;
                  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

                  &:active{
                     background-color: #3377EF;
                     color: #fff;
                  }
                  &:hover{
                     background-color: #3377EF;
                     color: #fff;
                  }
               }
            }
         }
      }
   }
   .second{
      width: 100%;
      min-height: 50px;
      display: flex;
      margin-bottom:30px;
      // margin-right: 20px;

      .balance{
         // background-color: #fff;
         border-radius: 30px;
         height: 300px !important;
           margin-top: -20px; 
           //margin-left: 210px;
         margin-right: 40px;
         width:110%;
         padding: 30px 20px;
         // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

         .bal{
         background-color: #fff;
         border-radius: 10px;
         height: 50px !important;
           //margin-top: 40px; 
         margin-left: -30px;
         margin-right: 0px;
         width:102%;
         padding: 5x 10px;
         
         .no-recent-transaction {
            background-color: #c4c4c4;
            width: 100%;
            height: 50px;
         }
         .flex-container{
            padding: 0;
            margin: 0;
            width: 100%;
            list-style: none;
            
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
               
            -webkit-justify-content: space-around;
            justify-content: space-around;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-align-items: stretch;
            align-items: stretch;

            .flex-item:nth-of-type(1) { flex-grow: 1; }
            .flex-item:nth-of-type(2) { flex-grow: 1; }
            .flex-item:nth-of-type(3) { flex-grow: 2; }
            .flex-item:nth-of-type(4) { flex-grow: 1; }
            .flex-item:nth-of-type(5) { flex-grow: 1; }

            .flex-item {
  
               
              
               // border: 3px solid rgba(0,0,0,.2);
               line-height: 45px;
               color: #000000;
               font-weight: 500;
               font-size: 11px;
               text-align: center;
               
               @media (min-width:767px) {
                  font-size: 14px;
               }
             }
         }
         }
      .recent{
         color: #000;
         font-size: 18px;
         font-weight: 600;
         margin-left: -20px;
         // float:left;
      }
      .no-transaction {
         background-color: #c4c4c4;
         width: 150px;
         height: 80px;
      }
      .show{
         border: 1px solid #606060;
         color: #606060;
         border-radius: 8px;
         float:right;
         padding: 8px 20px;
         box-sizing: border-box;
         font-size: 13px;
         font-weight: 600;
         margin-top: -35px;
         margin-right: 22px;

         &:hover{
            border: none;
            background-color: #606060;
            color: #fff;
            padding: 9px 21px;

         }
      }
      .rafiki{
         margin-top: 30px;
         margin-left: 160px;
      }
      p{
         color:#606060;
         text-align: center;
         margin-top: 20px;
      }
   }
      .year{
         background-color: #fff;
           border-radius: 30px;
           height: 300px !important;
           //margin-top: 40px; 
           //margin-left: 210px;
           width:80%;
           padding: 30px 20px;
         //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
         .chat{
            margin-top: 5px;
            margin-left: 170px;
         }
         .store{
            display: flex;
            margin-top: 25px;
            .google{
               height: 10%;
               width: 20%;
               margin-left: 135px;
               margin-right: 10px;
            }
            .apple{
               width: 25%;
               height: 30%;
               margin-top: -10px;
               // margin-left: 155px;
               margin-right: 10px;
            }
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 15px;
         }
      }
   }

@media (min-width: 280px) {
      .first{
         width: 110%;
         min-height: 50px;
         margin-left: -45px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 60px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 45px;
               width: 20%!important;
               height: 12%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height:45%;
                  width: 60%;
                  margin-left: 14px;
                  margin-top: -15px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 2px;
                     margin-top: 12px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     // margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 160px;
               margin-top: -47px;
               width: 10%!important;
               height: 20%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 40%;
                  width:93%;
                  margin-left: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 2px;
                     margin-top: 10px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     font-weight: 600;
                     margin-right: -20px;
                     margin-left: -35px;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-right: -15px;
                     margin-left: -30px;
                     text-align: center;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 23px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 10px;
               font-weight: 600;
               border-radius: 10px;
               width: 150px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 70px;

               .bar{
                  height: 200px!important;
                  width: 100%!important;
               }
            }
         }
      }
      .second{
         width: 110%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: -35px;
         // margin-right: 20px;
   
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            margin-bottom:50px;
            width:120%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .recent{

            }
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:120%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 11px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 13px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 5px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width: 90% !important;;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 55px;
               width: 50%!important;
            }
            .store{
               display: flex;
               margin-top: 45px;
               margin-left: -120px;
               .google{
                  height: 10%;
                  width: 25%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 32%;
                  height: 30%;
                  margin-top: -10px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }  
@media (min-width: 320px) {
      .first{
         width: 110%;
         min-height: 50px;
         margin-left: -45px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 120px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 45px;
               width: 20%!important;
               height: 12%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height:45%;
                  width: 60%;
                  margin-left: 14px;
                  margin-top: -15px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 2px;
                     margin-top: 12px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     // margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 220px;
               margin-top: -47px;
               width: 10%!important;
               height: 20%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 40%;
                  width:93%;
                  margin-left: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 2px;
                     margin-top: 10px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     font-weight: 600;
                     margin-right: -20px;
                     margin-left: -35px;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-right: -15px;
                     margin-left: -30px;
                     text-align: center;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 16px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 80px;

               .bar{
                  height: 170px!important;
                  width: 100%!important;
               }
            }
         }
      }
      .second{
         width: 110%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: -35px;
         // margin-right: 20px;
   
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            margin-bottom:50px;
            width:120%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .recent{

            }
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:120%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 13px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 5px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width: 90% !important;;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 70px;
               width: 50%!important;
            }
            .store{
               display: flex;
               margin-top: 45px;
               margin-left: -110px;
               .google{
                  height: 10%;
                  width: 25%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 32%;
                  height: 30%;
                  margin-top: -10px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }  
@media (min-width: 360px) {
      .first{
         width: 110%;
         min-height: 50px;
         margin-left: -45px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 120px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 45px;
               width: 20%!important;
               height: 12%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height:45%;
                  width: 60%;
                  margin-left: 14px;
                  margin-top: -15px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 4px;
                     margin-top: 12px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     // margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 220px;
               margin-top: -47px;
               width: 10%!important;
               height: 20%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 40%;
                  width:93%;
                  margin-left: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 10px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     font-weight: 600;
                     margin-right: -20px;
                     margin-left: -35px;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-right: -15px;
                     margin-left: -30px;
                     text-align: center;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 16px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 80px;

               .bar{
                  height: 170px!important;
                  width: 100%!important;
               }
            }
         }
      }
      .second{
         width: 110%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: -35px;
         // margin-right: 20px;
   
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            margin-bottom:50px;
            width:110%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .recent{

            }
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:112%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 13px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 5px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width:80%;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 70px;
               width: 50%!important;
            }
            .store{
               display: flex;
               margin-top: 45px;
               margin-left: -90px;
               .google{
                  height: 10%;
                  width: 25%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 32%;
                  height: 30%;
                  margin-top: -10px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }  
@media (min-width: 375px) {
      .first{
         width: 110%;
         min-height: 50px;
         margin-left: -45px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 150px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 45px;
               width: 20%!important;
               height: 12%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height:45%;
                  width: 60%;
                  margin-left: 14px;
                  margin-top: -15px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 4px;
                     margin-top: 12px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     // margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 260px;
               margin-top: -47px;
               width: 10%!important;
               height: 20%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 40%;
                  width:93%;
                  margin-left: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 10px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     font-weight: 600;
                     margin-right: -20px;
                     margin-left: -35px;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-right: -15px;
                     margin-left: -30px;
                     text-align: center;
                     
                  }
               }
            }
         }
         
      }
      
   }  
@media (min-width: 411px) {
      .first{
         width: 110%;
         min-height: 50px;
         margin-left: -45px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 220px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 45px;
               width: 20%!important;
               height: 12%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height:60%;
                  width: 70%;
                  margin-left: 14px;
                  margin-top: -15px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 12px;
                     margin-top: 18px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     // margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     text-align: center;
                     margin-right: -20px;
                     margin-left: -25px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 300px;
               margin-top: -47px;
               width: 15%!important;
               height: 20%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 53%;
                  width:85%;
                  margin-left: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 12px;
                     margin-top: 20px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     text-align: center;
                     font-weight: 600;
                     margin-right: -30px;
                     margin-left: -40px;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-right: -15px;
                     margin-left: -30px;
                     text-align: center;
                     
                  }
               }
            }
         }
         
      }
      .second{
         width: 110%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: -35px;
         // margin-right: 20px;
   
        
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width:80%;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 90px;
               width: 50%!important;
            }
            .store{
               display: flex;
               margin-top: 20px;
               margin-left: -60px;
               .google{
                  height: 11%;
                  width: 23%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 27%;
                  height: 30%;
                  margin-top: -08px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }  
@media (min-width: 540px) {
      .first{
         width: 100%;
         min-height: 50px;
         margin-left: -30px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 280px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:100%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 20px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 270px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 20px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 55px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 70%!important;
                  width: 30%;
                  margin-left: 14px;
                  margin-top: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -70px;
                     margin-left: 30px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     margin-left: 30px;
                     
                  }
               }
            }
            .cardss{
               margin-left: 270px;
               margin-top: -55px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 72%;
                  width: 33%!important;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -75px;
                     margin-left: 35px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     margin-left: 35px;
                     
                  }
               }
            }
            
         }
         
      }
      .second{
         width: 100%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: -15px;
         // margin-right: 20px;
   
        
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width:80%;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 160px;
               width: 30%!important;
            }
            .store{
               display: flex;
               margin-top: 20px;
               margin-left: -20px;
               .google{
                  height: 11%;
                  width: 23%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 27%;
                  height: 30%;
                  margin-top: -08px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }   
@media (min-width: 768px) {
      .first{
         width: 120%;
         min-height: 50px;
         margin-left: -10px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 25px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -20px;
                 margin-left: 400px;
                 width: 15%!important;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 25px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 55px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 95%;
                  margin-left: 14px;
                  margin-top: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 370px;
               margin-top: -75px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 95%;
                  width: 50%!important;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 20px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 20px;

               .bar{
                  height: 280px!important;
                  width: 100%!important;
               }
            }
         }
         
         
      } 
      .second{
         width: 117%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: 0px;
         // margin-right: 20px;
         
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            margin-bottom:50px;
            width:110%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .recent{

            }
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:112%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 13px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 5px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
        
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 380px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width: 100%!important;;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 180px;
               width: 40%!important;
            }
            .store{
               display: flex;
               margin-top: 20px;
               margin-left: 20px;
               .google{
                  height: 11%;
                  width: 23%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 27%;
                  height: 32%;
                  margin-top: -13px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }
@media (min-width: 978px) {
      .first{
         width: 120%;
         min-height: 50px;
         margin-left: -10px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 25px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -20px;
                 margin-left: 320px;
                 width: 15%!important;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 10px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 25px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 75px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 93%!important;
                  margin-left: 14px;
                  margin-top: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -15px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     margin-left: -15px;
                     
                  }
               }
            }
            .cardss{
               margin-left: 330px;
               margin-top: -75px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 95%;
                  width: 50%!important;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -20px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     margin-left: -20px;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 20px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 20px;

               .bar{
                  height: 280px!important;
                  width: 100%!important;
               }
            }
         }
         
         
      } 
      .second{
         width: 117%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: 0px;
         // margin-right: 20px;
         
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            margin-bottom:50px;
            width:110%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .recent{

            }
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:112%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 13px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 5px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
        
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 380px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width: 100%!important;;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 150px;
               width: 40%!important;
            }
            .store{
               display: flex;
               margin-top: 20px;
               margin-left: -5px;
               .google{
                  height: 11%;
                  width: 23%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 28%;
                  height: 32%;
                  margin-top: -11px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      }   
   }
@media (min-width: 1024px) {
      .first{
         width: 120%;
         min-height: 50px;
         margin-left: -10px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 80px;
            margin-bottom: 30px;
            width:110%;
            padding: 10px 10px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 30px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 300px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 85px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 95%;
                  margin-left: 14px;
                  margin-top: 0px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     
                  }
               }
            }
            .cardss{
               margin-left: 330px;
               margin-top: -75px;
               width: 37%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 95%;
                  width: 50%!important;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 25px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 12px;
                     font-weight: 600;
                     margin-top: -10px;
                  }
                  h5{
                     color: #606060;
                     font-size: 14px;
                     font-weight: 600;
                     
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 350px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
              width:110%;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 20px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 20px;

               .bar{
                  height: 280px!important;
                  width: 100%!important;
               }
            }
         }
         
         
      } 

      .second{
         width: 117%;
         min-height: 50px;
         display: flex;
         margin-bottom:50px;
         margin-left: 0px;
         // margin-right: 20px;
        
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 400px !important;
              //margin-top: 40px; 
              margin-left: -10px;
              margin-bottom: 40px;
              width: 100%!important;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 180px;
               width: 40%!important;
            }
            .store{
               display: flex;
               margin-top: 40px;
               margin-left: 20px;
               .google{
                  height: 11%;
                  width: 23%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 29%;
                  height: 32%;
                  margin-top: -16px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      } 
   }

@media (min-width: 1200px) {
      .first{
         width: 100%;
         min-height: 50px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 340px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 70px;
            padding: 30px 20px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 22px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 200px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 40px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 35px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 55px;
               width: 42%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 88%!important;
                  margin-left: 14px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -30px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-left: -30px;
                     
                  }
               }
            }
            .cardss{
               margin-left: 230px;
               margin-top: -60px;
               width: 42%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 88%;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -30px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-left: -30px;
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 340px !important;
              
              margin-left: -20px;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 16px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 100px;

               .bar{
                  height: 200px!important;
                  width: 100%!important;
               }
            }
         
         }
      }
      .second{
         width: 100%;
         min-height: 50px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            width:110%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:102%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 22px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: 0px;
              width:80%;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 15px;
               margin-left: 100px;
            }
            .store{
               display: flex;
               margin-top: 25px;
               margin-left: -60px;
               .google{
                  height: 10%;
                  width: 20%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 25%;
                  height: 30%;
                  margin-top: -7px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      }
   
   }

   @media (min-width: 1400px) {
      .first{
         width: 100%;
         min-height: 50px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            background-color: #fff;
            border-radius: 30px;
            height: 340px !important;
              //margin-top: 40px; 
              //margin-left: 210px;
            margin-right: 70px;
            padding: 30px 20px;
            position: relative;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .content{
               display: flex;
               flex-direction: row;
               margin-top: 30px;
               // position: absolute;
               
               p{
                  color: #c4c4c4;
                  font-size: 22px;
                  font-weight: 600;
                  margin-left: 15px;
               }
               .image{
                 margin-top: -10px;
                 margin-left: 270px;
               }
            }
            .text{
               margin-left: 15px;
               margin-top: 40px;
               h3{
                  color: #000;
                  font-weight: 600;
                  font-size: 35px;
               }
            }
            .cards{
               margin-left: 20px;
               margin-top: 55px;
               width: 42%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(254, 236, 95, 0.2);
               border-radius: 10px;
               border: 0px;
               
               .leftside{
                  background-color: #efda33;
                  height: 88%!important;
                  margin-left: 14px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -30px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-left: -30px;
                     
                  }
               }
            }
            .cardss{
               margin-left: 280px;
               margin-top: -60px;
               width: 42%!important;
               height: 22%!important;
               box-shadow: 0px 8px 40px rgba(255, 193, 222, 0.2);
               border-radius: 10px;
               border: 0px;
               .leftside{
                  background-color: #ef338a;
                  height: 88%;
                  margin-left: 13px;
                  border-radius: 10px;
                  .arrow{
                     margin-left: 5px;
                     margin-top: 15px;
                  }
               }
               .rightside{
                  // padding-bottom: 5px;
                  p{
                     color: #c4c4c4;
                     font-size: 10px;
                     font-weight: 600;
                     margin-top: -10px;
                     margin-left: -30px;
                  }
                  h5{
                     color: #606060;
                     font-size: 10px;
                     font-weight: 600;
                     margin-left: -30px;
                  }
               }
            }
         }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 340px !important;
              
              margin-left: -20px;
              padding: 30px 20px;
              position: relative;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .duration-select{
               position: absolute;
               top: 23px;
               right: 43px;
               padding: 7px 20px;
               border: 1px solid #606060;
               color: #606060;
               font-size: 16px;
               font-weight: 600;
               border-radius: 10px;
               width: 190px!important;
   
               .custom-selected{
                  list-style-type: none;
                  right: 0;
                  left: 0;
                  top: 105%;
                  // padding: 10px 10px;
                  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                  li{
                     background-color: #fff;
                     padding: 13px 20px;
                     width: 100%;
                     // text-align: left;
                     // justify-content: center;
                     cursor: pointer;
                     // border: 1px solid #606060;
                     // position: absolute;
                     // left: 80px;
                     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
   
                     &:active{
                        background-color: #3377EF;
                        color: #fff;
                     }
                     &:hover{
                        background-color: #3377EF;
                        color: #fff;
                     }
                  }
               }
            }
            .bar-chart{
               
               margin-top: 100px;

               .bar{
                  height: 200px!important;
                  width: 100%!important;
               }
            }
         
         }
      }
      .second{
         width: 100%;
         min-height: 50px;
         display: flex;
         margin-bottom:30px;
         // margin-right: 20px;
   
         .balance{
            // background-color: #fff;
            border-radius: 30px;
            height: 300px !important;
              margin-top: -20px; 
              //margin-left: 210px;
            margin-right: 40px;
            width:110%;
            padding: 30px 20px;
            // box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
   
            .bal{
            background-color: #fff;
            border-radius: 10px;
            height: 50px !important;
              //margin-top: 40px; 
            margin-left: -30px;
            margin-right: 0px;
            width:102%;
            padding: 5x 10px;
   
            .flex-container{
               padding: 0;
               margin: 0;
               width: 100%;
               list-style: none;
               
               -ms-box-orient: horizontal;
               display: -webkit-box;
               display: -moz-box;
               display: -ms-flexbox;
               display: -moz-flex;
               display: -webkit-flex;
               display: flex;
                  
               -webkit-justify-content: space-around;
               justify-content: space-around;
               -webkit-flex-flow: row wrap;
               flex-flow: row wrap;
               -webkit-align-items: stretch;
               align-items: stretch;
   
               .flex-item:nth-of-type(1) { flex-grow: 1; }
               .flex-item:nth-of-type(2) { flex-grow: 1; }
               .flex-item:nth-of-type(3) { flex-grow: 2; }
               .flex-item:nth-of-type(4) { flex-grow: 1; }
               .flex-item:nth-of-type(5) { flex-grow: 1; }
   
               .flex-item {
     
                  
                 
                  // border: 3px solid rgba(0,0,0,.2);
                  line-height: 45px;
                  color: #000000;
                  font-weight: 500;
                  // font-size: 14px;
                  text-align: center;
                  
                }
            }
            }
         .recent{
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin-left: -20px;
            // float:left;
         }
         .show{
            border: 1px solid #606060;
            color: #606060;
            border-radius: 8px;
            float:right;
            padding: 8px 20px;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 600;
            margin-top: -35px;
            margin-right: 22px;
   
            &:hover{
               border: none;
               background-color: #606060;
               color: #fff;
               padding: 9px 21px;
   
            }
         }
         .rafiki{
            margin-top: 30px;
            margin-left: 160px;
         }
         p{
            color:#606060;
            text-align: center;
            margin-top: 20px;
         }
      }
         .year{
            background-color: #fff;
              border-radius: 30px;
              height: 300px !important;
              //margin-top: 40px; 
              margin-left: 0px;
              width:80%;
              padding: 30px 20px;
            //   box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .chat{
               margin-top: 5px;
               margin-left: 130px;
            }
            .store{
               display: flex;
               margin-top: 25px;
               margin-left: -20px;
               .google{
                  height: 10%;
                  width: 20%;
                  margin-left: 135px;
                  margin-right: 10px;
               }
               .apple{
                  width: 25%;
                  height: 30%;
                  margin-top: -9px;
                  // margin-left: 155px;
                  margin-right: 10px;
               }
            }
            p{
               color:#606060;
               text-align: center;
               margin-top: 15px;
            }
         }
      }
   
   }
}

















.ReactModal__Overlay--after-open, .ReactModal__Overlay {
   background-color: rgba(0, 0, 0, 0.3) !important;
    
   
   h3{
      color: #606060;
      font-size: 19px;
      font-weight: 600;
      text-align: center;
      margin-top: 0px;
   }
   p{
      color:#606060;
      font-size: 14px;
      text-align: center;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 2rem 11rem;
      // padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         margin-left: 20px;
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .new-form{
      margin-left: 40px; 
     
       .form-text{
           font-size: 12px!important;
           font-weight: 700;
           margin-bottom: -5px;
       }
       .input {
           outline: none;
           font-size: 14px; 
           font-weight: 400;
           border-radius: 5px;
           padding-top: 7px;
           padding-bottom: 7px;
           padding-left: 22px;     
        }
        i{
         position: absolute;
         left: 88%;
         top: 5%;
         cursor:pointer;
         color: #c4c4c4!important;
         
     }
     .css-wmw4vi-ReactDropdownSelect {
      border-radius: 5px;
      
      &:focus-within {
        outline: none;
        box-shadow: 0 0 0 0px;
      }
    }
        
        .input-wrapper {
           border: 1px solid #c4c4c4 !important;
           border-radius: 5px;
           position: relative;
          
           &:focus-within{
             border: 1px solid #3377ef!important;  
           }
        }
   
        .error-text {
           color: #f5390a!important;
           font-weight: 400;
           font-size: 13px;     
        }

        .state{
         position: absolute;
         // top:0px;
         // right: 53px!important;
         position: relative;
         padding-top: 7px;
         padding-bottom: 7px;
         padding-left: 22px;  
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         // overflow-y: scroll;
         
         
         .states{
            list-style-type: none;
            right: 0px;
            left: 0px;
            top: 45px;
            max-height: 120px!important;
            overflow-y: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            // margin-right: -100px!important;
            // padding: 10px 10px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }

      .country{
         // position: relative;
         padding: 15px 20px;
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         z-index: -70;
         // overflow-y: scroll;
         
         
         .countries{
            list-style-type: none;
            right: 25px;
            top: 90px;
            width: 530px;
            // overflow: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            margin-right: -85px!important;
            // padding: 10px 10px;
            // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               width: 85%;
               margin-right: -40px!important;
               // text-align: left;
               // justify-content: center;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }
}
.home-signup{
   background-color: #3377ef;
   color: #fff;
   text-align:center;
   margin-left: 460px!important;
   justify-content: center;
   border-radius: 10px;
   font-size: 13px;
   font-weight: 600;
   border: 0px;
}
.homedisabled{
   background-color: #D7E5FE!important;
   color: #606060!important; 
   text-align: center;
   // margin-left: 460px!important;
   
}

@media (min-width: '280px') {
   .warning{
      justify-content: center;
      margin-top: 10%;
      width: 25%!important;
      
   }

   h3{
      font-size: 15px;
   }
   p{
      font-size: 12px;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(10%, 50%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .new-form{
      margin-left: 0px!important; 
     
       .form-text{
           font-size: 12px!important;
           font-weight: 700;
           margin-bottom: -5px;
       }
       .input {
           outline: none;
           font-size: 12px; 
           font-weight: 400;
           border-radius: 5px;
           padding-top: 10px;
           padding-bottom: 10px;
           padding-left: 22px;     
        }
        i{
         position: absolute;
         left: 88%;
         top: 5%;
         cursor:pointer;
         color: #c4c4c4!important;
         
     }
        
        .input-wrapper {
           border: 1px solid #c4c4c4 !important;
           border-radius: 5px;
           position: relative;
          
           &:focus-within{
             border: 1px solid #3377ef!important;  
           }
        }
   
        .error-text {
           color: #f5390a!important;
           font-weight: 400;
           font-size: 13px;     
        }

        .state{
         position: absolute;
         // top:0px;
         // right: 53px!important;
         position: relative;
         padding-top: 7px;
         padding-bottom: 7px;
         padding-left: 22px;  
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         // overflow-y: scroll;
         
         
         .states{
            list-style-type: none;
            right: 0px;
            left: 0px;
            top: 45px;
            max-height: 120px!important;
            overflow-y: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            // margin-right: -100px!important;
            // padding: 10px 10px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }

      .country{
         // position: relative;
         padding: 15px 20px;
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         z-index: -70;
         // overflow-y: scroll;
         
         
         .countries{
            list-style-type: none;
            right: 25px;
            top: 90px;
            width: 530px;
            // overflow: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            margin-right: -85px!important;
            // padding: 10px 10px;
            // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               width: 85%;
               margin-right: -40px!important;
               // text-align: left;
               // justify-content: center;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }
}
.home-signup{
   background-color: #3377ef;
   color: #fff;
   text-align:center;
   justify-content: center;
   border-radius: 10px;
   margin-left: 40px!important;
   margin-bottom: 20px;
   font-size: 13px;
   font-weight: 600;
   border: 0px;
}
.homedisabled{
   background-color: #D7E5FE!important;
   color: #606060!important;
   margin-left: 40px!important;
}
}
@media (min-width: '320px') {
   .warning{
      justify-content: center;
      margin-top: 15%;
      width: 20%!important;
   }

   h3{
      font-size: 17px;
   }
   p{

   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(20%, 50%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .home-signup{
      background-color: #3377ef;
      color: #fff;
      text-align:center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
   }
}
@media (min-width: '360px') {
   .warning{
      justify-content: center;
      margin-top: 15%;
      width: 20%!important;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(35%, 50%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .home-signup{
      background-color: #3377ef;
      color: #fff;
      text-align:center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
   }
}

@media (min-width: '411px') {
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(45%, 50%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .home-signup{
      background-color: #3377ef;
      color: #fff;
      text-align:center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
   }
}
@media (min-width: '540px') {
   .warning{
      margin-top: 5%!important;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(80%, 50%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .home-signup{
      background-color: #3377ef;
      color: #fff;
      text-align:center;
      justify-content: center;
      border-radius: 10px;
      margin-left: 55px!important;
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
   }
   .homedisabled{
      background-color: #D7E5FE!important;
      color: #606060!important;
      margin-left: 55px!important;
   }
}
@media (min-width: '768px') {
   .warning{
      justify-content: center;
      margin-top: 5%;
      width: 13%!important;
   }
   h3{
      font-size: 25px;
      text-align: center;
   }
   p{
      font-size: 18px;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 0rem 0rem;
      padding: 0;
      width: max-content;
      justify-content: center;
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         transform: translate(100%, 60%);
         
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .new-form{
      margin-left: 40px!important; 
     
       .form-text{
           font-size: 12px!important;
           font-weight: 700;
           margin-bottom: -5px;
       }
       .input {
           outline: none;
           font-size: 12px; 
           font-weight: 400;
           border-radius: 5px;
           padding-top: 10px;
           padding-bottom: 10px;
           padding-left: 22px;     
        }
        i{
         position: absolute;
         left: 88%;
         top: 5%;
         cursor:pointer;
         color: #c4c4c4!important;
         
     }
        
        .input-wrapper {
           border: 1px solid #c4c4c4 !important;
           border-radius: 5px;
           position: relative;
          
           &:focus-within{
             border: 1px solid #3377ef!important;  
           }
        }
   
        .error-text {
           color: #f5390a!important;
           font-weight: 400;
           font-size: 13px;     
        }

        .state{
         position: absolute;
         // top:0px;
         // right: 53px!important;
         position: relative;
         padding-top: 7px;
         padding-bottom: 7px;
         padding-left: 22px;  
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         // overflow-y: scroll;
         
         
         .states{
            list-style-type: none;
            right: 0px;
            left: 0px;
            top: 45px;
            max-height: 120px!important;
            overflow-y: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            // margin-right: -100px!important;
            // padding: 10px 10px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }

      .country{
         // position: relative;
         padding: 15px 20px;
         border: 1px solid #c4c4c4!important;
         color: #757575!important;
         font-size: 14px;
         font-weight: 500;
         border-radius: 5px;
         margin-top: 15px;
         margin-bottom: 30px;
         z-index: -70;
         // overflow-y: scroll;
         
         
         .countries{
            list-style-type: none;
            right: 25px;
            top: 90px;
            width: 530px;
            // overflow: scroll;
            z-index: 30001;
            font-size: 12px;
            color:#000;
            margin-right: -85px!important;
            // padding: 10px 10px;
            // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li{
               background-color: #fff;
               padding: 13px 20px;
               width: 85%;
               margin-right: -40px!important;
               // text-align: left;
               // justify-content: center;
               cursor: pointer;
               // border: 1px solid #606060;
               // position: absolute;
               // left: 80px;
               box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
               
               &:active{
                  background-color: #3377EF;
                  color: #fff;
               }
               &:hover{
                  background-color: #3377EF;
                  color: #fff;
               }
            }
         }
         
      }
}
.home-signup{
   background-color: #3377ef;
   color: #fff;
   text-align:center;
   justify-content: center;
   border-radius: 10px;
   margin-left: 60px!important;
   margin-bottom: 20px;
   font-size: 13px;
   font-weight: 600;
   border: 0px;
}
.homedisabled{
   background-color: #D7E5FE!important;
   color: #606060!important;
   margin-left: 60px!important;
}
}

@media (min-width: '1200px') {
   .warning{
      justify-content: center;
      margin-left: 40%;
   }
   h3{
      color: #606060;
      font-size: 19px;
      font-weight: 600;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
   }
   p{
      color:#606060;
      font-size: 14px;
      text-align: center;
   }
   button {
      border: none;
      background: none;
      // margin: 1.5rem 0rem 1rem auto !important;
      margin: 2rem 11rem;
      padding: 0;
      width: max-content;
      justify-content: center;
      transform: translate(-90%, -30%);
     

      .button {
         background: #fff;
         border: 1px solid #3377EF;
         border-radius: 8px;
         font-size: 12px;
         font-weight: 600;
         margin-left: 20px;
         // font: normal .8rem 'SofiaPro';
         color: #3377ef;
         width: max-content;
        padding: .8rem 3.8rem;
         
         &:hover {
            background: #3377EF;
            color: #fff;
            border: none;
            padding: .9rem 3.9rem;
         }
      }
   }
   .home-signup{
      background-color: #3377ef;
      color: #fff;
      text-align:center;
      justify-content: center;
      margin-left: 460px!important;
      border-radius: 10px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
   }
   .homedisabled{
      background-color: #D7E5FE!important;
      color: #606060!important; 
      text-align: center;
      margin-left: 460px!important;
      
   }
}
}

.ReactModal__Content .ReactModal__Content--after-open {
   .style {
      background-color: red!important;
    }
 }

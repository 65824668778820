.emailverify{
   background-color: #f7f8fc;
   min-height: 790px;

   .smallbox2{
       background-color: #fff;
       border-radius: 20px;
       height: 410px !important;
       width:38%;
       transform: translate(82%, 1%);
       padding: 40px 40px;
       box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
       text-align: center;
       display: block;

       .tick{
           width: 25%;
           transform:translate(-10%, 30%);
       }
       h3{
           font-size: 16px;
           font-weight: 550;
           color: #606060;
           margin-bottom:20px;
       }
       .signupbtn{
         background-color: #fff;
         color: #3377ef;
         text-align:center;
         padding: 10px 40px;
         border-radius: 5px;
         // margin-left: 40px;
         font-size: 13px;
         font-weight: 600;
         border: 1px solid #3377ef;
     }
       
   }
   @media (min-width: 280px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 300px !important;
        width:90%;
        transform: translate(8%, 15%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 25%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 13px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 13px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
   @media (min-width: 320px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 300px !important;
        width:90%;
        transform: translate(8%, 15%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 25%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 13px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 13px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }

   @media (min-width: 360px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 370px !important;
        width:90%;
        transform: translate(8%, 15%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 25%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 16px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 13px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
   @media (min-width: 768px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 420px !important;
        width:90%;
        transform: translate(8%, 15%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 15%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 20px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 13px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
   @media (min-width: 1024px) {
    background-color: #f7f8fc;
    min-height: 950px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 520px !important;
        width:80%;
        transform: translate(15%, 15%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 15%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 25px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 21px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
   @media (min-width: 1200px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 380px !important;
        width:50%;
        transform: translate(50%, 5%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 15%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 20px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 21px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
   @media (min-width: 1400px) {
    background-color: #f7f8fc;
    min-height: 790px;
 
    .smallbox2{
        background-color: #fff;
        border-radius: 20px;
        height: 380px !important;
        width:50%;
        transform: translate(50%, 5%);
        padding: 40px 40px;
        box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
        text-align: center;
        display: block;
 
        .tick{
            width: 15%;
            transform:translate(-10%, 30%);
        }
        h3{
            font-size: 20px;
            font-weight: 550;
            color: #606060;
            margin-bottom:20px;
        }
        .signupbtn{
          background-color: #fff;
          color: #3377ef;
          text-align:center;
          padding: 10px 40px;
          border-radius: 5px;
          // margin-left: 40px;
          font-size: 21px;
          font-weight: 600;
          border: 1px solid #3377ef;
      }
        
    }
   }
}
.customer_verify_email {
    background-color: #f7f8fc;
   min-height: 790px;

   .smallbox2{
       background-color: #fff;
       border-radius: 20px;
    //    height: 410px !important;
    //    width:38%;
    //    transform: translate(82%, 1%);
       padding: 40px 40px;
       box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
       text-align: center;
       display: block;

       h3{
           font-size: 16px;
           font-weight: 550;
           color: #606060;
           margin-bottom:20px;
       }
       h2 {
        font-size: 18px;

        font-weight: 700;
        color: #3377EF;
        
       }
       p {
        font-size: 16px;
        font-weight: 550;
        color: #333436;
       }
       
   } 
}
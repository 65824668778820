.signup{
   
    .bigboxes{
            .image{
                .over{
                    .word{
                        h3{
                            font-size: 33px;
                            font-weight: 600;
                            color: #fff;
                            width: 60%;
                            text-align: center;
                        }
                        p{
                            color: #fff;
                            font-size: 25px;
                            width: 60%;
                            text-align: center;
                        }
                    }
                }
            }
        

            
        }
        .smallbox{
            float: right;
           .small-text{
               color: grey!important;
           }
    
            .smallbox2{
                background-color: #fff;
                border-radius: 20px;
                height: 300px !important;
                width: 80%;
                box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
                
                
    
                p{
                    color: #3377ef !important;;
                    font-size: 13px;
                    font-weight:700;
                    text-align: center;
                    margin-top: 5%!important;
                }
               
                .images{
                    
                    .custom{
                        width: 63%;
                        transition: all 1.5s ease;
                        
    
                        &:hover{
                            transform: scale(1.1);
                            
                        }
                        @media (max-width: 500px) {
                            width: 55%;
                        }
                    }
                    .merchant{
                        // width: 75%;
                        height: 50%;
                        transition: all 1.5s ease;
    
                        &:hover{
                            transform: scale(1.1);
                    }
                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }
                    .customer{
                        font-weight: 500;
                        text-align: center;
                        margin-left: -50px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                    .merchant-text {
                        font-weight: 500;
                        text-align: center;
                        margin-left: 0px;
                        margin-top: -10px!important;
    
                        &:hover{
                            color: #0e52c1!important;
                        }
                    }
                }
                
        }    
            p{
                font-size: 12px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                
            }
        }

}


.sidebar-wrapper {
    width: 315px;
    padding: 0 40px;
    transition: left 0.15s ease-in;
    background-color: #f7f8fc;
    .side-bar-logo{
        display: inline-flex;
        margin-bottom: 61px;
        .buylogo{
            width: 100%!important;
            padding-left: 15px!important;
        }

        i {
            display: none;
            cursor: pointer;
            font-size: 20px;
            @media (max-width: 540px) and (min-width: 280px) {
                margin-left: 15px!important;
                margin-top: -15px!important;
            }
            @media (max-width: 1020px) and (min-width: 540px) {
                margin-left: 15px!important;
                margin-top: -15px!important;
            }
            @media only screen and (max-width: 978px) {
                display: inline-flex;
                margin-top: 33px;
                margin-left: 50px;
            }
        }
    }
    
    @media only screen and (max-width: 978px) {
        position: fixed;
        z-index: 1550;
        top: 0;
        left: -315px;
        bottom: 0;
        
        &.open {
            left: 0;
        }
    }
}

.sidebar-backdrop {
    display: none;
    
    @media only screen and (max-width: 978px) {
        z-index: 1500;
        position: fixed;
        background-color: #00000085;
        
        &.open {
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

#sidebar {
    background-color: #fff;
    position: relative;
    padding: 25px 25px;
    margin-top: 30px;
    margin-bottom: 50px;
    flex: 1;
    border-radius: 30px;

    &:hover {
        text-decoration: none !important;
    }

    p {
        color: #000;
        margin-top: 40px;
        margin-left: 25px;
        text-align: center;
        //   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        font-style: normal;
        width:129px;
        height: 30px;
        font-size: 18px;
        line-height: 33.25px;
    }

    .list{
       padding:0;
       margin-left: 20px;
       list-style: none;

        .list-item {
            width: 143%;
            color:#000;
            margin-left: -45px!important;
            border-radius: 0px!important;
            padding: 15px 17px!important;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            margin-bottom: 10px!important;

            .content {
                display: flex !important;
                #icon {
                    margin-left: 50px;
                    width: 20%;
                }

                #title {
                    margin-left: 10px;
                }
            }

            &.active {
                background-color: #418FFD;
                color: #fff!important;
               

                .content {
                    #icon {
                        color: #fff!important;
                    }
                }
            }

            &:hover{
                text-decoration: none!important;
                //    background-color: #418FFD;
                //    color: #fff;
            }
           
        }
    }
    .logout{
        text-align: center;
        position: absolute;
        left: 30%;
        top: 90%;
        cursor: pointer;

        &:hover{
            
            text-decoration: underline;
        }
        
        // margin-top: 150px!important;
    }
    

}

// @media only screen and (max-width: 978px) {
//     #sidebar {
//         display:none;
    
//         .side-bar-logo{
//             display: inline-flex;
//             margin-bottom: 61px;

//             i {
//                 display: inline-flex;
//                 font-size: 20px;
//             }
//         }
//     }
// }
.payment{
   overflow-y:visible;
   padding: 2.6rem 0;
   padding-right: 2.5rem;
   // margin-left: 50px;

   .pay {
      margin-bottom: 2rem;
      .menu {
         background: #3377EF;
         color: #ffffff;
         margin-left: 1rem;
         font-size: 12px;
         border: 1px solid #3377EF;
         transition: all .4s ease;
         padding: 2px 20px;
         border-radius: 10px;
         
         &:hover {
            background: transparent;
            color: #3377EF;
            border: 1px solid #3377EF;
            text-decoration: none!important;
         }
      }
      .menu2 {
         font-size: 12px;
         transition: all .4s ease;
         padding: 2px 20px;
         border-radius: 10px;
         background: transparent;
         color: #3377ef;
         border: 1px solid #3377ef;
         
         
         &:hover {
         background: #3377ef;
         color: #ffffff;
         margin-left: 1rem;
         font-size: 12px;
         border: 1px solid #3377ef;
         text-decoration: none!important;
         }
      }
      
      ul {
         list-style: none;
         padding-left: 0;
         margin-left: 60px;
         // margin-top: 20px;

         li {
            position: relative;

            &:nth-child(n) {
               margin-right: 2rem;
            }

            div {
              font-size: 15px;
              font-weight: 600;
               color: #C4C4C4;
               cursor: pointer;

               &:hover {
                  color: #3377EF98;
               }

               &.active {
                  color: #3377EF;

                  &::before {
                     content: "";
                     height: 2px;
                     width: 40%;
                     background: #3377EF;
                     position: absolute;
                     left: 0;
                     top: 1.3rem;
                     transition: 0.5s;
                  }

                  &:hover::before {
                     width: 100%;
                  }
               }
            }
         }
      }
      
   }
   .bankcard{
      margin-left: 60px;
      margin-top: 15px;
      position: relative;
      cursor: pointer;
      float: left;

      .home{
         position: absolute;
         left: 50%;
         top: 30%;
      }
      .home2{
         position: absolute;
         left: 65%;
         top: 15%;
      }
      .addbank {
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 58%;
         top: 30%;
      }
      .accountname{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 30%;
         top: 40%;
      }
      .accountno{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 75%;
         top: 80%;
      }
      .bankname{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 10%;
         top: 80%;
      }

   }

   @media (min-width: 280px) {
      .pay {
        
         .menu {
            margin-left: 0rem;
            text-align: center;
            font-size: 9px;
            padding: 10px 10px;
            border-radius: 20px;
            margin-top: 5px;     
         }
         .menu2 {
            font-size: 9px;
            margin-top: -15px;
            text-align: center;
            padding: 10px 10px;
            border-radius: 20px;
            
            &:hover {
            margin-left: 0rem;
            font-size: 9px;
            }
         }
         
         ul {
            li {
               div {
                 font-size: 12px;
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .card_bank{
            width: 100%;
         }
         .card_bank2{
            width: 55%!important;
            margin-left: 40px!important;
            margin-top: -30px!important;
         }

         .home{
            position: absolute;
            left: 45%;
            top: 25%;
         }
         .home2{
            position: absolute;
            left: 45%;
            top: 20%;
         }
         .addbank {
            font-size: 10px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 38%;
            top: 40%;
         }
         .accountname{
            font-size: 7px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 30%;
            top: 30%;
         }
         .accountno{
            font-size: 7px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 75%;
            top: 65%;
         }
         .bankname{
            font-size: 7px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 10%;
            top: 65%;
         }
   
      }
   }
   @media (min-width: 320px) {
      .pay {
         margin-bottom: 2rem;
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 0rem;
            text-align: center;
            font-size: 9px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 10px;
            border-radius: 20px;
            margin-top: 5px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
         }
         .menu2 {
            font-size: 9px;
            margin-top: -15px;
            text-align: center;
            transition: all .4s ease;
            padding: 10px 10px;
            border-radius: 20px;
            background: transparent;
            color: #3377ef;
            border: 1px solid #3377ef;
            
            
            &:hover {
            background: #3377ef;
            color: #ffffff;
            margin-left: 0rem;
            font-size: 9px;
            border: 1px solid #3377ef;
            text-decoration: none!important;
            }
         }
         
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 60px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:nth-child(n) {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 12px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .card_bank{
            width: 100%;
         }
         .card_bank2{
            width: 55%!important;
            margin-left: 50px!important;
            margin-top: -30px!important;
         }

         .home{
            position: absolute;
            left: 7%;
            top: 30%;
         }
         .home2{
            position: absolute;
            left: 50%;
            top: 20%;
         }
         .addbank {
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 38%;
            top: 40%;
         }
         .accountname{
            font-size: 10px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 40%;
            top: 35%;
         }
         .accountno{
            font-size: 10px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 70%;
            top: 70%;
         }
         .bankname{
            font-size: 10px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 5%;
            top: 70%;
         }
   
      }
   }
   @media (min-width: 360px) {
      .pay {
         margin-bottom: 2rem;
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 0rem;
            text-align: center;
            font-size: 9px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 10px;
            border-radius: 20px;
            margin-top: 5px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
         }
         .menu2 {
            font-size: 9px;
            margin-top: -15px;
            text-align: center;
            transition: all .4s ease;
            padding: 10px 10px;
            border-radius: 20px;
            background: transparent;
            color: #3377ef;
            border: 1px solid #3377ef;
            
            
            &:hover {
            background: #3377ef;
            color: #ffffff;
            margin-left: 0rem;
            border: 1px solid #3377ef;
            text-decoration: none!important;
            }
         }
         
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 60px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:nth-child(n) {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 12px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .card_bank{
            width: 100%;
            margin-left: -20px;
         }

         .home{
            position: absolute;
            left: 47%;
            top: 30%;
         }
         .home2{
            position: absolute;
            left: 42%;
            top: 35%;
         }
         .addbank {
            font-size: 12px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 35%;
            top: 50%;
         }
         .accountname{
            font-size: 12px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 32%;
            // top: 40%;
         }
         .accountno{
            font-size: 12px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 60%;
            // top: 80%;
         }
         .bankname{
            font-size: 12px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 0%;
            // top: 80%;
         }
   
      }
   }
   @media (min-width: 375px) {
     
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .accountname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 30%;
            top: 40%;
         }
         .accountno{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            // left: 75%;
            // top: 80%;
         }
         .bankname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            // left: 10%;
            // top: 80%;
         }
   
      }
   }
   @media (min-width: 411px) {
     
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .card_bank{
            width: 100%;
            margin-left: 0px;
         }
         .card_bank2{
            width: 100%;
            margin-left: 80px!important;
         }
         .home{
         position: absolute;
         left: 50%;
         top: 30%;
      }
      .home2{
         position: absolute;
         left: 45%;
         top: 30%;
      }
      .addbank {
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 37%;
         top: 45%;
      }
      .accountname{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 37%;
         // top: 40%;
      }
      .accountno{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 75%;
         // top: 80%;
      }
      .bankname{
         font-size: 14px;
         color: #000;
         font-weight: 700;
         position: absolute;
         left: 5%;
         // top: 80%;
      }
   
      }
   }
   @media (min-width: 768px) {
      
      .pay {
         margin-bottom: 2rem;
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-top: 10px!important;
            margin-left: 1rem;
            align-items: center;
            font-size: 14px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 15px 20px;
            border-radius: 10px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
         }
         .menu2 {
            font-size: 14px;
            transition: all .4s ease;
            padding: 15px 20px;
            margin-top: 10px!important;
            border-radius: 10px;
            background: transparent;
            color: #3377ef;
            border: 1px solid #3377ef;
            
            
            &:hover {
            background: #3377ef;
            color: #ffffff;
            margin-left: 1rem;
            font-size: 12px;
            border: 1px solid #3377ef;
            text-decoration: none!important;
            }
         }
         
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 60px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:nth-child(n) {
                  margin-right: 2rem;
               }
   
               div {
                  font-size: 25px;
                  font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 2.2rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 50px;
         margin-top: 35px;
         position: relative;
         cursor: pointer;
         float: none;
         
         .card_bank{
            width: 75%;
            margin-left: 80px;
         }
         .card_bank2{
            width: 40%!important;
            margin-left: 200px!important;
         }
         .home{
            position: absolute;
            left: 50%;
            top: 30%;
         }
         .home2{
            position: absolute;
            left: 49%;
            top: 40%;
         }
         .addbank {
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 45%;
            top: 50%;
         }

         .accountname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 45%;
            top: 40%;
         }
         .accountno{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 70%;
            top: 80%;
         }
         .bankname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 15%;
            top: 80%;
         }
   
      }
   }
   @media (min-width: 1200px) {
      .pay {
         margin-bottom: 2rem;
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            font-size: 12px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 20px;
            border-radius: 10px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               font-size: 12px;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
         }
         .menu2 {
            font-size: 12px;
            transition: all .4s ease;
            padding: 10px 20px;
            border-radius: 10px;
            background: transparent;
            color: #3377ef;
            border: 1px solid #3377ef;
            
            
            &:hover {
            background: #3377ef;
            color: #ffffff;
            margin-left: 1rem;
            font-size: 12px;
            border: 1px solid #3377ef;
            text-decoration: none!important;
            }
         }
         
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 60px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:nth-child(n) {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 15px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 60px;
         margin-top: 15px;
         position: relative;
         cursor: pointer;
         float: left;
   
            
         .card_bank{
            width: 100%;
            margin-left: 0px;
         }
         .card_bank2{
            width: 73%!important;
            margin-left: 100px!important;
            margin-top:-110px!important;
         }

         .home{
            position: absolute;
            left: 50%;
            top: 30%;
         }
         .home2{
            position: absolute;
            left: 65%;
            top: 20%;
         }
         .addbank {
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 58%;
            top: 35%;
         }
         .accountname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 40%;
            top: 40%;
         }
         .accountno{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 75%;
            top: 80%;
         }
         .bankname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 10%;
            top: 80%;
         }
   
      }
   }
   @media (min-width: 1400px) {
      .pay {
         margin-bottom: 2rem;
         .menu {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            font-size: 12px;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            padding: 10px 20px;
            border-radius: 10px;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
               text-decoration: none!important;
            }
         }
         .menu2 {
            font-size: 12px;
            transition: all .4s ease;
            padding: 10px 20px;
            border-radius: 10px;
            background: transparent;
            color: #3377ef;
            border: 1px solid #3377ef;
            
            
            &:hover {
            background: #3377ef;
            color: #ffffff;
            margin-left: 1rem;
            font-size: 12px;
            border: 1px solid #3377ef;
            text-decoration: none!important;
            }
         }
         
         ul {
            list-style: none;
            padding-left: 0;
            margin-left: 60px;
            // margin-top: 20px;
   
            li {
               position: relative;
   
               &:nth-child(n) {
                  margin-right: 2rem;
               }
   
               div {
                 font-size: 15px;
                 font-weight: 600;
                  color: #C4C4C4;
                  cursor: pointer;
   
                  &:hover {
                     color: #3377EF98;
                  }
   
                  &.active {
                     color: #3377EF;
   
                     &::before {
                        content: "";
                        height: 2px;
                        width: 40%;
                        background: #3377EF;
                        position: absolute;
                        left: 0;
                        top: 1.3rem;
                        transition: 0.5s;
                     }
   
                     &:hover::before {
                        width: 100%;
                     }
                  }
               }
            }
         }
         
      }
      .bankcard{
         margin-left: 60px;
         margin-top: 15px;
         position: relative;
         cursor: pointer;
         float: left;
   
         .home{
            position: absolute;
            left: 50%;
            top: 30%;
         }
         .home2{
            position: absolute;
            left: 65%;
            top: 20%;
         }
         .addbank {
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 58%;
            top: 35%;
         }
         // .accountname{
         //    font-size: 14px;
         //    color: #000;
         //    font-weight: 700;
         //    position: absolute;
         //    left: 30%;
         //    top: 40%;
         // }
         .accountno{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 75%;
            top: 80%;
         }
         .bankname{
            font-size: 14px;
            color: #000;
            font-weight: 700;
            position: absolute;
            left: 10%;
            top: 80%;
         }
   
      }
   }
}
.ReactModal__Content, .ReactModal__Content--after-open{
padding: 3% 0% 1% 1%!important;

overflow: hidden;

.input-wrapper {
   border: 1px solid #c4c4c4 !important;
   border-radius: 5px;
   position: relative;
  
   &:focus-within{
     border: 1px solid #3377ef!important;  
   }
}

.form{
     margin-left: 20%;
     justify-content: center;
     .form-text{
      font-size: 12px!important;
      font-weight: 700;
      margin-bottom: -5px;
      }
      .css-wmw4vi-ReactDropdownSelect {
          border-radius: 5px;
          
          &:focus-within {
            outline: none;
            box-shadow: 0 0 0 0px;
          }
        }
      h4{
         font-size: 15px;
         color: #000;
         font-weight: 800;
      }
      h5{
      font-size: 12px;
      color: #606060;
      margin-bottom: 25px;
      
      }
      .input{
      
         outline: none;
         // background-color: #ebebeb;
         border: none!important;
        
         // color: red!important;
         font-size: 14px; 
         font-weight: 400;
         border-radius: 5px;
         padding-top: 7px;
         padding-bottom: 7px;
         padding-left: 22px;     
      
   }
   i{
      position: absolute;
      left: 90%;
      top: 5%;
      cursor:pointer;
      color: #c4c4c4!important;
      
  }
  
   .input1 {
      outline: none;
      background-color: #ebebeb;
      border: none!important;
     
      // color: red!important;
      font-size: 14px; 
      font-weight: 400;
      border-radius: 5px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 22px;
      
      
   }
   .input-wrapper {
      border: 1px solid #c4c4c4 !important;
      border-radius: 5px;
      position: relative;
     
      &:focus-within{
        border: 1px solid #3377ef!important;  
      }
   }

   .error-text {
      color: #f5390a!important;
      font-weight: 400;
      font-size: 13px;
      
   }
   .input1-wrapper {
      border: none !important;
      border-radius: 5px;
      position: relative;
      // color: red!important;
      
   }
   
   .bank{
      // top:0px;
      // right: 53px!important;
      position: relative;
      padding: 7px 20px;
      border: 1px solid #c4c4c4!important;
      color: #757575!important;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 30px;
      // overflow-y: scroll;
      
      
      .banks{
         list-style-type: none;
         right: 0px;
         left: 0px;
         top: 35px;
         max-height: 120px!important;
         overflow-y: scroll;
         z-index: 30001;
         font-size: 12px;
         color:#000;
         // margin-right: -100px!important;
         // padding: 10px 10px;
         box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
         li{
            background-color: #fff;
            padding: 13px 20px;
            cursor: pointer;
            // border: 1px solid #606060;
            // position: absolute;
            // left: 80px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            
            &:active{
               background-color: #3377EF;
               color: #fff;
            }
            &:hover{
               background-color: #3377EF;
               color: #fff;
            }
         }
      }
      
   }
   
   
}

.reset-form {
    margin-left: 0%;
     justify-content: center;
     .form-text{
      font-size: 15px!important;
      font-weight: 700;
      margin-bottom: -5px;
      }
      .css-wmw4vi-ReactDropdownSelect {
          border-radius: 5px;
          
          &:focus-within {
            outline: none;
            box-shadow: 0 0 0 0px;
          }
        }
      h4{
         font-size: 15px;
         color: #000;
         font-weight: 800;
      }
      h5{
      font-size: 12px;
      color: #606060;
      margin-bottom: 25px;
      
      }
      .input{
      
         outline: none;
         // background-color: #ebebeb;
         border: none!important;
        
         // color: red!important;
         font-size: 14px; 
         font-weight: 400;
         border-radius: 5px;
         padding-top: 7px;
         padding-bottom: 7px;
         padding-left: 22px;     
      
      }
   i{
      position: absolute;
      left: 90%;
      top: 15%;
      cursor:pointer;
      color: #c4c4c4!important;
      
  }
  
   .input1 {
      outline: none;
      background-color: #ebebeb;
      border: none!important;
     
      // color: red!important;
      font-size: 14px; 
      font-weight: 400;
      border-radius: 5px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 22px;
      
      
   }
   .input-wrapper {
      border: 1px solid #c4c4c4 !important;
      border-radius: 5px;
      position: relative;
     
      &:focus-within{
        border: 1px solid #3377ef!important;  
      }
   }

   .error-text {
      color: #f5390a!important;
      font-weight: 400;
      font-size: 13px;
      
   }
   .input1-wrapper {
      border: none !important;
      border-radius: 5px;
      position: relative;
      // color: red!important;
      
   }
   
   .bank{
      // top:0px;
      // right: 53px!important;
      position: relative;
      padding: 7px 20px;
      border: 1px solid #c4c4c4!important;
      color: #757575!important;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 30px;
      // overflow-y: scroll;
      
      
      .banks{
         list-style-type: none;
         right: 0px;
         left: 0px;
         top: 35px;
         max-height: 120px!important;
         overflow-y: scroll;
         z-index: 30001;
         font-size: 12px;
         color:#000;
         // margin-right: -100px!important;
         // padding: 10px 10px;
         box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
         li{
            background-color: #fff;
            padding: 13px 20px;
            cursor: pointer;
            // border: 1px solid #606060;
            // position: absolute;
            // left: 80px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            
            &:active{
               background-color: #3377EF;
               color: #fff;
            }
            &:hover{
               background-color: #3377EF;
               color: #fff;
            }
         }
      }
      
   }
}
.payment-formed {
   padding: 5px 0px;

   .formtext {
     font-size: 15px !important;
     font-weight: 700;
   }
   .input {
     outline: none;
     font-size: 17px; 
     font-weight: 600;
     border: 1px solid #c4c4c4;
     border-radius: 10px;
     padding-top: 7px;
     text-align: center;
   //   padding-bottom: 10px;
     width:40px;
     margin-bottom: 10px;
     margin-top: 10px;
   //   margin-right: 15px;
     margin-left: 10px;
     
     &:focus-within{
        border: 1px solid #3377ef!important;  
     }
  }
   
 }
.newbutton{
   background-color: #3377ef;
   color: #fff;
   text-align:center;
   // padding: 15px 40px;
   border-radius: 10px;
   // margin-left: 160px;
   font-size: 13px;
   font-weight: 600;
   border: 0px;
}
.signbtn{
   background-color: #3377ef;
   color: #fff;
   text-align:center;
   // padding: 15px 40px;
   border-radius: 10px;
   // margin-left: 160px;
   font-size: 15px;
   font-weight: 600;
   border: 0px;
}
.disabled{
   background-color: #D7E5FE;
   color: #606060;
   border-radius: 10px;
}
.verified{
   margin-top: 50px;
   margin-bottom: 50px;
   margin-left: 160px;
   margin-right: 160px;
}
.character{
   transform: translate(270%, 0%);
   height: 130px;
}
.proceed{
   background-color: #3377EF;
   color: #fff;
   text-align: center;
   border: 0px;
   font-size: 13px;
   padding: 10px 30px;
   border-radius: 10px;
}
.lock{
   transform: translate(470%, 0%);
   margin-bottom: 30px;
}
.form-payment{
                    
   margin-left: 0px; 
   margin-bottom: -30px;
   
   
   .form-text{
       font-size: 12px!important;
       font-weight: 700;
       margin-bottom: -5px;
   }
   .input {
       outline: none;
       font-size: 17px; 
       font-weight: 600;
       border: 1px solid #c4c4c4;
       border-radius: 10px;
       padding-top: 10px;
       padding-bottom: 10px;
       width:60px;
       text-align: center;
       margin-bottom: 10px;
       margin-right: 30px;
       
       &:focus-within{
         border: 1px solid #3377ef!important;  
     }
   }
   .value{
      outline: none;
      font-size: 17px; 
      font-weight: 600;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      width:60px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 15px;
      margin-right: 70px;
      margin-left: -70px;
      
      &:focus-within{
        border: 1px solid #3377ef!important;  
    }
   }
      .input-wrapper {
         border: 1px solid #3377ef !important;
         border-radius: 10px;
         position: relative;
         margin-right: 3px;
                
      &:focus-within{
         border: 1px solid #3377ef!important;  
         }
      }
       
      .error-text {
           color: #f5390a!important;
           font-weight: 400;
           font-size: 13px;
           
           
       }
      }
      
      p{
         margin-bottom: -20px;
      }
      .newdisabled{
         background-color: #D7E5FE;
         color: #606060;
         padding: 13px 55px;
         // margin-left: 80px;
      }

      @media (min-width: 320px) {
         .form{
            margin-left: 0%;
            justify-content: center;
            .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
             h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
          }
          h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
          }
          .input{
             
                outline: none;
               //  background-color: #ebebeb;
                border: 1px solid #c4c4c4 !important;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 22px;     
             
          }
          i{
             position: absolute;
             left: 90%;
             top: 5%;
             cursor:pointer;
             color: #c4c4c4!important;
             
         }
         
          .input1 {
             outline: none;
             background-color: #ebebeb;
             border: none!important;
            
             // color: red!important;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 22px;
             
             
          }
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             // top:0px;
             // right: 53px!important;
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
          
          
       }
       .reset-form{
         margin-left: 0%;
         justify-content: center;
         .form-text{
          font-size: 15px!important;
          font-weight: 700;
          margin-bottom: -5px;
         }
         h4{
             font-size: 15px;
             color: #000;
             font-weight: 800;
         }
         h5{
          font-size: 12px;
          color: #606060;
          margin-bottom: 25px;
          
         }
         .input{
             outline: none;
             border: 1px solid #c4c4c4!important;               
             margin-top: 20px;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 15px;     
             padding-right: 32px;     
          
         }
         i{
            position: absolute;
            left: 90%;
            top: 45%;
            cursor:pointer;
            color: #c4c4c4!important;    
         }
      
       .input1 {
          outline: none;
          background-color: #ebebeb;
          border: none!important;
         
          // color: red!important;
          font-size: 14px; 
          font-weight: 400;
          border-radius: 5px;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 22px;
          
          
       }
       .input-wrapper {
          border: 1px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;
         
          &:focus-within{
            border: 1px solid #3377ef!important;  
          }
       }
    
       .error-text {
          color: #f5390a!important;
          font-weight: 400;
          font-size: 13px;
          
       }
       .input1-wrapper {
          border: none !important;
          border-radius: 5px;
          position: relative;
          // color: red!important;
          
       }
       
       .bank{
          position: relative;
          padding: 10px 20px;
          border: 1px solid #c4c4c4!important;
          color: #757575!important;
          font-size: 12px;
          font-weight: 500;
          border-radius: 5px;
          margin-top: 15px;
          margin-bottom: 30px;
          // overflow-y: scroll;
          
          
          .banks{
             list-style-type: none;
             right: 0px;
             left: 0px;
             top: 35px;
             max-height: 150px!important;
             overflow-y: scroll;
             z-index: 30001;
             font-size: 12px;
             color:#000;
             // margin-right: -100px!important;
             // padding: 10px 10px;
             box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
             li{
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                
                &:active{
                   background-color: #3377EF;
                   color: #fff;
                }
                &:hover{
                   background-color: #3377EF;
                   color: #fff;
                }
             }
          }
          
       }
             
    }
       .lock{
         transform: translate(180%, 0%);
         margin-bottom: 30px;
      }
      .reset-lock {
         display: flex;
         justify-content: center;   
      }
       .form-payment{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         .input {
             outline: none;
             font-size: 17px; 
             font-weight: 600;
             border: 1px solid #c4c4c4;
             border-radius: 10px;
             padding-top: 10px;
             padding-bottom: 10px;
             width:60px;
             text-align: center;
             margin-bottom: 10px;
             margin-right: 80px;
             margin-left: 0px!important;
             margin-top: 10px;
             
             &:focus-within{
               border: 1px solid #3377ef!important;  
           }
         }
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width:60px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: 70px;
            margin-left: -70px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       .forms{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         
         .value{
            outline: none;
            font-size: 12px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            width:35px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: -10px;
            margin-left: 7px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       
       .newdisabled{
         background-color: #D7E5FE;
         margin-bottom: 0px!important;
         display: inline!important;
         color: #606060;
         padding: 13px 55px;
         // margin-left: 120px!important;
         margin-top: 30px;
      }
       .resetbtn {
         background-color: #3377ef;
         border-radius: 5px;
         color: #fff;
         font-size: 12px;
         margin-top: 30px;
         // margin-left: 140px!important;
         }
      }
      @media (min-width: 360px) {
         .form{
            margin-left: 0%;
            justify-content: center;
            .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
             h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
          }
          h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
          }
          .input{
             
                outline: none;
                border: 1px solid #c4c4c4!important;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 2px;     
             
          }
          i{
             position: absolute;
             left: 90%;
             top: 5%;
             cursor:pointer;
             color: #c4c4c4!important;
             
         }
         
          .input1 {
             outline: none;
             background-color: #ebebeb;
             border: none!important;
            
             // color: red!important;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 22px;
             
             
          }
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             // top:0px;
             // right: 53px!important;
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
          
          
       }
       .lock{
         transform: translate(230%, 0%);
         margin-bottom: 30px;
      }
       .form-payment{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         .input {
             outline: none;
             font-size: 17px; 
             font-weight: 600;
             border: 1px solid #c4c4c4;
             border-radius: 10px;
             padding-top: 10px;
             padding-bottom: 10px;
             width:60px;
             text-align: center;
             margin-bottom: 10px;
             margin-right: 80px;
             margin-left: 0px!important;
             margin-top: 10px;
             
             &:focus-within{
               border: 1px solid #3377ef!important;  
           }
         }
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width:60px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: 70px;
            margin-left: -70px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       .forms{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            width:40px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: -10px;
            margin-left: 7px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       
       .newdisabled{
         background-color: #D7E5FE;
         margin-bottom: 0px!important;
         display: inline!important;
         color: #606060;
         padding: 13px 55px;
         // margin-left: 120px!important;
         margin-top: 30px;
      }
       
      }
      @media (min-width: 411px) {
         .form{
            margin-left: 0%;
            justify-content: center;
            .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
             h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
          }
          h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
          }
          .input{
             
                outline: none;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 22px;     
             
          }
          i{
             position: absolute;
             left: 90%;
             top: 5%;
             cursor:pointer;
             color: #c4c4c4!important;
             
         }
         
          .input1 {
             outline: none;
             background-color: #ebebeb;
             border: none!important;
            
             // color: red!important;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 22px;
             
             
          }
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             // top:0px;
             // right: 53px!important;
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
          
          
       }
       .lock{
         transform: translate(280%, 0%);
         margin-bottom: 30px;
      }
       .form-payment{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         .input {
             outline: none;
             font-size: 17px; 
             font-weight: 600;
             border: 1px solid #c4c4c4;
             border-radius: 10px;
             padding-top: 10px;
             padding-bottom: 10px;
             width:60px;
            
             margin-bottom: 10px;
             margin-right: 50px;
             margin-left: 0px!important;
             margin-top: 10px;
             
             &:focus-within{
               border: 1px solid #3377ef!important;  
           }
         }
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width:60px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: 70px;
            margin-left: -70px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       .form{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         .input {
             outline: none;
             font-size: 17px; 
             font-weight: 600;
             border: 1px solid #c4c4c4;
             border-radius: 10px;
             padding-top: 10px;
             padding-bottom: 10px;
             width:60px;
             margin-bottom: 10px;
             margin-right: 50px;
             margin-left: 0px!important;
             margin-top: 10px;
             
             &:focus-within{
               border: 1px solid #3377ef!important;  
           }
         }
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width:60px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: 70px;
            margin-left: -70px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       .forms{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            width:40px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: -10px;
            margin-left: 15px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       
       .newdisabled{
         background-color: #D7E5FE;
         margin-bottom: 0px!important;
         display: inline!important;
         color: #606060;
         padding: 13px 55px;
         // margin-left: 120px!important;
         margin-top: 30px;
      }
       
      }
      @media (min-width: 540px) {
         .form{
            margin-left: 0%;
            justify-content: center;
            .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
             h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
          }
          h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
          }
          .input{
             
                outline: none;
                // background-color: #ebebeb;
               
                // color: red!important;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 22px;     
             
          }
          i{
             position: absolute;
             left: 90%;
             top: 5%;
             cursor:pointer;
             color: #c4c4c4!important;
             
         }
         
          .input1 {
             outline: none;
             background-color: #ebebeb;
             border: none!important;
            
             // color: red!important;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 22px;
             
             
          }
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             // top:0px;
             // right: 53px!important;
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
          
          
       }
       
       .lock{
         transform: translate(370%, 0%);
         margin-bottom: 30px;
      }
       .form-payment{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         .input {
             outline: none;
             font-size: 17px; 
             font-weight: 600;
             border: 1px solid #c4c4c4;
             border-radius: 10px;
             padding-top: 10px;
             padding-bottom: 10px;
             width:60px;
             text-align: center;
             margin-bottom: 10px;
             margin-right: 50px;
             margin-left: -30px!important;
             
             &:focus-within{
               border: 1px solid #3377ef!important;  
           }
         }
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width:60px;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 15px;
            margin-right: 70px;
            margin-left: -70px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       .forms{
                    
         margin-left: 0px; 
         margin-bottom: 10px;
         
         
         .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
         }
         
         .value{
            outline: none;
            font-size: 17px; 
            font-weight: 600;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            width:40px;
            text-align: center;
            margin-bottom: 0px;
            margin-top: 15px;
            margin-right: -10px;
            margin-left: 30px;
            
            &:focus-within{
              border: 1px solid #3377ef!important;  
          }
         }
             .input-wrapper {
                 border: 1px solid #3377ef !important;
                 border-radius: 10px;
                 position: relative;
                 margin-right: 3px;
                 
                 
                 &:focus-within{
                     border: 1px solid #3377ef!important;  
                 }
             }
             
             .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
                 
             }
            }
       
       .newdisabled{
         background-color: #D7E5FE;
         margin-bottom: 20px!important;
         display: inline!important;
         color: #606060;
         padding: 13px 55px;
         // margin-left: 180px!important;
         margin-top: 30px;
      }
       
      }
      @media (min-width: 768px) {
         
         .form{
            margin-left: 0%;
            justify-content: center;
            .form-text{
             font-size: 12px!important;
             font-weight: 700;
             margin-bottom: -5px;
            }
            h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
            }
            h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
            }
            .input{

                outline: none;
                border: 1px solid #c4c4c4!important;
                margin-top: 20px;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 22px;     
             
            }

         i{
               position: absolute;
               left: 90%;
               top: 5%;
               cursor:pointer;
               color: #c4c4c4!important;    
         }
         
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
                
       }
         .reset-form{
            // margin-left: 20%;
            justify-content: center;
            .form-text{
             font-size: 15px;
             display: flex;
             justify-content: center;
             font-weight: 700;
             margin-bottom: -5px;
            }
            h4{
                font-size: 15px;
                color: #000;
                font-weight: 800;
            }
            h5{
             font-size: 12px;
             color: #606060;
             margin-bottom: 25px;
             
            }
            .input{
                outline: none;
                border: 1px solid #c4c4c4!important;               
                margin-top: 20px;
                font-size: 14px; 
                font-weight: 400;
                border-radius: 5px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 15px;     
                padding-right: 32px;     
             
            }
            i{
               position: absolute;
               left: 90%;
               top: 45%;
               cursor:pointer;
               color: #c4c4c4!important;    
            }
         
          .input1 {
             outline: none;
             background-color: #ebebeb;
             border: none!important;
            
             // color: red!important;
             font-size: 14px; 
             font-weight: 400;
             border-radius: 5px;
             padding-top: 7px;
             padding-bottom: 7px;
             padding-left: 22px;
             
             
          }
          .input-wrapper {
             border: 1px solid #c4c4c4 !important;
             border-radius: 5px;
             position: relative;
            
             &:focus-within{
               border: 1px solid #3377ef!important;  
             }
          }
       
          .error-text {
             color: #f5390a!important;
             font-weight: 400;
             font-size: 13px;
             
          }
          .input1-wrapper {
             border: none !important;
             border-radius: 5px;
             position: relative;
             // color: red!important;
             
          }
          
          .bank{
             position: relative;
             padding: 10px 20px;
             border: 1px solid #c4c4c4!important;
             color: #757575!important;
             font-size: 12px;
             font-weight: 500;
             border-radius: 5px;
             margin-top: 15px;
             margin-bottom: 30px;
             // overflow-y: scroll;
             
             
             .banks{
                list-style-type: none;
                right: 0px;
                left: 0px;
                top: 35px;
                max-height: 150px!important;
                overflow-y: scroll;
                z-index: 30001;
                font-size: 12px;
                color:#000;
                // margin-right: -100px!important;
                // padding: 10px 10px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                li{
                   background-color: #fff;
                   padding: 13px 20px;
                   cursor: pointer;
                   // border: 1px solid #606060;
                   // position: absolute;
                   // left: 80px;
                   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
                   
                   &:active{
                      background-color: #3377EF;
                      color: #fff;
                   }
                   &:hover{
                      background-color: #3377EF;
                      color: #fff;
                   }
                }
             }
             
          }
                
       }
       
         .form-payment{
                    
            margin-left: 5px!important; 
            margin-bottom: 10px;
            
            
            .form-text{
                font-size: 12px!important;
                font-weight: 700;
                margin-bottom: -5px;
            }
            .input {
                outline: none;
                font-size: 17px; 
                font-weight: 600;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                width:60px;
                text-align: center;
                margin-bottom: 10px;
                margin-right: 0px;
                margin-top: 10px;
                margin-left: 5px!important;
                
                &:focus-within{
                  border: 1px solid #3377ef!important;  
              }
            }
            .value{
               outline: none;
               font-size: 17px; 
               font-weight: 600;
               border: 1px solid #c4c4c4;
               border-radius: 10px;
               padding-top: 15px;
               padding-bottom: 15px;
               width:60px;
               text-align: center;
               margin-bottom: 10px;
               margin-top: 15px;
               margin-right: 70px;
               margin-left: -70px;
               
               &:focus-within{
                 border: 1px solid #3377ef!important;  
             }
            }
                .input-wrapper {
                    border: 1px solid #3377ef !important;
                    border-radius: 10px;
                    position: relative;
                    margin-right: 3px;
                    
                    
                    &:focus-within{
                        border: 1px solid #3377ef!important;  
                    }
                }
                
                .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                    
                }
               }
          .forms{
                       
            margin-left: 0px; 
            margin-bottom: 10px;
            
            
            .form-text{
                font-size: 12px!important;
                font-weight: 700;
                margin-bottom: -5px;
            }
            
            .value{
               outline: none;
               font-size: 17px; 
               font-weight: 600;
               border: 1px solid #c4c4c4;
               border-radius: 10px;
               padding-top: 10px;
               padding-bottom: 10px;
               width:40px;
               text-align: center;
               margin-bottom: 10px;
               margin-top: 15px;
               margin-right: -10px;
               margin-left: 35px;
               
               &:focus-within{
                 border: 1px solid #3377ef!important;  
             }
            }
            .input-wrapper {
                    border: 1px solid #3377ef !important;
                    border-radius: 10px;
                    position: relative;
                    margin-right: 3px;
                    
                    
                    &:focus-within{
                        border: 1px solid #3377ef!important;  
                    }
                }
                
                .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                    
                }
               }
          
          .newdisabled{
            background-color: #D7E5FE;
            margin-bottom: 20px!important;
            display: inline!important;
            color: #606060;
            padding: 13px 55px;
            // margin-left: 230px!important;
            margin-top: 30px;
         }
          .lock{
            transform: translate(480%, 0%);
            margin-bottom: 30px;
         }
      }
      
      
}

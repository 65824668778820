   header{
      height: 5rem;

      .companyname{
         // margin-left: 60px;
         // margin-top: 45px;
         // float: left !important;
         text-align: left!important;
         font-size: 15px;
         font-weight: 700;
         color: #000;  

      //     .svg{
      //     margin-left: 30px;
      //     margin-top: 5px;
      //  }
         @media (min-width:767px) {
            font-size: 25px;
         }
      }
      .pagename {
         margin-left: 60px;
         margin-top: 45px;
         float: left !important;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  

          .svg{
          margin-left: 30px;
          margin-top: 5px;
       }

      }
         .nav_icon{
            display: none;
            i{
            font-size: 26px!important;
            color: inherit;
            cursor: pointer;
         }
      }
      
      .div-header{
         // width:40%;
         // margin: 3.5% -7% 2% -7%;
         
   
         #notice{
            margin-left: 50px;
         }
         // .v-svg{
         //    margin-right: 105px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
         .multiple {
            cursor: pointer;
            border: .15px solid #bdbdbd;
            border-radius: 10px;
            
            
            span {
               font-size: 15px;
               @media (min-width: 330px) {
                  font-size: 10px;
               }
               @media (min-width: 767px) {
                  font-size: 13px;
                  font-weight: 600;
               }
            }
            
         }
      }

      .profile-settings {
         background: #FFFFFF;
         position: relative;
         box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
         border-radius: 10px;
         top: 5rem;
         right: 1rem;
         z-index: 2;
 
         ul {
           list-style: none;
           margin: 0;
           padding: 0;
 
           li {
             
               color: #000000;
               padding: 1rem 5rem;
               border-radius: 10px;
               transition: all .3s ease;
 
               &:hover {
                 background: #3377EF;
                 color: #ffffff;
               }
           }
         }
      
   }
   @media (min-width: 280px) {
      height: 4rem;
      .pagename{
         margin-left: 60px;
         margin-top: 45px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: none!important;

          .svg{
          margin-left: 20px;
          margin-top: 5px;
          display: inline!important;
       }

      }
         .nav_icon{
            display: inline;
            i{
            font-size: 26px!important;
            // margin-left: 20px;
            // margin-top: 25px;
            color: inherit;
         }
      }
      
      .div-header{
         // width:40%;
         // margin: -43.5% 3% 2% -7%;
         
   
     
         // .v-svg{
         //    margin-right: 115px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
      }
      
   } 
   @media (min-width: 320px) {
      height: 4rem;
      .pagename{
         margin-left: 60px;
         margin-top: 45px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: none!important;

          .svg{
          margin-left: 20px;
          margin-top: 5px;
          display: inline!important;
       }

      }
         .nav_icon{
            display: inline;
            i{
            font-size: 26px!important;
            // margin-left: 20px;
            // margin-top: 25px;
            color: inherit;
         }
      }
      
      .div-header{
         // width:40%;
         // margin: -37% 3% 2% -7%;
        
      
         // .v-svg{
         //    margin-right: 115px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
      }
      
   } 
   @media (min-width: 360px) {
      height: 4rem;
      .pagename{
         margin-left: 60px;
         margin-top: 45px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: none!important;

          .svg{
          margin-left: 20px;
          margin-top: 5px;
          display: inline!important;
       }

      }
         .nav_icon{
            display: inline;
            i{
            font-size: 26px!important;
            // margin-left: 20px;
            // margin-top: 25px;
            color: inherit;
         }
      }
      
      .div-header{
         // width:40%;
         // margin: -33% -10% 2% -10%!important;
         
   
      
         // .v-svg{
         //    margin-right: 115px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
      }
      
   }  
   @media (min-width: 375px) {
      height: 4rem;
     
      
   }  
   @media (min-width: 411px) {
      height: 4rem;
      .div-header{
         // width:40%;
         // margin: -30% -10% 2% -10%!important;
        
   
      
         // .v-svg{
         //    margin-right: 115px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
      }
        
      
     
      
   }  
   @media (min-width: 500px) {
      height: 4rem;
     
   }  
   @media (min-width: 540px) {
      height: 4rem;
      
      .nav_icon{
         display: inline;
         // margin-left: 15px;
         i{
         font-size: 26px!important;
         color: inherit;
      }
   }  
      .div-header{
         // width:30%;
         // margin: -23% -5% 3% -7%!important;
         
   
      
         // .v-svg{
         //    margin-right: 100px;
         // }
      
         .profile {
            margin-left: 0.9rem;
            cursor: pointer;
            position: relative;
         }
      }
   }  
   @media (min-width: 610px) {
      height: 4rem;
   }  
   @media (min-width: 700px) {
      height: 4rem;
   }  
   @media (min-width: 768px) {
      height: 4rem;
      
        
      .nav_icon{
         display: inline;
         i{
         font-size: 26px!important;
         // margin-left: 40px;
         // margin-top: 40px;
         color: inherit;
      }
   }

   .div-header{
      // width:40%;
      // margin: -8% -10% 2% -7%!important;
     

   
      // .v-svg{
      //    margin-right: 115px;
      // }
   
      .profile {
         margin-left: 0.9rem;
         cursor: pointer;
         position: relative;
      }
   }
   }  
   @media (min-width: 860px) {
      height: 5rem;    
    
   }  
   @media (min-width: 978px) {
      .pagename{
         margin-left: 50px;
         margin-top: 25px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: inline!important;

          .svg{
          margin-left: 20px;
          margin-top: -10px;
          display: inline!important;
         }

      }

      .nav_icon{
         display: none;
         i{
         font-size: 26px!important;
         // margin-left: 20px;
         // margin-top: 25px;
         color: inherit;
      }
   }

   .div-header{
      // width:40%;
      // margin: -4% -10% 2% -7%!important;
      

   
      // .v-svg{
      //    margin-right: 115px;
      // }
   
      .profile {
         margin-left: 0.9rem;
         cursor: pointer;
         position: relative;
      }
   }
   }
   @media (min-width: 1024px) {
      .pagename{
         margin-left: 50px;
         margin-top: 25px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: inline!important;

          .svg{
          margin-left: 20px;
          margin-top: -10px;
          display: inline!important;
       }

      }

      .nav_icon{
         display: none;
         i{
         font-size: 26px!important;
         // margin-left: 20px;
         // margin-top: 25px;
         color: inherit;
      }
   }
   }
   @media (min-width: 1100px) {
     

     
   }
   @media (min-width: 1200px) {
      .pagename{
         margin-left: 50px;
         margin-top: 45px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: inline!important;

          .svg{
          margin-left: 20px;
          margin-top: -10px;
          display: inline!important;
       }

      }

      .nav_icon{
         display: none;
         i{
         font-size: 26px!important;
         // margin-left: 20px;
         // margin-top: 25px;
         color: inherit;
      }
   }

   .div-header{
      // width:100%;
      // margin: 0% -10% 2% -7%!important;

   
      // .v-svg{
      //    margin-right: 115px;
      // }
   
      .profile {
         margin-left: 0.9rem;
         cursor: pointer;
         position: relative;
      }
   }
   
   }
   @media (min-width: 1300px) {
    
  
   }
   @media (min-width: 1400px) {
      .pagename{
         margin-left: 50px;
         margin-top: 45px;
         float: left !important;;
         text-align: left!important;
         font-size: 25px;
         font-weight: 700;
         color: #000;  
         display: inline!important;

          .svg{
          margin-left: 20px;
          margin-top: -10px;
          display: inline!important;
       }

      }

      .nav_icon{
         display: none;
         i{
         font-size: 26px!important;
         // margin-left: 20px;
         // margin-top: 25px;
         color: inherit;
      }
   }
   
   }
   // @media only screen and (max-width: 978px) {
   //    .pagename{
   //       margin-left: 180px;
   //       margin-top: 45px;
   //       float: left !important;;
   //       text-align: left!important;
   //       font-size: 20px;
   //       font-weight: 700;
   //       color: #000;  
   //       text-align: center;

   //        .svg{
   //        margin-left: 30px;
   //        margin-top: 5px;
   //     }

   //    }
   //    .nav_icon {
         
   //       display: inline;
   //       i{
   //          margin-left: -380px;
   //       margin-top: 50px!important;
   //       }
   //    }
   // }
      }
      .tab {
         
            p {
                font-size: 18px;
                font-weight: 600;
                color: #000;
            }
            .tab {
               background-color: #fff;
               border-radius: 10px;
            }
         }
         .smallbox{
            .close-button {
               position: absolute;
               top: -3%;
               right: -7%;
               cursor: pointer;
            }
            .title {
                
              font-size: 20px;
              font-weight: 800;
            }
            p{
                color: #000000!important;
            }
            
            .smallbox2{

           .error{
            color: #f5390a;
            font-size: 13px;
           }
           background-color: #fff;
           border-radius: 20px;
         //   width:60%;
           padding: 30px 20px 0px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);

           .forms{

          //   margin-left: 45px; 
           
             .form-text{
                 font-size: 18px!important;
                 font-weight: 700;
                 margin-bottom: -5px;
             }
             .input {
                 outline: none;
                 font-size: 18px; 
                 font-weight: 400;
                 border-radius: 5px;
                 padding-top: 10px;
                 padding-bottom: 10px;
                 padding-left: 22px;

              }
              
              .input-wrapper {
                 border: 1px solid #c4c4c4 !important;
                 border-radius: 5px;
                 position: relative;
                
                 &:focus-within{
                   border: 1px solid #3377ef!important;  
                 }
              }
         
              .error-text {
                 color: #f5390a!important;
                 font-weight: 400;
                 font-size: 13px;
                 
              }
     }
            .forgot{
                margin-left: 50px!important;
                
                &:hover a{
                    text-decoration: none!important;
                }
            }
            
          
            .companybtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                border-radius: 10px;
                font-weight: 600;
                border: 0px;
                font-size: 17px;
                margin-top: 10px;
               
              }
            .signbtn{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                border-radius: 10px;
                font-weight: 600;
                border: 0px;
                font-size: 17px;
                margin-top: 10px;
                margin-left: 2.5rem!important;
               
              }
            .disabled{
                background-color: #3377ef;
                color: #fff;
                text-align:center;
                border-radius: 10px;
                font-weight: 600;
                border: 0px;
                font-size: 17px;
                margin-top: 10px;
                margin-left: 2.5rem!important;
               
              }
              
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
           
        }       
}
     
     
      


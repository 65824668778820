.merchant{
   background-color: #f7f8fc;
   height: 100%;

   .styled-image {
       position: absolute;
   }

   .bigboxes{
       .text{
           margin-top: 25%;
       }
       h3{
           color: #000;
           text-align: left;
           font-weight: 700;
           line-height: 40px;
           margin-left: 100px;
       }
       p{
           color: #000;
           font-size: 14px;
           margin-left: 100px;

           &:hover a{
               text-decoration: none!important;
           }
       }
       
       .text{
           vertical-align: middle!important;
           align-items: center!important;
           justify-content: center!important;
           margin-bottom: 200px;
       }
       

   .smallbox{
       float: right;
       // margin-top: 50px!important;
       // margin: 50px;
      p{
          color: #000000!important;
          
       //    margin-left: 350px!important;
      }
      .another{
          margin-top: 7%;
          font-size: 16px;
          font-weight: 700;
      }
       .smallbox2{
           background-color: #fff;
           border-radius: 20px;
        //    height: 510px !important;
           margin-left: 210px;
           margin-top: 50px;
           width:75%;
          
           padding: 40px 20px;
           box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
           .error{
            color: #f5390a;
            font-size: 13px;
           }
           .forms{

              margin-left: 40px; 
             
               .form-text{
                   font-size: 18px!important;
                   font-weight: 700;
                   margin-bottom: -5px;
               }
               .input {
                   outline: none;
                   font-size: 18px; 
                   font-weight: 400;
                   border-radius: 5px;
                   padding-top: 10px;
                   padding-bottom: 10px;
                   padding-left: 22px;

                }
                i{
                    position: absolute;
                    left: 90%;
                    top: 5%;
                    cursor:pointer;
                    color: #c4c4c4!important;
                    
                }
                
                .input-wrapper {
                   border: 1px solid #c4c4c4 !important;
                   border-radius: 5px;
                   position: relative;
                  
                   &:focus-within{
                     border: 1px solid #3377ef!important;  
                   }
                }
           
                .error-text {
                   color: #f5390a!important;
                   font-weight: 400;
                   font-size: 13px;
                   
                }
       }
       .signupbtn{
           background-color: #3377ef;
           color: #fff;
           text-align:center;
           padding: 15px 142px;
           border-radius: 10px;
           margin-left: 58px;
           font-size: 13px;
           font-weight: 600;
           border: 0px;
       }
   
      .disabled{
         background-color: #D7E5FE;
         color: #606060;
         padding: 15px 142px;
         border-radius: 10px;
         margin-left: 58px;
         font-size: 13px;
         font-weight: 600;
         border: 0px;
      }
           .button{
               margin-left: 50px;
               margin-top: 35px;
               display: block!important;
               
               .btn{
                   text-transform: none!important;
               }
               .btn-primary{
                   background-color: #3377ef!important;
               }

               .pad{
                   padding: 15px 150px;
               }
           }
           

           p{
               color: #3377ef !important;;
               font-size: 15px;
               font-weight:700;
               text-align: center;
               margin-top: 20%!important;
               padding-top: 20px;
               
           }
           .images{
               display: flex;
               
               
               .custom{
                   margin-top: 20px;
                   margin-left: 60px;
                   transition: all 1.5s ease;
                   

                   &:hover{
                       transform: scale(1.1);
                       
                   }
               }
               .merchant{
                   margin-top: 20px;
                   margin-left: 50px;
                   transition: all 1.5s ease;

                   &:hover{
                       transform: scale(1.1);
               }
           }
               .customer{
                   font-weight: 500;
                   text-align: center;
                   margin-left: 55px;
                   margin-top: -10px!important;

                   &:hover{
                       color: #0e52c1!important;
                   }
               }
           }
           
   }    
   

       
       h4{
           text-align: center;
           font-weight: 650;
           font-size: 20px;
       }
       p{
           font-size: 12px;
           line-height: 20px;
           color:#777679;
           font-weight: 600;
           font-style: normal;
           text-align: center;
           margin-top: 20px;
       }
   }

   }

   @media (min-width: 280px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 45px;
            margin-top: 25px;
            
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 45px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 15px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -150px;
            width:95%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 12px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 18px;
 
                 }
                 i{
                     position: absolute;
                     left: 83%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 84%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 84%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }   
   }

   @media (min-width: 320px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 45px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 45px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 15px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -150px;
            width:95%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 360px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 45px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 45px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 15px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -150px;
            width:95%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 380px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 75px;
            // width: 150%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 75px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 15px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -150px;
            width:95%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 409px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 80px;
            // width: 150%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 80px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 15px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -150px;
            width:95%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}

@media (min-width: 411px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 80px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 80px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 411px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 85px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 85px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 414px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 90px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 90px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 424px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 95px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 95px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 429px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 115px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 115px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 449px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 130px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 130px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 10px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 462px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 140px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 140px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 35px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 499px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 180px;
            // width: 120%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 180px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 35px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 89%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 89%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 540px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 60%;
        }
        h3{
            color: #000;
            // text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 140px;
            width: 50%;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 140px;
            width:120%;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        margin-right: 90px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            margin-bottom: 35px;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 60px;
            margin-top: -120px;
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: -0px; 
              
              
                .form-text{
                    font-size: 14px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 85%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            width: 92%;
            padding: 15px 15px;
            border-radius: 5px;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 92%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 15px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 768px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 25%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 200px;
            font-size: 35px;
        }
        p{
            color: #000;
            font-size: 20px;
            margin-left: 200px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 120px;
            margin-top: -150px;
            width:75%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #D7E5FE;
            color: #606060;
            width: 76%;
            padding: 15px 15px;
           border-radius: 5px;
           margin-left: 55px;
           margin-bottom: 10px;
           font-size: 13px;
           font-weight: 600;
           border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 76%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 55px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 992px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 25%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 220px;
            font-size: 35px;
            width: 100%;
        }
        p{
            color: #000;
            font-size: 20px;
            margin-left: 220px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: left;
        // margin-top: 50px!important;
        
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 250px;
            margin-top: -100px;
            width:70%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #D7E5FE;
            color: #606060;
            width: 79%;
            padding: 15px 15px;
           border-radius: 5px;
           margin-left: 55px;
           margin-bottom: 10px;
           font-size: 13px;
           font-weight: 600;
           border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 79%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 55px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 1024px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 25%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 220px;
            font-size: 35px;
            width: 100%;
        }
        p{
            color: #000;
            font-size: 20px;
            margin-left: 220px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: left;
        // margin-top: 50px!important;
        
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 240px;
            margin-top: -100px;
            width:70%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #D7E5FE;
            color: #606060;
            width: 79%;
            padding: 15px 15px;
           border-radius: 5px;
           margin-left: 55px;
           margin-bottom: 10px;
           font-size: 13px;
           font-weight: 600;
           border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
        background-color: #D7E5FE;
        color: #606060;
        width: 79%;
        padding: 15px 15px;
       border-radius: 5px;
       margin-left: 55px;
       margin-bottom: 10px;
       font-size: 13px;
       font-weight: 600;
       border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
@media (min-width: 1200px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 25%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 210px;
            margin-top: 50px;
            width:75%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 142px;
            border-radius: 10px;
            margin-left: 58px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .disabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 142px;
          border-radius: 10px;
          margin-left: 58px;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
            .button{
                margin-left: 50px;
                margin-top: 35px;
                display: block!important;
                
                .btn{
                    text-transform: none!important;
                }
                .btn-primary{
                    background-color: #3377ef!important;
                }
 
                .pad{
                    padding: 15px 150px;
                }
            }
            
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }  
}
@media (min-width: 1400px) {
    background-color: #f7f8fc;
    height: 100%;
 
    .styled-image {
        position: absolute;
    }
 
    .bigboxes{
        .text{
            margin-top: 25%;
        }
        h3{
            color: #000;
            text-align: left;
            font-weight: 700;
            line-height: 40px;
            margin-left: 100px;
        }
        p{
            color: #000;
            font-size: 14px;
            margin-left: 100px;
 
            &:hover a{
                text-decoration: none!important;
            }
        }
        
        .text{
            vertical-align: middle!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 200px;
        }
        
 
    .smallbox{
        float: right;
        // margin-top: 50px!important;
        // margin: 50px;
       p{
           color: #000000!important;
           
        //    margin-left: 350px!important;
       }
       .another{
           margin-top: 7%;
           font-size: 16px;
           font-weight: 700;
       }
        .smallbox2{
            background-color: #fff;
            border-radius: 20px;
         //    height: 510px !important;
            margin-left: 210px;
            margin-top: 50px;
            width:80%;
           
            padding: 40px 20px;
            box-shadow: 0px 3px 24px rgba(51, 119, 239, 0.1);
            .error{
             color: #f5390a;
             font-size: 13px;
            }
            .forms{
 
               margin-left: 40px; 
              
                .form-text{
                    font-size: 18px!important;
                    font-weight: 700;
                    margin-bottom: -5px;
                }
                .input {
                    outline: none;
                    font-size: 18px; 
                    font-weight: 400;
                    border-radius: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 22px;
 
                 }
                 i{
                     position: absolute;
                     left: 90%;
                     top: 5%;
                     cursor:pointer;
                     color: #c4c4c4!important;
                     
                 }
                 
                 .input-wrapper {
                    border: 1px solid #c4c4c4 !important;
                    border-radius: 5px;
                    position: relative;
                   
                    &:focus-within{
                      border: 1px solid #3377ef!important;  
                    }
                 }
            
                 .error-text {
                    color: #f5390a!important;
                    font-weight: 400;
                    font-size: 13px;
                    
                 }
        }
        .signupbtn{
            background-color: #3377ef;
            color: #fff;
            text-align:center;
            padding: 15px 142px;
            border-radius: 10px;
            margin-left: 53px;
            font-size: 13px;
            font-weight: 600;
            border: 0px;
        }
     //    .signupbtn{
     //     background-color: #3377ef;
     //     color: #fff;
     //     text-align:center;
     //     padding: 15px 175px;
     //     border-radius: 5px;
     //     margin-left: 65px;
     //     margin-bottom: 15px;
     //     font-size: 13px;
     //     font-weight: 600;
     //     border: 0px;
     // }
    
       .signupdisabled{
          background-color: #D7E5FE;
          color: #606060;
          padding: 15px 142px;
          border-radius: 10px;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
       }
           
 
            p{
                color: #3377ef !important;;
                font-size: 15px;
                font-weight:700;
                text-align: center;
                margin-top: 20%!important;
                padding-top: 20px;
                
            }
            .images{
                display: flex;
                
                
                .custom{
                    margin-top: 20px;
                    margin-left: 60px;
                    transition: all 1.5s ease;
                    
 
                    &:hover{
                        transform: scale(1.1);
                        
                    }
                }
                .merchant{
                    margin-top: 20px;
                    margin-left: 50px;
                    transition: all 1.5s ease;
 
                    &:hover{
                        transform: scale(1.1);
                }
            }
                .customer{
                    font-weight: 500;
                    text-align: center;
                    margin-left: 55px;
                    margin-top: -10px!important;
 
                    &:hover{
                        color: #0e52c1!important;
                    }
                }
            }
            
    }    
    
 
        
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 20px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }
 
    }
}
}


.landing{
    overflow-x: hidden;
    // min-height: 1500px!important;
    
    .nav{
        min-height: 800px;
        display: inline;
        position: relative;
    
    .brand{
        margin-top: 70px;
        margin-left: 98px;
        color: #000!important;
        font-weight: 750;
        font-size: 19px;
        a{
            color: #000 !important;
        }
    } 
    .navs{
        margin-top: -720px;
        a{
            color: #000;
            font-weight: 750;
            font-size: 15px;
            text-decoration: none;
            
            &:hover{
                text-decoration: none;
                color:#3377EF;
            }
            
            
        }
        
}

    }
    .bg.variant, .bg-dark {
        background-color: #fff !important;
        color: #000;      
    }
    .navbar-dark .navbar-nav{
        color:#000;
        // margin-left: 100px;
        text-align: left;
        // transform: translate(40%,0%);
       
       
     .nav-link {
        color: #000;
        margin-left: 35px;
        margin-top: -10px;
        &:hover{
            color:#3377EF;
        }
    }
    }
    .navbar-dark .navbar-brand {
        color: #000;
        font-weight: 750;
        font-size: 19px;
        margin-left: 70px;
        margin-top: -20px;
    }
    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        // background: 50% / 100% 100% no-repeat;
        background-color:#3377ef;
        border: 0px;
        color: #fff;
        border-radius: 8px;
    }

    .navbar {
        background-color: #fff !important;

        .learn {
            background-color: #3377ef;
            border-radius: 5px;
            border: 0px;    
            text-transform: capitalize !important;
            font-weight: 500;
            cursor: pointer;
            .link_learn {
                color: #fff;
                font-size: 13px;
                @media (min-width: 768px)
                {
                    font-size: 15px; 
                }
            }
    
            &:hover{
                background-color: rgba( 51,119,239, 0.9);
                color: #fff;
            }      
        }
        .start {
            background-color: #fff;
            text-transform: capitalize !important;
            font-weight: 500;
            border: 1px solid #3377EF;
            border-radius: 5px;
            cursor: pointer;
            .link_start {
                color: #3377EF;
                font-size: 13px;
                @media (min-width: 768px)
                {
                    font-size: 15px; 
                }
            }
            &:hover{
                background-color: rgba( 51,119,239, 0.9);
                // color: #fff;
                .link_start {
                    color: #fff;
                }
            }  
        }

        .nav-buttons {
            display: flex;
            list-style-type: none;
            .nav-link1 {
                border: 1px solid #3377EF;
                color: #3377EF;
                text-decoration: none;
                background-color: rgba(0,0,0,0);
                border-radius: 10px;
                &:hover {
                    background-color: #3377EF;
                    color: #fff;
                }
            }
            .nav-link2 {
                border: 1px solid #3377EF;
                border-radius: 10px;
                text-decoration: none;
                color: #fff;
                background-color: #3377EF;
                &:hover {
                    background-color: rgba(51, 119, 239, 0.9);
                    color: #3377EF;
                }
            }
        }  
        .nav-link {
            color: #000;
            margin-left: 35px;
            margin-top: -10px;
            &:hover{
                color:#3377EF;
            }
        } 
        .brand {
                height: 40%;
                width: 40%;
                object-fit: cover;
                @media (min-width:400px) {
                   height: 20%; 
                   width: 20%; 
                }
                @media (min-width:768px) {
                   height: 10%; 
                   width: 10%; 
                }
        }
        
    }
    .main{
        padding-top: 60px;
        position: relative;
        .woman {
            background-image: url(../../_assets/images/new_bg.png);
            min-height: 100vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        .image{
            position: absolute;
            top: 14%;
            right: 0%;
        }
        .image2{
            position: absolute;
            top: -3%;
            right: 0%;
        }
        .bold-text{
            color:#000;
            width: 70%;
            font-size: 25px!important;
            
            h2{
                font-weight:600;
            }
        }
        .light-text{
            color:#000;
            width: 50%;
            font-size: 13px;
            font-weight: 500;
        }
        .learn {
            background-color: #3377ef;
            border-radius: 5px;
            border: 0px;    
            text-transform: capitalize !important;
            font-weight: 500;
            cursor: pointer;
            .link_learn {
                color: #fff;
                font-size: 13px;
                @media (min-width: 768px)
                {
                    font-size: 15px; 
                }
            }
    
            &:hover{
                background-color: rgba( 51,119,239, 0.9);
                color: #fff;
            }      
        }
        .start {
            background-color: #fff;
            text-transform: capitalize !important;
            font-weight: 500;
            border: 1px solid #3377EF;
            border-radius: 5px;
            cursor: pointer;
            .link_start {
                color: #3377EF;
                font-size: 13px;
                @media (min-width: 768px)
                {
                    font-size: 15px; 
                }
            }
            &:hover{
                background-color: rgba( 51,119,239, 0.9);
                // color: #fff;
                .link_start {
                    color: #fff;
                }
            }  
        }
        .google {
            // width: 9rem;
            img {
                width: 20%;
                height: 20%;
                object-fit: cover;
            }
        }
        .apple {
            // width: 11rem;
            img {
                width: 20%;
                height: 20%;
                object-fit: cover;
            }
        }
       
    #content{

            display: inline-flex;
            flex-direction: row;
            align-items: center;
            list-style-type: none;
            margin: 0px;
            flex-direction: row;
            float:left;
            position: absolute;
            left:30%;
            
            li{
                margin-top: 70px;
                

            } a{
                padding-right:50px;
                color: #1E1A1A;
                text-decoration: none;
                font-weight: 600;
                font-size: 15px;
                 
                &:hover{
                    color: #3377EF;
                }
            }
        
    }
   
}
.service{
    background-color: #ffffff;
    // min-height: 530px;
    // margin-bottom: -50px;

    .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        
        h3{
            font-size: 19px;
            font-weight: 700;
            // margin: 5px 9px;
            color: #1a1a1a;
            text-align: center;
        
        }
        .left{
            width: auto;
        }
        .right{
            width: auto;
        }
    }
 
        // margin-top: 40px;
       
       
        

        .square{
           
            border-radius: 27px;
            // padding-top: 5px;
            // padding-bottom: 20px;
            min-height: 60px!important;
            // padding-right: 20px;
            border: 0!important;
            box-sizing: border-box;
            // margin-right: 50px!important;
            box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
            transition: transform .2s;

            


            .eligible{
                width:18%;
                
            }
            h5{
                font-size: 19px;
                font-weight: 650;
                color: #1a1a1a;
            }
            p{
                font-size: 15px;
            }

            &:hover {
                transform: scale(1.05);
                border: 1px solid #3377EF!important;
            }
        }
    
}

.service2{
    background-color: #fffcf2;

    .sub{        
            h5{
                color: #1E1A1A;
                font-weight: 500;
                font-size: 20px;
                font-family: 'Sofia Pro', sans-serif;
            }

            h4{
                color: #1E1A1A;
                font-size: 22px;
                font-weight: 700;
            }

            p{
                color: #7d7975;
                font-size: 16px;
                word-spacing: 50%;
                font-family: 'Sofia Pro', sans-serif;
            }

            .btn4{
                background-color: #ffc107;
                border-radius: 7px;
                border: 0px;
                padding: 11px 35px;
                color: #fff;
                font-size: 15px;
                font-weight: 600;
                text-align: right; 
                border:0px;
                border-radius: 10px;
                cursor: pointer;   

                &:hover{
                    text-decoration: none;
                    background-color: #fffcf2;
                    color:#ffc107;
                    border: 1px solid #ffc107
                }
        }
        .square{
            // .saleswoman{
            //     width: 170% !important; 
            // }    
        }
    }
}
.service3{
    background-color: #ffffff;
    min-height: 750px;
    position: relative;
    margin-bottom: -50px;
    .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -10px;
        h3{
            font-size: 19px;
            font-weight: 700;
            margin: 5px 9px;
            color: #1a1a1a;
        }
    }
    .subtitle{
        p{
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            color:rgba(30, 26, 26, 0.64);
            margin-top: 20px;
        }
    }
    .sub-titles{
        margin-left: 20px;
        .cards{
            overflow: hidden;
            border: 0 !important;
            box-sizing: border-box;
            box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
            border-radius: 20px;
            
            .title{
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                color: #1a1a1a;
            }
            
            .text{
                color: #3377ef;
                text-align: center;
                font-weight: bolder;
                font-size: 20px;
            }
            
            .inner{
                overflow: hidden;
            }
            .image{
                transition: all 1.5s ease;
                
                &:hover{
                    transform: scale(1.3);
                }
            }

        }
    }
    .btn5{

        background-color: #fff;

        border-radius: 10px;
        border: 2px solid #3377ef;
        padding: 12px 30px;
        color: #3377ef;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        left: 48%;
        top:70%;
        cursor: pointer;
        margin-top: 90px;
        z-index: 1001;

        &:hover{
            text-decoration: none;
            background-color: #3377ef;
            color: #fff;
        }

    }
}
.service4{
    min-height: 580px;
    background-color: #f4f8fe;
    .sub-titles{
        width: 90%;
        .squares{
            h5{
                font-size: 20px;
                color: #1a1a1a;
            }
            h4{
                font-weight: 650;
                font-size: 25px;
                line-height: 35px;
                margin-top: -30px;
                color: #1a1a1a;
            }
            p{
                font-size: 13px;
                line-height: 24px;
                color: rgba(30, 26, 26, 0.64);
                font-weight: 600;
                font-style: normal;
                
            }
        }
    }
}
.service5{
    min-height: 500px;
    background-color: #fff;
    
    

    .bigbox{
    background-color: #fff;
    box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
    border-radius: 12px;
    min-height: 400px;
    margin-top: 20px;
    margin-left: 30px;
    width:95%;
    padding: 30px 30px;
    

    .smallbox3{
        background-color: #ffedf5;
        border-radius: 10px;
        padding-bottom: 40px !important;
        // min-height: 80px;
        
        // margin-left: 30px;
        

        .smallbox-img{
            width: 22%;
            text-align: center;
            margin-left: 195px;
            margin-top: 20px;
        }
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 25px;
            
        }
        p{
            font-size: 13px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }

        }
    
    .smallbox2{
        background-color: #e2ecfd;
        border-radius: 10px;
        margin-left: 50px;
        


        .smallbox-img{
            width: 22%;
            text-align: center;
            margin-left: 185px;
            margin-top: 20px;
        }
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 25px;
        }
        p{
            font-size: 13px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }

    }
}
.service6{
        // min-height: 600px;
        background-color: #3377EF;
        position: relative;


        .quote1{
            position: absolute;
            left: 10%;
            top: 20%;
        }
        .quote2{
        position: absolute;
        left:45%;
        top:55%;
        
        }
        .carousel-indicators{
            margin-bottom: -40px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 13px;
                font-weight: lighter;
            }
        }
        .carouseltext{
            margin-top: 0px;
            
            .caption{
             z-index: 3001;
                position: absolute;
                top: 0%;
                left: 15%;
                text-align: left;
                margin-top: 80px;

                p{
                    font-size: 17px;
                    
                }

               
            }
        }
        .carouselimage{
            float: right;
            
            

            .image{
                
                float: right;
                margin-left: -30px;
                display: inline-flex!important;
                
                
            }
            
           
            
        }

}

.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 1200px;
    .questions {
        .header {
            margin-bottom: 70px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 25px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 250px;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    right: 250px;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 10px;
            cursor: pointer;

            .faq-row {
                padding-top: 36px;
                padding-bottom: 36px;
                padding-left: 45px;
                padding-right: 45px;

                .arrow-wrapper {
                    .arrow {
                        width: 22px !important;
                        height: 22px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}

.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    .phones{

                        margin-bottom: -13px;
                    }
                }

                }
        }
    }
.service9{
    background-color: #3377EF;
    // min-height: 500px;
    width:100%;
    overflow: hidden;
    .footer{
        // display: flex;
        // flex-direction: row;
        // width:100%;
        .company{
            p {
                line-height: 1.7;
            }
        }
        .contact{
            // width: 30%;
            p {
                line-height: 1.7;
            }
        }
        // .content{
        //     margin-top: 80px;
            
        // }
        ul{
            list-style-type: none;
            
            li{
                color: #fff;
                font-size: 12px;
                width: max-content;
                margin-left: -32px;
                margin-top: 10px;
            }
        }

        h3{
            font-size: 18px;
            color: #fff;
        }
        p{
            font-size: 13px;
            font-weight: lighter;
            color: #fff;
        }
    }
    .social{
        display: flex;
        justify-content: center;
        text-align:center;
        margin-left: 70px;
    .dash{
        width: 5%;
    }
    .tweet{
        width: 2%;
    }
    .insta{
        width: 2%;
    }
    .link{
        width: 2%;
    }
    .face{
        width: 2%;
    }
    }
    .copyright{
        display: flex;
        text-align: center;
        justify-content: center;
        margin-left: 80px;
        color: #fff;
    }
}





// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media (min-width: 280px) {

.service{
        background-color: #ffffff;
        min-height: 1100px;
       
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -20px!important;
            margin-bottom: -30px!important;
            h3{
                font-size: 12px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 15%;
            }
            .right{
                width: 15%;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: -5px;
            
    
            .square{
               
                border-radius: 27px;
                min-height: 60px!important;
                border: 0!important;
                box-sizing: border-box;
                margin-right: 50px!important;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
                
                
    
    
                .eligible{
                    width:15%!important;
                    
                }
                h5{
                    font-size: 15px;
                    font-weight: 750;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                }
    
                &:hover {
                    transform: scale(1.03);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
    
        .sub{
            position: relative;
            margin-left: 10px;
            // margin-top: 100px;
    
            
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 13px;
                    margin-left: 20px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 13px;
                    font-weight: 800;
                    margin-left: 20px;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 14px;
                    word-spacing: 50%;
                    margin-left: 20px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 10px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
                    margin-left: 20px;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
        }   
            .square{
                .saleswoman{
                    
                    
                }
            }
        
    }
.service3{
        background-color: #ffffff;
        min-height: 1310px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px!important;
            margin-left: 5px;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 0px;
                margin-left: 40px;
            }
        }
        .sub-titles{
            margin-left: 35px;
            .cards{
                overflow: hidden;
                border: 0.5px solid rgba(51, 119, 239, 0.43)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 20px;
            color: #3377ef;
            font-size: 10px;
            font-weight: 600;
            position: absolute;
            left: 20%;
            top:85%;
            cursor: pointer;
            margin-left: 10px;
          
            // margin-bottom: 100px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                margin-left: 0px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                h4{
                    font-weight: 650;
                    font-size: 20px;
                    line-height: 35px;
                    margin-top: -50px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    margin-left: 0px!important;
                    
                }
            }
            .whoweare{
                margin-left: -40%!important;
                margin-top: 30%;
                margin-bottom: 20%;
            }

        }
    }
.service5{
        min-height: 500px;
        margin-bottom: -50px;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 15px;
        width:95%;
        padding: 30px 30px;
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            padding: 10px !important;
            
            // min-height: 80px;
            
            margin-left: 0px;
            
    
            .smallbox-img{
                width: 32%;
                text-align: center;
                margin-left: 40px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 15px;
                
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            padding: 10px !important;
            
            // min-height: 80px;
            
            margin-left: 0px;
            margin-top: 30px;
            
    
    
            .smallbox-img{
                width: 32%;
                text-align: center;
                margin-left: 40px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 15px;
                
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 400px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -100px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 13px;
            font-weight: 700;
            margin: 0px 3px;
            color: #fff;
            text-align: center;
        
        }
        .left{
            width: 20%;
        }
        .right{
            width: 20%;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 11px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                // margin-right: 120px;
                margin-top: 150px;

                p{
                    font-size: 12px;
                    
                }
                h5{
                    font-size: 12px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           margin-top: 150px;
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}

.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 700px;
    .questions {
        .header {
            margin-bottom: 50px;
            margin-top: -20px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 15px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 450px;
                    display: none;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    display: none;
                    right: 100px;
                    width: 1%;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 5px!important;
            cursor: pointer;
            .collapse{
                height: 130px!important;
                p{
                    font-size: 10px;
                    margin-left: -30px!important;
                    margin-right: -50px!important;
                    margin-bottom: -80px!important;
                }
            }
            .faq-row {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
                
                h5{
                    font-size: 10px;
                }
                .indicator-wrapper {
                    .indicator {
                        width: 50%;
                    }
                }

                .arrow-wrapper {
                    .arrow {
                        width: 12px !important;
                        height: 12px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}
.service8{
    background-color: #fff;
    position: relative;
    // margin-top: -90px!important;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                margin-top: -150px;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 35%;
                    }
                    .image4{
                        width:45%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 63.7%;
                        left: -3%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
    
}
@media (min-width: 320px) {
.service{
        background-color: #ffffff;
        min-height: 1180px;
       
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 14px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: -5px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                padding-right: 20px;
                border: 0!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 15px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                }
    
                &:hover {
                    transform: scale(1.03);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 13px;
                    margin-left: 40px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 13px;
                    font-weight: 700;
                    margin-left: 40px;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 14px;
                    word-spacing: 50%;
                    margin-left: 40px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
                    margin-left: 40px;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                
    
            }
            .square{
                position: relative;
                margin-top: 30px;
                margin-left: 40px;
    
                .saleswoman{
                    // width:30%;
                    position: relative;
                    
                }
                }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 1310px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px!important;
            margin-left: 5px;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 0px;
                margin-left: 40px;
            }
        }
        .sub-titles{
            margin-left: 35px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 20px;
            color: #3377ef;
            font-size: 10px;
            font-weight: 600;
            position: absolute;
            left: 20%;
            top:85%;
            cursor: pointer;
            margin-left: 20px;
          
            // margin-bottom: 100px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                margin-left: 0px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                h4{
                    font-weight: 650;
                    font-size: 20px;
                    line-height: 35px;
                    margin-top: -50px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    margin-left: 0px!important;
                    
                }
            }
            .whoweare{
                margin-left: 18%;
                margin-top: 30%;
                margin-bottom: 20%;
                width: 130%;
            }

        }
    }
.service5{
    min-height: 500px;
    margin-bottom: -50px;
    
    

    .bigbox{
    background-color: #fff;
    box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
    border-radius: 12px;
    min-height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 15px;
    width:95%;
    padding: 30px 30px;
    

    .smallbox3{
        background-color: #ffedf5;
        border-radius: 10px;
        padding: 10px !important;
        
        // min-height: 80px;
        
        margin-left: 0px;
        

        .smallbox-img{
            width: 32%;
            text-align: center;
            margin-left: 55px;
            margin-top: 20px;
        }
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 15px;
            
        }
        p{
            font-size: 10px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }

        }
    
    .smallbox2{
        background-color: #e2ecfd;
        border-radius: 10px;
        padding: 10px !important;
        
        // min-height: 80px;
        
        margin-left: 0px;
        margin-top: 30px;
        


        .smallbox-img{
            width: 32%;
            text-align: center;
            margin-left: 55px;
            margin-top: 20px;
        }
        h4{
            text-align: center;
            font-weight: 650;
            font-size: 15px;
            
        }
        p{
            font-size: 10px;
            line-height: 20px;
            color:#777679;
            font-weight: 600;
            font-style: normal;
            text-align: center;
            margin-top: 20px;
        }
    }

    }
}
.service6{
        min-height: 400px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -90px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 15px;
            font-weight: 700;
            margin: 0px 3px;
            color: #fff;
            text-align: center;
        
        }
        .left{
            width: 20%;
        }
        .right{
            width: 20%;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 11px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                // margin-right: 120px;
                margin-top: 0px;

                p{
                    font-size: 12px;
                    
                }
                h5{
                    font-size: 12px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 700px;
    .questions {
        .header {
            margin-bottom: 50px;
            margin-top: -20px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 15px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 450px;
                    display: none;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    display: none;
                    right: 100px;
                    width: 1%;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 5px!important;
            cursor: pointer;
            .collapse{
                height: 100px!important;
                p{
                    font-size: 10px;
                    margin-left: -30px!important;
                    margin-right: -50px!important;
                    margin-bottom: -80px!important;
                }
            }
            .faq-row {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
                
                h5{
                    font-size: 10px;
                }
                .indicator-wrapper {
                    .indicator {
                        width: 50%;
                    }
                }

                .arrow-wrapper {
                    .arrow {
                        width: 12px !important;
                        height: 12px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}
.service8{
    background-color: #fff;
    position: relative;
    // margin-top: -90px!important;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                margin-top: -150px;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 35%;
                    }
                    .image4{
                        width:45%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 55.7%;
                        left: -3%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
    
}
@media (min-width: 330px) {
.service{
        background-color: #ffffff;
        min-height: 1180px;
       
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: -5px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                padding-right: 20px;
                border: 0!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 15px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                }
    
                &:hover {
                    transform: scale(1.03);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            .squares{
                margin-top: -20px;
                margin-left: 5px;
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 16px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 15px;
                    font-weight: 700;
                }
    
                p{
                    color: #7d7975;
                    font-size: 14px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
            }
            .square{
                position: relative;
                margin-top: 30px;
                margin-left: 40px;
    
                .saleswoman{
                    // width:30%;
                    position: relative;
                    
                }
                .profit{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position: absolute;
                    top:210px;
                    left:-30px;
                    p{
                        font-size: 10px;
                        color: #1a1a1a;
                        font-weight: 600;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .increase{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:210px;
                    left:200px;
                    p{
                        font-size: 10px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .customer{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width:35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:10px;
                    left:200px;
                   
                    p{
                        font-size: 10px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 1310px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px!important;
            margin-left: 5px;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 0px;
                margin-left: 40px;
            }
        }
        .sub-titles{
            margin-left: 35px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 16%;
            top:85%;
            cursor: pointer;
          
            // margin-bottom: 100px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                margin-left: 0px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                h4{
                    font-weight: 650;
                    font-size: 20px;
                    line-height: 35px;
                    margin-top: -50px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    margin-left: 0px!important;
                    
                }
            }
            .whoweare{
                margin-left: 18%;
                margin-top: 30%;
                margin-bottom: 20%;
                width: 130%;
            }

        }
    }
.service5{
        min-height: 500px;
        margin-bottom: -50px;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 10px;
        width:95%;
        // padding: 10px 10px;
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            padding: 10px !important;
            width: 100%;
            
            // min-height: 80px;
            
            margin-left: 20px;
            
    
            .smallbox-img{
                width: 42%;
                text-align: center;
                margin-left: 40px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
                
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            padding: 10px !important;
            
            // min-height: 80px;
            
            margin-left: 20px;
            margin-top: 30px;
            
    
    
            .smallbox-img{
                width: 42%;
                text-align: center;
                margin-left: 40px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 400px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -90px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 15px;
            font-weight: 700;
            margin: 0px 3px;
            color: #fff;
            text-align: center;
        
        }
        .left{
            width: 20%;
        }
        .right{
            width: 20%;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 11px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                // margin-right: 120px;
                margin-top: 0px;

                p{
                    font-size: 12px;
                    
                }
                h5{
                    font-size: 12px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service8{
    background-color: #fff;
    position: relative;
    // margin-top: -90px!important;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                margin-top: -150px;
                
                    
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 53.7%;
                        left: -3%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
    
}
@media (min-width: 360px) { 
    
    .service{
            background-color: #ffffff;
            min-height: 1180px;
           
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .sub-titles{
                margin-top: 40px;
                margin-left: 05px;
                
        
                .square{
                   
                    border-radius: 27px;
                    padding-top: 5px;
                    padding-bottom: 20px;
                    min-height: 60px!important;
                    padding-right: 20px;
                    border: 0!important;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                    transition: transform .2s;
        
                    
        
        
                    .eligible{
                        width:20%;
                        
                    }
                    h5{
                        font-size: 15px;
                        font-weight: 650;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 13px;
                    }
        
                    &:hover {
                        transform: scale(1.03);
                        border: 1px solid #3377EF!important;
                    }
                }
            }
        }
    .service2{
            background-color: #fffcf2;
            min-height: 600px;
            position: relative;
        
            .sub{
                position: relative;
                // margin-top: 100px;
        
                
                
                    
        
                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 600;
                        font-size: 15px;
                        margin-left: 40px;
                        font-family: 'Sofia Pro', sans-serif;
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 15px;
                        font-weight: 700;
                        margin-left: 40px;
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
                        margin-left: 40px;
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-top: 15px;
                        z-index: 1001;
                        margin-left: 40px;
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 2px solid #ffc107
                        }
                        
                    }
        
                
                .square{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 60px;
        
                    .saleswoman{
                        // width:30%;
                        position: relative;
                        
                    }
                    .profit{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position: absolute;
                        top:210px;
                        left:-30px;
                        p{
                            font-size: 10px;
                            color: #1a1a1a;
                            font-weight: 600;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .increase{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:210px;
                        left:200px;
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .customer{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width:35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:10px;
                        left:200px;
                       
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    
                }
            }
        }
    .service3{
            background-color: #ffffff;
            min-height: 1350px;
            position: relative;
            margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px!important;
                margin-left: 5px;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 11px;
                    font-weight: 600;
                    color:rgba(30, 26, 26, 0.64);
                    margin-top: 0px;
                    margin-left: 40px;
                }
            }
            .sub-titles{
                margin-left: 50px;
                .cards{
                    overflow: hidden;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
        
                border-radius: 10px;
                border: 2px solid #3377ef;
                padding: 12px 30px;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                left: 23%;
                top:93%;
                cursor: pointer;
                margin-top: 20px;
                margin-left: -5px;
                z-index: 1001;
        
                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
        }
    .service4{
            min-height: 580px;
            background-color: #f4f8fe;
            margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                   margin-left: -10px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 20px;
                        line-height: 35px;
                        margin-top: -50px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    p{
                        font-size: 13px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 0px!important;
                        
                    }
                }
                
                    .whoweare{
                        margin-top: 0%;
                        margin-left: -80px!important;
                        margin-bottom: 20%;
                        width:120%!important;
                    }
                
    
            }
        }
    .service5{
            min-height: 500px;
            margin-bottom: -50px;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 15px;
            width:95%;
            padding: 30px 30px;
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 65px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 15px;
                    
                }
                p{
                    font-size: 8px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                margin-top: 30px;
                
        
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 65px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 15px;
                    
                }
                p{
                    font-size: 8px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
        }
    .service6{
            min-height: 400px;
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
    
            .carousel-indicators{
                margin-bottom: -90px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                   
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                   
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 0px 3px;
                color: #fff;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 11px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
               
                margin-top: 0px;
                
                .caption{
                 display: block !important;;
                //  z-index: 3001;
                    // position: absolute;
                    // top: 0%;
                    // left: 100%;
                    // text-align: left;
                    // margin-right: 120px;
                    margin-top: 0px;
    
                    p{
                        font-size: 12px;
                        
                    }
                    h5{
                        font-size: 12px;
                    }
    
                   
                }
            }
            .carouselimage{
                // float: right;
                // margin-left: -370px!important;
               
                .image{
                    
                    // float: right;
                    // margin-left: -30px;
                    display: inline-flex!important;
                    
        
                   
                    
                    
                }
                
               
                
            }
    
    }
    .service7 {
        padding-top: 80px;
        background-color: #fff;
        margin-bottom: -50px;
        min-height: 700px;
        .questions {
            .header {
                margin-bottom: 50px;
                margin-top: -20px;
                .title {
                    text-align: center;
                    position: relative;
                    margin: 0;
                    font-weight: 600;
                    font-size: 15px;
                    &::before {
                        content: url('../../_assets/icon/left.svg');
                        position: absolute;
                        left: 450px;
                        display: none;
                    }
                    &::after {
                        content: url('../../_assets/icon/right.svg');
                        position: absolute;
                        display: none;
                        right: 100px;
                        width: 1%;
                    }
                }
            }
    
            .faq-wrapper {
                box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
                margin-bottom: 20px;
                border-radius: 7px;
                width:95%;
                margin-left: 5px!important;
                cursor: pointer;
                .collapse{
                    height: 110px!important;
                    p{
                        font-size: 10px;
                        margin-left: -30px!important;
                        margin-right: -50px!important;
                        margin-bottom: -80px!important;
                    }
                }
                .faq-row {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    
                    h5{
                        font-size: 10px;
                    }
                    .indicator-wrapper {
                        .indicator {
                            width: 50%;
                        }
                    }
    
                    .arrow-wrapper {
                        .arrow {
                            width: 12px !important;
                            height: 12px !important;
                            transform: rotate(0deg); 
                            transition: transform .3s linear;
                        
                        &.open{
                            transition: transform .3s linear;
                            transform: rotate(180deg);
                                }
                        
                        }
                    }
                }
            }
        }
    }
    .service8{
        background-color: #fff;
        position: relative;
        // margin-top: -90px!important;
    
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    margin-top: -70px;
                    
                        .image3{
                            
                            margin-top: 20px;
                            
                            width: 35%;
                        }
                        .image4{
                            width:45%;
                            margin-top: 20px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 100px!important;
                            margin-bottom: 20px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                        }
                        
                    }
                    .image{
                        position: relative;
                        .phones{
                            position: absolute;
                            top: 54.5%;
                            left: -3%;
                            margin-bottom: -43px!important;
                        }
                    }
    
                    }
            }
        }
    }
@media (min-width: 370px) { 
    
.service{
        background-color: #ffffff;
        min-height: 1180px;
       
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: 05px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                padding-right: 20px;
                border: 0!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 15px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                }
    
                &:hover {
                    transform: scale(1.03);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            .squares{
                margin-top: -20px;
                margin-left: 5px;
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 20px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 20px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 16px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
            }
            .square{
                position: relative;
                margin-top: 30px;
                margin-left: 60px;
    
                .saleswoman{
                    // width:30%;
                    position: relative;
                    
                }
                .profit{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position: absolute;
                    top:210px;
                    left:-30px;
                    p{
                        font-size: 10px;
                        color: #1a1a1a;
                        font-weight: 600;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .increase{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:210px;
                    left:200px;
                    p{
                        font-size: 10px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .customer{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width:35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:10px;
                    left:200px;
                   
                    p{
                        font-size: 10px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 1350px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px!important;
            margin-left: 5px;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 15px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 0px;
                margin-left: 40px;
            }
        }
        .sub-titles{
            margin-left: 50px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
            background-color: #fff;
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 23%;
            top:93%;
            cursor: pointer;
            margin-top: 20px;
            // margin-bottom: 100px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                margin-left: 60px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                h4{
                    font-weight: 650;
                    font-size: 20px;
                    line-height: 35px;
                    margin-top: -50px;
                    color: #1a1a1a;
                    margin-left: 0px!important;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    margin-left: 0px!important;
                    
                }
            }
            .whoweare{
                margin-left: 25%;
                margin-top: 30%;
                margin-bottom: 20%;
                width: 130%;
            }

        }
    }
.service5{
        min-height: 500px;
        margin-bottom: -50px;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 15px;
        width:95%;
        padding: 30px 30px;
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            padding: 10px !important;
            
            // min-height: 80px;
            
            margin-left: 20px;
            
    
            .smallbox-img{
                width: 42%;
                text-align: center;
                margin-left: 50px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
                
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            padding: 10px !important;
            
            // min-height: 80px;
            
            margin-left: 20px;
            margin-top: 30px;
            
    
    
            .smallbox-img{
                width: 42%;
                text-align: center;
                margin-left: 50px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 20px;
            }
            p{
                font-size: 10px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 400px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -90px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 15px;
            font-weight: 700;
            margin: 0px 3px;
            color: #fff;
            text-align: center;
        
        }
        .left{
            width: 20%;
        }
        .right{
            width: 20%;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 11px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                // margin-right: 120px;
                margin-top: 0px;

                p{
                    font-size: 12px;
                    
                }
                h5{
                    font-size: 12px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service8{
    background-color: #fff;
    position: relative;
    // margin-top: -90px!important;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                margin-top: -70px;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 35%;
                    }
                    .image4{
                        width:45%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 100px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 53%;
                        left: -3%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }

}
@media (min-width: 375px) { 
    
    .service{
            background-color: #ffffff;
            min-height: 1180px;
           
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .sub-titles{
                margin-top: 40px;
                margin-left: 05px;
                
        
                .square{
                   
                    border-radius: 27px;
                    padding-top: 5px;
                    padding-bottom: 20px;
                    min-height: 60px!important;
                    padding-right: 20px;
                    border: 0!important;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                    transition: transform .2s;
        
                    
        
        
                    .eligible{
                        width:20%;
                        
                    }
                    h5{
                        font-size: 15px;
                        font-weight: 650;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 13px;
                    }
        
                    &:hover {
                        transform: scale(1.03);
                        border: 1px solid #3377EF!important;
                    }
                }
            }
        }
    .service2{
            background-color: #fffcf2;
            min-height: 600px;
            position: relative;
        
            .sub{
                position: relative;
                // margin-top: 100px;
        
                
                .squares{
                    margin-top: -20px;
                    margin-left: 5px;
        
                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 600;
                        font-size: 20px;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 20px;
                        font-weight: 700;
        
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-top: 15px;
                        z-index: 1001;
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 2px solid #ffc107
                        }
                        
                    }
        
                }
                .square{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 60px;
        
                    .saleswoman{
                        // width:30%;
                        position: relative;
                        
                    }
                    .profit{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position: absolute;
                        top:210px;
                        left:-30px;
                        p{
                            font-size: 10px;
                            color: #1a1a1a;
                            font-weight: 600;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .increase{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:210px;
                        left:200px;
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .customer{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width:35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:10px;
                        left:200px;
                       
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    
                }
            }
        }
    .service3{
            background-color: #ffffff;
            min-height: 1350px;
            position: relative;
            margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px!important;
                margin-left: 5px;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 11px;
                    font-weight: 600;
                    color:rgba(30, 26, 26, 0.64);
                    margin-top: 0px;
                    margin-left: 40px;
                }
            }
            .sub-titles{
                margin-left: 50px;
                .cards{
                    overflow: hidden;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
        
                border-radius: 10px;
                border: 2px solid #3377ef;
                padding: 12px 30px;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                left: 23%;
                top:93%;
                cursor: pointer;
                margin-top: 20px;
                // margin-bottom: 100px;
                z-index: 1001;
        
                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
        }
    .service4{
            min-height: 580px;
            background-color: #f4f8fe;
            margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                    margin-left: 0px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 20px;
                        line-height: 35px;
                        margin-top: -50px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    p{
                        font-size: 13px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 0px!important;
                        
                    }
                }
                .whoweare{
                    margin-left: 17%;
                    margin-top: 30%;
                    margin-bottom: 20%;
                    width: 130%;
                }
    
            }
        }
        .service5{
            min-height: 500px;
            margin-bottom: -50px;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 15px;
            width:95%;
            padding: 30px 30px;
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 75px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 15px;
                    
                }
                p{
                    font-size: 8px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                margin-top: 30px;
                
        
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 75px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 15px;
                    
                }
                p{
                    font-size: 8px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
        }
    .service6{
            min-height: 400px;
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
    
            .carousel-indicators{
                margin-bottom: -90px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                   
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                   
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 0px 3px;
                color: #fff;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 11px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
               
                margin-top: 0px;
                
                .caption{
                 display: block !important;;
                //  z-index: 3001;
                    // position: absolute;
                    // top: 0%;
                    // left: 100%;
                    // text-align: left;
                    // margin-right: 120px;
                    margin-top: 0px;
    
                    p{
                        font-size: 12px;
                        
                    }
                    h5{
                        font-size: 12px;
                    }
    
                   
                }
            }
            .carouselimage{
                // float: right;
                // margin-left: -370px!important;
               
                .image{
                    
                    // float: right;
                    // margin-left: -30px;
                    display: inline-flex!important;
                    
        
                   
                    
                    
                }
                
               
                
            }
    
    }
    .service7 {
        padding-top: 80px;
        background-color: #fff;
        margin-bottom: -50px;
        min-height: 700px;
        .questions {
            .header {
                margin-bottom: 50px;
                margin-top: -20px;
                .title {
                    text-align: center;
                    position: relative;
                    margin: 0;
                    font-weight: 600;
                    font-size: 15px;
                    &::before {
                        content: url('../../_assets/icon/left.svg');
                        position: absolute;
                        left: 450px;
                        display: none;
                    }
                    &::after {
                        content: url('../../_assets/icon/right.svg');
                        position: absolute;
                        display: none;
                        right: 100px;
                        width: 1%;
                    }
                }
            }
    
            .faq-wrapper {
                box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
                margin-bottom: 20px;
                border-radius: 7px;
                width:95%;
                margin-left: 5px!important;
                cursor: pointer;
                .collapse{
                    height: 90px!important;
                    p{
                        font-size: 10px;
                        margin-left: -30px!important;
                        margin-right: -50px!important;
                        margin-bottom: -80px!important;
                    }
                }
                .faq-row {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    
                    h5{
                        font-size: 10px;
                    }
                    .indicator-wrapper {
                        .indicator {
                            width: 50%;
                        }
                    }
    
                    .arrow-wrapper {
                        .arrow {
                            width: 12px !important;
                            height: 12px !important;
                            transform: rotate(0deg); 
                            transition: transform .3s linear;
                        
                        &.open{
                            transition: transform .3s linear;
                            transform: rotate(180deg);
                                }
                        
                        }
                    }
                }
            }
        }
    }
    .service8{
        background-color: #fff;
        position: relative;
        // margin-top: -90px!important;
    
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    margin-top: -70px;
                    
                        .image3{
                            
                            margin-top: 20px;
                            
                            width: 35%;
                        }
                        .image4{
                            width:45%;
                            margin-top: 20px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 100px!important;
                            margin-bottom: 20px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                        }
                        
                    }
                    .image{
                        position: relative;
                        .phones{
                            position: absolute;
                            top: 53%;
                            left: -3%;
                            margin-bottom: -43px!important;
                        }
                    }
    
                    }
            }
        }
    }
@media (min-width: 410px) { 
    
    .service{
            background-color: #ffffff;
            min-height: 1180px;
           
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .sub-titles{
                margin-top: 40px;
                margin-left: 05px;
                
        
                .square{
                   
                    border-radius: 27px;
                    padding-top: 5px;
                    padding-bottom: 20px;
                    min-height: 60px!important;
                    padding-right: 20px;
                    border: 0!important;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                    transition: transform .2s;
        
                    
        
        
                    .eligible{
                        width:20%;
                        
                    }
                    h5{
                        font-size: 15px;
                        font-weight: 650;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 13px;
                    }
        
                    &:hover {
                        transform: scale(1.03);
                        border: 1px solid #3377EF!important;
                    }
                }
            }
        }
    .service2{
            background-color: #fffcf2;
            min-height: 600px;
            position: relative;
        
            .sub{
                position: relative;
                // margin-top: 100px;
        
                
                .squares{
                    margin-top: -20px;
                    margin-left: 5px;
        
                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 600;
                        font-size: 20px;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 20px;
                        font-weight: 700;
        
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-top: 15px;
                        z-index: 1001;
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 2px solid #ffc107
                        }
                        
                    }
        
                }
                .square{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 60px;
        
                    .saleswoman{
                        // width:30%;
                        position: relative;
                        
                    }
                    .profit{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position: absolute;
                        top:210px;
                        left:-30px;
                        p{
                            font-size: 10px;
                            color: #1a1a1a;
                            font-weight: 600;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .increase{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:210px;
                        left:200px;
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .customer{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width:35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:10px;
                        left:200px;
                       
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    
                }
            }
    }
    .service3{
            background-color: #ffffff;
            min-height: 1350px;
            position: relative;
            margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px!important;
                margin-left: 5px;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 11px;
                    font-weight: 600;
                    color:rgba(30, 26, 26, 0.64);
                    margin-top: 0px;
                    margin-left: 40px;
                }
            }
            .sub-titles{
                margin-left: 50px;
                .cards{
                    overflow: hidden;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
        
                border-radius: 10px;
                border: 2px solid #3377ef;
                padding: 12px 30px;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                left: 23%;
                top:93%;
                cursor: pointer;
                margin-top: 20px;
                // margin-bottom: 100px;
                z-index: 1001;
        
                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
        }
    .service4{
            min-height: 580px;
            background-color: #f4f8fe;
            margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                    margin-left: 0px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 20px;
                        line-height: 35px;
                        margin-top: -50px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    p{
                        font-size: 13px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 0px!important;
                        
                    }
                }
                .whoweare{
                    margin-left: 15%;
                    margin-top: 30%;
                    margin-bottom: 20%;
                    width: 130%;
                }
    
            }
        }
    .service5{
            min-height: 500px;
            margin-bottom: -50px;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 15px;
            width:95%;
            padding: 30px 30px;
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 20px;
                
        
                .smallbox-img{
                    width: 42%;
                    text-align: center;
                    margin-left: 50px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 20px;
                    
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 20px;
                margin-top: 30px;
                
        
        
                .smallbox-img{
                    width: 42%;
                    text-align: center;
                    margin-left: 50px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 20px;
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
        }
    .service6{
            min-height: 400px;
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
    
            .carousel-indicators{
                margin-bottom: -90px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                   
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                   
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 0px 3px;
                color: #fff;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 11px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
               
                margin-top: 0px;
                
                .caption{
                 display: block !important;;
                //  z-index: 3001;
                    // position: absolute;
                    // top: 0%;
                    // left: 100%;
                    // text-align: left;
                    // margin-right: 120px;
                    margin-top: 0px;
    
                    p{
                        font-size: 12px;
                        
                    }
                    h5{
                        font-size: 12px;
                    }
    
                   
                }
            }
            .carouselimage{
                // float: right;
                // margin-left: -370px!important;
               
                .image{
                    
                    // float: right;
                    // margin-left: -30px;
                    display: inline-flex!important;
                    
        
                   
                    
                    
                }
                
               
                
            }
    
    }
    .service8{
        background-color: #fff;
        position: relative;
        // margin-top: -90px!important;
    
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    margin-top: -70px;
                    
                        .image3{
                            
                            margin-top: 20px;
                            
                            width: 35%;
                        }
                        .image4{
                            width:45%;
                            margin-top: 20px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 100px!important;
                            margin-bottom: 20px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                        }
                        
                    }
                    .image{
                        position: relative;
                        .phones{
                            position: absolute;
                            top: 43.3%;
                            left: -3%;
                            margin-bottom: -43px!important;
                        }
                    }
    
                    }
            }
        }
    }
@media (min-width: 411px) { 
    
    .service{
            background-color: #ffffff;
            min-height: 1180px;
            
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .sub-titles{
                margin-top: 40px;
                margin-left: 05px;
                
        
                .square{
                    
                    border-radius: 27px;
                    padding-top: 5px;
                    padding-bottom: 20px;
                    min-height: 60px!important;
                    padding-right: 20px;
                    border: 0!important;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                    transition: transform .2s;
        
                    
        
        
                    .eligible{
                        width:20%;
                        
                    }
                    h5{
                        font-size: 15px;
                        font-weight: 650;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 13px;
                    }
        
                    &:hover {
                        transform: scale(1.03);
                        border: 1px solid #3377EF!important;
                    }
                }
            }
        }
    .service2{
            background-color: #fffcf2;
            min-height: 600px;
            position: relative;
        
            .sub{
                position: relative;
                // margin-top: 100px;                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 600;
                        font-size: 17px;
                        margin-left: 50px;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 17px;
                        margin-left: 50px;
                        font-weight: 700;
        
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        margin-left: 50px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        margin-left: 50px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-top: 15px;
                        z-index: 1001;
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 2px solid #ffc107
                        }
                        
                    }
        
                
                .square{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 50px;
        
                    .saleswoman{
                        // width:30%;
                        position: relative;
                        
                    }
                    .profit{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position: absolute;
                        top:240px;
                        left:-30px;
                        p{
                            font-size: 10px;
                            color: #1a1a1a;
                            font-weight: 600;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .increase{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:240px;
                        left:240px;
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .customer{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width:35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:10px;
                        left:240px;
                        
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    
                }
            }
    }
    .service3{
            background-color: #ffffff;
            min-height: 1350px;
            position: relative;
            margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px!important;
                margin-left: 5px;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 11px;
                    font-weight: 600;
                    color:rgba(30, 26, 26, 0.64);
                    margin-top: 0px;
                    margin-left: 40px;
                }
            }
            .sub-titles{
                margin-left: 50px;
                .cards{
                    overflow: hidden;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
        
                border-radius: 10px;
                border: 2px solid #3377ef;
                padding: 12px 30px;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                left: 23%;
                top:93%;
                cursor: pointer;
                margin-top: 20px;
                margin-left: 10px;
                // margin-bottom: 100px;
                z-index: 1001;
        
                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
        }
    .service4{
            min-height: 580px;
            background-color: #f4f8fe;
            margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                    margin-left: 0px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 20px;
                        line-height: 35px;
                        margin-top: -50px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    p{
                        font-size: 13px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 0px!important;
                        
                    }
                }
                .whoweare{
                    margin-left: -20%!important;
                    margin-top: 30%;
                    margin-bottom: 20%;
                    width: 130%;
                }
    
            }
        }
    .service5{
            min-height: 500px;
            margin-bottom: -50px;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 15px;
            width:95%;
            padding: 30px 30px;
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 80px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 18px;
                    
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                margin-top: 30px;
                
        
        
                .smallbox-img{
                    width: 32%;
                    text-align: center;
                    margin-left: 80px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 18px;
                    
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
        }
    .service6{
            min-height: 400px;
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
    
            .carousel-indicators{
                margin-bottom: -90px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                    
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                    
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 0px 3px;
                color: #fff;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 11px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
                
                margin-top: 0px;
                
                .caption{
                    display: block !important;;
                //  z-index: 3001;
                    // position: absolute;
                    // top: 0%;
                    // left: 100%;
                    // text-align: left;
                    // margin-right: 120px;
                    margin-top: 0px;
    
                    p{
                        font-size: 12px;
                        
                    }
                    h5{
                        font-size: 12px;
                    }
    
                    
                }
            }
            .carouselimage{
                // float: right;
                // margin-left: -370px!important;
                
                .image{
                    
                    // float: right;
                    // margin-left: -30px;
                    display: inline-flex!important;
                    
        
                    
                    
                    
                }
                
                
                
            }
    
    }
    .service7 {
        padding-top: 80px;
        background-color: #fff;
        margin-bottom: -50px;
        min-height: 700px;
        .questions {
            .header {
                margin-bottom: 50px;
                margin-top: -20px;
                .title {
                    text-align: center;
                    position: relative;
                    margin: 0;
                    font-weight: 600;
                    font-size: 15px;
                    &::before {
                        content: url('../../_assets/icon/left.svg');
                        position: absolute;
                        left: 450px;
                        display: none;
                    }
                    &::after {
                        content: url('../../_assets/icon/right.svg');
                        position: absolute;
                        display: none;
                        right: 100px;
                        width: 1%;
                    }
                }
            }
    
            .faq-wrapper {
                box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
                margin-bottom: 20px;
                border-radius: 7px;
                width:95%;
                margin-left: 5px!important;
                cursor: pointer;
                .collapse{
                    height: 100px!important;
                    p{
                        font-size: 10px;
                        margin-left: -30px!important;
                        margin-right: -50px!important;
                        margin-bottom: -80px!important;
                    }
                }
                .faq-row {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    
                    h5{
                        font-size: 10px;
                    }
                    .indicator-wrapper {
                        .indicator {
                            width: 50%;
                        }
                    }
    
                    .arrow-wrapper {
                        .arrow {
                            width: 12px !important;
                            height: 12px !important;
                            transform: rotate(0deg); 
                            transition: transform .3s linear;
                        
                        &.open{
                            transition: transform .3s linear;
                            transform: rotate(180deg);
                                }
                        
                        }
                    }
                }
            }
        }
    }
    .service8{
        background-color: #fff;
        position: relative;
        // margin-top: -90px!important;
    
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    margin-top: -70px;
                    
                        .image3{
                            
                            margin-top: 20px;
                            
                            width: 35%;
                        }
                        .image4{
                            width:45%;
                            margin-top: 20px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 100px!important;
                            margin-bottom: 20px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                        }
                        
                    }
                    .image{
                        position: relative;
                        .phones{
                            position: absolute;
                            top: 43.3%;
                            left: -3%;
                            margin-bottom: -43px!important;
                        }
                    }
    
                    }
            }
        }
        }
@media (min-width: 414px) { 
            .service{
                    background-color: #ffffff;
                    min-height: 1180px;
                   
                    .title{
                        width: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            font-size: 15px;
                            font-weight: 700;
                            margin: 15px 9px;
                            color: #1a1a1a;
                            text-align: center;
                        
                        }
                        .left{
                            width: 20%;
                        }
                        .right{
                            width: 20%;
                        }
                    }
                    .sub-titles{
                        margin-top: 40px;
                        margin-left: 05px;
                        
                
                        .square{
                           
                            border-radius: 27px;
                            padding-top: 5px;
                            padding-bottom: 20px;
                            min-height: 60px!important;
                            padding-right: 20px;
                            border: 0!important;
                            box-sizing: border-box;
                            box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                            transition: transform .2s;
                
                            
                
                
                            .eligible{
                                width:20%;
                                
                            }
                            h5{
                                font-size: 15px;
                                font-weight: 650;
                                color: #1a1a1a;
                            }
                            p{
                                font-size: 13px;
                            }
                
                            &:hover {
                                transform: scale(1.03);
                                border: 1px solid #3377EF!important;
                            }
                        }
                    }
                }
            .service2{
                    background-color: #fffcf2;
                    min-height: 600px;
                    position: relative;
                
                    .sub{
                        position: relative;
                        // margin-top: 100px;
                
                        
                        .squares{
                            margin-top: -20px;
                            margin-left: 5px;
                
                            
                            h5{
                                color: #1E1A1A;
                                font-weight: 600;
                                font-size: 20px;
                                font-family: 'Sofia Pro', sans-serif;
                
                            }
                
                            h4{
                                color: #1E1A1A;
                                font-size: 20px;
                                font-weight: 700;
                
                            }
                
                            p{
                                color: #7d7975;
                                font-size: 16px;
                                word-spacing: 50%;
                                font-family: 'Sofia Pro', sans-serif;
                
                            }
                
                            .btn4{
                                background-color: #ffc107;
                                border-radius: 7px;
                                border: 0px;
                                padding: 11px 35px;
                                color: #fff;
                                font-size: 15px;
                                font-weight: 600;
                                text-align: right; 
                                border:0px;
                                border-radius: 10px;
                                cursor: pointer;
                                margin-top: 15px;
                                z-index: 1001;
                
                                &:hover{
                                    text-decoration: none;
                                    background-color: #fffcf2;
                                    color:#ffc107;
                                    border: 2px solid #ffc107
                                }
                                
                            }
                
                        }
                        .square{
                            position: relative;
                            margin-top: 30px;
                            margin-left: 60px;
                
                            .saleswoman{
                                // width:30%;
                                position: relative;
                                
                            }
                            .profit{
                                background-color: #ffffff;
                                padding: 10px 10px;
                                width: 35%;
                                border-radius: 20px;
                                box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                                text-align: center;
                                position: absolute;
                                top:240px;
                                left:-30px;
                                p{
                                    font-size: 10px;
                                    color: #1a1a1a;
                                    font-weight: 600;
                                }
                                .percent{
                                    width:25%;
                                }
                            }
                            .increase{
                                background-color: #ffffff;
                                padding: 10px 10px;
                                width: 35%;
                                border-radius: 20px;
                                box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                                text-align: center;
                                position:absolute;
                                top:240px;
                                left:220px;
                                p{
                                    font-size: 10px;
                                    font-weight: 600;
                                    color: #1a1a1a;
                                }
                                .percent{
                                    width:25%;
                                }
                            }
                            .customer{
                                background-color: #ffffff;
                                padding: 10px 10px;
                                width:35%;
                                border-radius: 20px;
                                box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                                text-align: center;
                                position:absolute;
                                top:10px;
                                left:220px;
                               
                                p{
                                    font-size: 10px;
                                    font-weight: 600;
                                    color: #1a1a1a;
                                }
                                .percent{
                                    width:25%;
                                }
                            }
                            
                        }
                    }
            }
            .service3{
                    background-color: #ffffff;
                    min-height: 1400px;
                    position: relative;
                    margin-bottom: -50px;
                
                    .title{
                        width: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 5px!important;
                        margin-left: 5px;
                        h3{
                            font-size: 15px;
                            font-weight: 700;
                            margin: 15px 9px;
                            color: #1a1a1a;
                            text-align: center;
                        
                        }
                        .left{
                            width: 20%;
                        }
                        .right{
                            width: 20%;
                        }
                    }
                    .subtitle{
                        p{
                            text-align: center;
                            font-size: 11px;
                            font-weight: 600;
                            color:rgba(30, 26, 26, 0.64);
                            margin-top: 0px;
                            margin-left: 40px;
                        }
                    }
                    .sub-titles{
                        margin-left: 50px;
                        .cards{
                            overflow: hidden;
                            border: 1px solid rgba(51, 119, 239, 0.25)!important;
                            box-sizing: border-box;
                            box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                            border-radius: 20px;
                            
                            .title{
                                font-size: 14px;
                                font-weight: 700;
                                text-align: center;
                                color: #1a1a1a;
                            }
                            
                            .text{
                                color: #3377ef;
                                text-align: center;
                                font-weight: bolder;
                                font-size: 20px;
                            }
                            
                            .inner{
                                overflow: hidden;
                            }
                            .image{
                                transition: all 1.5s ease;
                                
                                &:hover{
                                    transform: scale(1.3);
                                }
                            }
                
                        }
                    }
                    .btn5{
                
                        background-color: #fff;
                
                        border-radius: 10px;
                        border: 2px solid #3377ef;
                        padding: 12px 30px;
                        color: #3377ef;
                        font-size: 14px;
                        font-weight: 600;
                        position: absolute;
                        left: 23%;
                        top:93%;
                        cursor: pointer;
                        margin-top: 20px;
                        // margin-bottom: 100px;
                        z-index: 1001;
                
                        &:hover{
                            text-decoration: none;
                            background-color: #3377ef;
                            color: #fff;
                        }
                
                    }
                }
            .service4{
                    min-height: 580px;
                    background-color: #f4f8fe;
                    margin-bottom: -50px;
                    .sub-titles{
                        width: 100%;
                        .squares{
                            margin-left: 0px;
                            h5{
                                font-size: 20px;
                                color: #1a1a1a;
                                margin-left: 0px!important;
                            }
                            h4{
                                font-weight: 650;
                                font-size: 20px;
                                line-height: 35px;
                                margin-top: -50px;
                                color: #1a1a1a;
                                margin-left: 0px!important;
                            }
                            p{
                                font-size: 13px;
                                line-height: 24px;
                                color: rgba(30, 26, 26, 0.64);
                                font-weight: 600;
                                font-style: normal;
                                margin-left: 0px!important;
                                
                            }
                        }
                        .whoweare{
                            margin-left: 15%;
                            margin-top: 30%;
                            margin-bottom: 20%;
                            width: 130%;
                        }
            
                    }
                }
                .service5{
                    min-height: 500px;
                    margin-bottom: -50px;
                    
                    
                
                    .bigbox{
                    background-color: #fff;
                    box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
                    border-radius: 12px;
                    min-height: 400px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    margin-left: 15px;
                    width:95%;
                    padding: 30px 30px;
                    
                
                    .smallbox3{
                        background-color: #ffedf5;
                        border-radius: 10px;
                        padding: 10px !important;
                        
                        // min-height: 80px;
                        
                        margin-left: 0px;
                        
                
                        .smallbox-img{
                            width: 32%;
                            text-align: center;
                            margin-left: 80px;
                            margin-top: 20px;
                        }
                        h4{
                            text-align: center;
                            font-weight: 650;
                            font-size: 18px;
                            
                        }
                        p{
                            font-size: 10px;
                            line-height: 20px;
                            color:#777679;
                            font-weight: 600;
                            font-style: normal;
                            text-align: center;
                            margin-top: 20px;
                        }
                
                        }
                    
                    .smallbox2{
                        background-color: #e2ecfd;
                        border-radius: 10px;
                        padding: 10px !important;
                        
                        // min-height: 80px;
                        
                        margin-left: 0px;
                        margin-top: 30px;
                        
                
                
                        .smallbox-img{
                            width: 32%;
                            text-align: center;
                            margin-left: 80px;
                            margin-top: 20px;
                        }
                        h4{
                            text-align: center;
                            font-weight: 650;
                            font-size: 18px;
                            
                        }
                        p{
                            font-size: 10px;
                            line-height: 20px;
                            color:#777679;
                            font-weight: 600;
                            font-style: normal;
                            text-align: center;
                            margin-top: 20px;
                        }
                    }
                
                    }
                }
            .service6{
                    min-height: 400px;
                    background-color: #3377EF;
                    position: relative;
                    margin-bottom: -50px;

                    .carousel-indicators{
                        margin-bottom: -90px;
                    }
                    .carousel-control-prev{
                        .visually-hidden{
                            display: none;
                        }
                        .carousel-control-prev-icon{
                            position:absolute;
                            top:40%;
                            left:0%;
                           
                            
                        }
                    }
                    .carousel-control-next{
                        .visually-hidden{
                            display: none;
                        }
                        .carousel-control-next-icon{
                            position:absolute;
                            top:40%;
                            right:0%;
                        }
                    }
                    .carousel-indicators{
                        li{
            
                            height: 10px !important;
                            width: 10px !important;
                            border-radius: 50%!important;
                           
                        }
                    }
                    .title{
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        font-size: 15px;
                        font-weight: 700;
                        margin: 0px 3px;
                        color: #fff;
                        text-align: center;
                    
                    }
                    .left{
                        width: 20%;
                    }
                    .right{
                        width: 20%;
                    }
                    }
            
                    .subtitle{
                        p{
                            color: rgba(255, 255, 255, 0.64);
                            text-align: center;
                            font-size: 11px;
                            font-weight: lighter;
                        }
                    }
                    .carouseltext{
                       
                        margin-top: 0px;
                        
                        .caption{
                         display: block !important;;
                        //  z-index: 3001;
                            // position: absolute;
                            // top: 0%;
                            // left: 100%;
                            // text-align: left;
                            // margin-right: 120px;
                            margin-top: 0px;
            
                            p{
                                font-size: 12px;
                                
                            }
                            h5{
                                font-size: 12px;
                            }
            
                           
                        }
                    }
                    .carouselimage{
                        // float: right;
                        // margin-left: -370px!important;
                       
                        .image{
                            
                            // float: right;
                            // margin-left: -30px;
                            display: inline-flex!important;
                            
                
                           
                            
                            
                        }
                        
                       
                        
                    }
            
            }
            .service7 {
                padding-top: 80px;
                background-color: #fff;
                margin-bottom: -50px;
                min-height: 700px;
                .questions {
                    .header {
                        margin-bottom: 50px;
                        margin-top: -20px;
                        .title {
                            text-align: center;
                            position: relative;
                            margin: 0;
                            font-weight: 600;
                            font-size: 15px;
                            &::before {
                                content: url('../../_assets/icon/left.svg');
                                position: absolute;
                                left: 450px;
                                display: none;
                            }
                            &::after {
                                content: url('../../_assets/icon/right.svg');
                                position: absolute;
                                display: none;
                                right: 100px;
                                width: 1%;
                            }
                        }
                    }
            
                    .faq-wrapper {
                        box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
                        margin-bottom: 20px;
                        border-radius: 7px;
                        width:95%;
                        margin-left: 5px!important;
                        cursor: pointer;
                        .collapse{
                            height: 90px!important;
                            p{
                                font-size: 11px;
                                margin-left: -30px!important;
                                margin-right: -50px!important;
                                margin-bottom: -80px!important;
                            }
                        }
                        .faq-row {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-left: 10px;
                            padding-right: 10px;
                            
                            h5{
                                font-size: 13px;
                            }
                            .indicator-wrapper {
                                .indicator {
                                    width: 50%;
                                }
                            }
            
                            .arrow-wrapper {
                                .arrow {
                                    width: 12px !important;
                                    height: 12px !important;
                                    transform: rotate(0deg); 
                                    transition: transform .3s linear;
                                
                                &.open{
                                    transition: transform .3s linear;
                                    transform: rotate(180deg);
                                        }
                                
                                }
                            }
                        }
                    }
                }
            }
            .service8{
                background-color: #fff;
                position: relative;
                // margin-top: -90px!important;
            
                .title{
            
                    .subtitles{
                        position: relative;
                        .text{
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: -70px;
                            
                                .image3{
                                    
                                    margin-top: 20px;
                                    
                                    width: 35%;
                                }
                                .image4{
                                    width:45%;
                                    margin-top: 20px;
                                    margin-left: 20px;
                                }
                                h3{
                                    color: #3377EF;
                                    font-size: 22px;
                                    font-weight:700;
                                    justify-content: center;
                                    align-items: center;
                                    vertical-align: middle;
                                    margin-top: 100px!important;
                                    margin-bottom: 20px;
                                }
                                p{
                                    color: #333436;
                                    line-height: 27px;
                                    font-size: 14px;
                                    font-weight: 550;
                                }
                                
                            }
                            .image{
                                position: relative;
                                .phones{
                                    position: absolute;
                                    top: 42.5%;
                                    left: -3%;
                                    margin-bottom: -43px!important;
                                }
                            }
            
                            }
                    }
                }
            }

@media (min-width: 425px) { 

    .service{
            background-color: #ffffff;
            min-height: 1180px;
            
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .sub-titles{
                margin-top: 40px;
                margin-left: 05px;
                
        
                .square{
                    
                    border-radius: 27px;
                    padding-top: 5px;
                    padding-bottom: 20px;
                    min-height: 60px!important;
                    padding-right: 20px;
                    border: 0!important;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                    transition: transform .2s;
        
                    
        
        
                    .eligible{
                        width:20%;
                        
                    }
                    h5{
                        font-size: 15px;
                        font-weight: 650;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 13px;
                    }
        
                    &:hover {
                        transform: scale(1.1);
                        border: 1px solid #3377EF!important;
                    }
                }
            }
    }
    .service2{
            background-color: #fffcf2;
            min-height: 600px;
            position: relative;
        
            .sub{
                position: relative;
                // margin-top: 100px;
        
                
                .squares{
                    margin-top: -20px;
                    margin-left: 5px;
        
                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 600;
                        font-size: 20px;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 20px;
                        font-weight: 700;
        
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-top: 15px;
                        z-index: 1001;
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 2px solid #ffc107
                        }
                        
                    }
        
                }
                .square{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 60px;
        
                    .saleswoman{
                        // width:30%;
                        position: relative;
                        
                    }
                    .profit{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position: absolute;
                        top:210px;
                        left:-30px;
                        p{
                            font-size: 10px;
                            color: #1a1a1a;
                            font-weight: 600;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .increase{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width: 35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:210px;
                        left:200px;
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    .customer{
                        background-color: #ffffff;
                        padding: 10px 10px;
                        width:35%;
                        border-radius: 20px;
                        box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                        text-align: center;
                        position:absolute;
                        top:10px;
                        left:200px;
                        
                        p{
                            font-size: 10px;
                            font-weight: 600;
                            color: #1a1a1a;
                        }
                        .percent{
                            width:25%;
                        }
                    }
                    
                }
            }
    }
    .service3{
            background-color: #ffffff;
            min-height: 1350px;
            position: relative;
            margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px!important;
                margin-left: 5px;
                h3{
                    font-size: 15px;
                    font-weight: 700;
                    margin: 15px 9px;
                    color: #1a1a1a;
                    text-align: center;
                
                }
                .left{
                    width: 20%;
                }
                .right{
                    width: 20%;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 11px;
                    font-weight: 600;
                    color:rgba(30, 26, 26, 0.64);
                    margin-top: 0px;
                    margin-left: 40px;
                }
            }
            .sub-titles{
                margin-left: 50px;
                .cards{
                    overflow: hidden;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
        
                border-radius: 10px;
                border: 2px solid #3377ef;
                padding: 12px 30px;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                left: 23%;
                top:93%;
                cursor: pointer;
                margin-top: 20px;
                // margin-bottom: 100px;
                z-index: 1001;
        
                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
    }
    .service4{
            min-height: 580px;
            background-color: #f4f8fe;
            margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                    margin-left: 0px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 20px;
                        line-height: 35px;
                        margin-top: -50px;
                        color: #1a1a1a;
                        margin-left: 0px!important;
                    }
                    p{
                        font-size: 13px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 0px!important;
                        
                    }
                }
                .whoweare{
                    margin-left: 15%;
                    margin-top: 30%;
                    margin-bottom: 20%;
                    width: 130%;
                }
    
            }
    }
    .service5{
            min-height: 500px;
            margin-bottom: -50px;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-bottom: 50px;
            margin-left: 15px;
            width:95%;
            padding: 30px 30px;
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                
        
                .smallbox-img{
                    width: 27%;
                    text-align: center;
                    margin-left: 110px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 20px;
                    
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                padding: 10px !important;
                
                // min-height: 80px;
                
                margin-left: 0px;
                margin-top: 30px;
                
        
        
                .smallbox-img{
                    width: 27%;
                    text-align: center;
                    margin-left: 110px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 20px;
                }
                p{
                    font-size: 10px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
    }
    .service6{
            min-height: 400px;
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
    
            .carousel-indicators{
                margin-bottom: -90px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                    
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                    
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 15px;
                font-weight: 700;
                margin: 0px 3px;
                color: #fff;
                text-align: center;
            
            }
            .left{
                width: 20%;
            }
            .right{
                width: 20%;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 11px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
                
                margin-top: 0px;
                
                .caption{
                    display: block !important;;
                //  z-index: 3001;
                    // position: absolute;
                    // top: 0%;
                    // left: 100%;
                    // text-align: left;
                    // margin-right: 120px;
                    margin-top: 0px;
    
                    p{
                        font-size: 12px;
                        
                    }
                    h5{
                        font-size: 12px;
                    }
    
                    
                }
            }
            .carouselimage{
                // float: right;
                // margin-left: -370px!important;
                
                .image{
                    
                    // float: right;
                    // margin-left: -30px;
                    display: inline-flex!important;
                    
        
                    
                    
                    
                }
                
                
                
            }
    
    }
    .service8{
        background-color: #fff;
        position: relative;
        // margin-top: -90px!important;
    
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    margin-top: -70px;
                    
                        .image3{
                            
                            margin-top: 20px;
                            
                            width: 35%;
                        }
                        .image4{
                            width:45%;
                            margin-top: 20px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 100px!important;
                            margin-bottom: 20px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                        }
                        
                    }
                    .image{
                        position: relative;
                        .phones{
                            position: absolute;
                            top: 34.3%;
                            left: -3%;
                            margin-bottom: -43px!important;
                        }
                    }
    
                    }
            }
    }    
       }

@media (min-width: 508px) { 
    
            .service{
                background-color: #ffffff;
                min-height: 1150px;
                margin-bottom: -50px;
            
                .title{
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        font-size: 17px;
                        font-weight: 700;
                        margin: 5px 9px;
                        color: #1a1a1a;
                        text-align: center;
                    
                    }
                    .left{
                        width: auto;
                    }
                    .right{
                        width: auto;
                    }
                }
                .sub-titles{
                    margin-top: 40px;
                    margin-left: 20px;
                    
            
                    .square{
                       
                        border-radius: 27px;
                        padding-top: 5px;
                        padding-bottom: 20px;
                        min-height: 40px!important;
                        // padding-right: 20px;
                        border: 0!important;
                        box-sizing: border-box;
                        box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                        transition: transform .2s;
                        // margin-left: -20px!important;
            
                        
            
            
                        .eligible{
                            width:20%;
                            
                        }
                        h5{
                            font-size: 19px;
                            font-weight: 650;
                            color: #1a1a1a;
                        }
                        p{
                            font-size: 15px;
                            
                        }
            
                        &:hover {
                            transform: scale(1.03);
                            border: 1px solid #3377EF!important;
                        }
                    }
                }
            }
            .service2{
                background-color: #fffcf2;
                min-height: 600px;
                position: relative;
                margin-bottom: -50px;
            
                .sub{
                    position: relative;
                    // margin-top: 100px;
            
                    
                    
                        
                        h5{
                            color: #1E1A1A;
                            font-weight: 600;
                            font-size: 20px;
                            font-family: 'Sofia Pro', sans-serif;
                            margin-left: 80px;
            
                        }
            
                        h4{
                            color: #1E1A1A;
                            font-size: 24px;
                            font-weight: 700;
                            margin-left: 80px;
            
                        }
            
                        p{
                            color: #7d7975;
                            font-size: 16px;
                            word-spacing: 50%;
                            font-family: 'Sofia Pro', sans-serif;
                            margin-left: 80px;
            
                        }
            
                        .btn4{
                            background-color: #ffc107;
                            border-radius: 7px;
                            border: 2px solid #ffc107;
                            padding: 11px 35px;
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            text-align: right; 
                            border:0px;
                            border-radius: 10px;
                            cursor: pointer;
                            margin-top: 15px;
                            margin-left: 80px;
            
                            &:hover{
                                text-decoration: none;
                                background-color: #fffcf2;
                                color:#ffc107;
                                border: 1px solid #ffc107;
                            }
                            
                        }
            
                    
                    .square{
                        position: relative;
                        margin-top: 30px;
                        margin-left: 60px;
            
                        .saleswoman{
                            // width:30%;
                            position: relative;
                            
                        }
                        .profit{
                            background-color: #ffffff;
                            padding: 10px 10px;
                            width: 35%;
                            border-radius: 20px;
                            box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                            text-align: center;
                            position: absolute;
                            top:210px;
                            left:-30px;
                            p{
                                font-size: 10px;
                                color: #1a1a1a;
                                font-weight: 600;
                            }
                            .percent{
                                width:25%;
                            }
                        }
                        .increase{
                            background-color: #ffffff;
                            padding: 10px 10px;
                            width: 35%;
                            border-radius: 20px;
                            box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                            text-align: center;
                            position:absolute;
                            top:320px;
                            left:280px;
                            p{
                                font-size: 11px;
                                font-weight: 600;
                                color: #1a1a1a;
                            }
                            .percent{
                                width:25%;
                            }
                        }
                        .customer{
                            background-color: #ffffff;
                            padding: 10px 10px;
                            width:35%;
                            border-radius: 20px;
                            box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                            text-align: center;
                            position:absolute;
                            top:10px;
                            left:280px;
                           
                            p{
                                font-size: 11px;
                                font-weight: 600;
                                color: #1a1a1a;
                            }
                            .percent{
                                width:25%;
                            }
                        }
                        
                    }
                }
            }
            .service3{
                background-color: #ffffff;
                min-height: 1600px;
                position: relative;
                margin-bottom: -50px;
            
                .title{
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    h3{
                        font-size: 16px;
                        font-weight: 700;
                        margin: 5px 9px;
                        color: #1a1a1a;
                    }
                }
                .subtitle{
                    p{
                        text-align: center;
                        font-size: 13px;
                        font-weight: 600;
                        color:rgba(30, 26, 26, 0.64);
                        margin-top: 20px;
                        // margin-left: 180px;
                        
                    }
                }
                .sub-titles{
                    margin-left: 80px;
                    .cards{
                        overflow: hidden;
                        border: 1px solid rgba(51, 119, 239, 0.25)!important;
                        box-sizing: border-box;
                        box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                        border-radius: 20px;
                        
                        .title{
                            font-size: 14px;
                            font-weight: 700;
                            text-align: center;
                            color: #1a1a1a;
                        }
                        
                        .text{
                            color: #3377ef;
                            text-align: center;
                            font-weight: bolder;
                            font-size: 20px;
                        }
                        
                        .inner{
                            overflow: hidden;
                        }
                        .image{
                            transition: all 1.5s ease;
                            
                            &:hover{
                                transform: scale(1.3);
                            }
                        }
            
                    }
                }
                .btn5{
            
                    background-color: #fff;
            
                    border-radius: 10px;
                    border: 2px solid #3377ef;
                    padding: 12px 30px;
                    color: #3377ef;
                    font-size: 14px;
                    font-weight: 600;
                    position: absolute;
                    left: 32%;
                    top:87%;
                    cursor: pointer;
                    margin-top: 60px;
                    z-index: 1001;
            
                    &:hover{
                        text-decoration: none;
                        background-color: #3377ef;
                        color: #fff;
                    }
            
                }
            }
            .service4{
                min-height: 580px;
                background-color: #f4f8fe;
                margin-bottom: -50px;
                .sub-titles{
                    width: 90%;
                    .squares{
                        margin-left: 40px;
                        h5{
                            font-size: 20px;
                            color: #1a1a1a;
                        }
                        h4{
                            font-weight: 650;
                            font-size: 25px;
                            line-height: 35px;
                            margin-top: -30px;
                            color: #1a1a1a;
                        }
                        p{
                            font-size: 13px;
                            line-height: 24px;
                            color: rgba(30, 26, 26, 0.64);
                            font-weight: 600;
                            font-style: normal;
                            
                        }
                    }
                    .whoweare{
                        margin-left: -10%!important;
                        margin-top: 30%;
                        margin-bottom: 20%;
                        width: 130%;
                    }
        
                }
            }
            .service5{
                min-height: 500px;
                margin-bottom: -50px;
                
                
                
            
                .bigbox{
                background-color: #fff;
                box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
                border-radius: 12px;
                min-height: 400px;
                margin-top: 50px;
                margin-bottom: 50px;
                margin-left: 15px;
                width:95%;
                padding: 30px 30px;
                
            
                .smallbox3{
                    background-color: #ffedf5;
                    border-radius: 10px;
                    padding-bottom: 40px !important;
                    // min-height: 80px;
                    
                    margin-left: 0px;
                    
            
                    .smallbox-img{
                        width: 22%;
                        text-align: center;
                        margin-left: 140px;
                        margin-top: 20px;
                    }
                    h4{
                        text-align: center;
                        font-weight: 650;
                        font-size: 25px;
                        
                    }
                    p{
                        font-size: 13px;
                        line-height: 20px;
                        color:#777679;
                        font-weight: 600;
                        font-style: normal;
                        text-align: center;
                        margin-top: 20px;
                    }
            
                    }
                
                .smallbox2{
                    background-color: #e2ecfd;
                    border-radius: 10px;
                    min-height: 300px;
                    margin-left: 0px;
                    margin-top: 30px;
                    
            
            
                    .smallbox-img{
                        width: 22%;
                        text-align: center;
                        margin-left: 140px;
                        margin-top: 20px;
                    }
                    h4{
                        text-align: center;
                        font-weight: 650;
                        font-size: 25px;
                    }
                    p{
                        font-size: 13px;
                        line-height: 20px;
                        color:#777679;
                        font-weight: 600;
                        font-style: normal;
                        text-align: center;
                        margin-top: 20px;
                    }
                }
            
                }
            }
            .service6{
                min-height: 500px;
                background-color: #3377EF;
                position: relative;
                margin-bottom: -50px;
        
                .carousel-indicators{
                    margin-bottom: -90px;
                }
                .carousel-control-prev{
                    .visually-hidden{
                        display: none;
                    }
                    .carousel-control-prev-icon{
                        position:absolute;
                        top:40%;
                        left:0%;
                       
                        
                    }
                }
                .carousel-control-next{
                    .visually-hidden{
                        display: none;
                    }
                    .carousel-control-next-icon{
                        position:absolute;
                        top:40%;
                        right:0%;
                    }
                }
                .carousel-indicators{
                    li{
        
                        height: 10px !important;
                        width: 10px !important;
                        border-radius: 50%!important;
                       
                    }
                }
                .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    color:#fff;
                    margin-top: 5px;
                    font-size: 23px;
                    margin-right: 10px;
                    margin-left: 10px;
                  }
                  .left{
                    width: auto;
                }
                .right{
                    width: auto;
                }
                }
        
                .subtitle{
                    p{
                        color: rgba(255, 255, 255, 0.64);
                        text-align: center;
                        font-size: 11px;
                        font-weight: lighter;
                    }
                }
                .carouseltext{
                   
                    margin-top: 0px;
                    
                    .caption{
                     display: block !important;;
                    //  z-index: 3001;
                        // position: absolute;
                        // top: 0%;
                        // left: 100%;
                        // text-align: left;
                        // margin-right: 120px;
                        margin-top:10px;
        
                        p{
                            font-size: 13px;
                            
                        }
                        h5{
                            font-size: 13px;
                        }
        
                       
                    }
                }
                .carouselimage{
                    // float: right;
                    // margin-left: -370px!important;
                   
                    .image{
                        
                        // float: right;
                        // margin-left: -30px;
                        display: inline-flex!important;
                        
            
                       
                        
                        
                    }
                    
                   
                    
                }
        
        }
       
        .service8{
            background-color: #fff;
            position: relative;
        
            .title{
        
                .subtitles{
                    position: relative;
                    .text{
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        
                            .image3{
                                
                                margin-top: 20px;
                                
                                width: 25%;
                            }
                            .image4{
                                width:30%;
                                margin-top: 20px;
                                margin-left: 20px;
                            }
                            h3{
                                color: #3377EF;
                                font-size: 22px;
                                font-weight:700;
                                justify-content: center;
                                align-items: center;
                                vertical-align: middle;
                                margin-top: 160px!important;
                                margin-bottom: 20px;
                            }
                            p{
                                color: #333436;
                                line-height: 27px;
                                font-size: 14px;
                                font-weight: 550;
                            }
                            
                        }
                        .image{
                            position: relative;
                            .phones{
                                position: absolute;
                                top: 27%;
                                margin-bottom: -43px!important;
                            }
                        }
        
                        }
                }
            }
         }    
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    
.service{
        background-color: #ffffff;
        min-height: 1260px;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 17px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: 100px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 40px!important;
                // padding-right: 20px;
                border: 0!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
                margin-left: -20px!important;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                    
                }
    
                &:hover {
                    transform: scale(1.03);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
        margin-bottom: -50px;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            .squares{
                margin-top: -20px;
                margin-left: 40px;
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 24px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 24px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 16px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 2px solid #ffc107;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 1px solid #ffc107;
                    }
                    
                }
    
            }
            .square{
                position: relative;
                margin-top: 30px;
                margin-left: 70px;
    
                .saleswoman{
                    // width:30%;
                    position: relative;
                    
                }
                .profit{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position: absolute;
                    top:210px;
                    left:-50px;
                    p{
                        font-size: 10px;
                        color: #1a1a1a;
                        font-weight: 600;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .increase{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width: 35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:320px;
                    left:300px;
                    p{
                        font-size: 11px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                .customer{
                    background-color: #ffffff;
                    padding: 10px 10px;
                    width:35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:10px;
                    left:310px;
                   
                    p{
                        font-size: 11px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                    .percent{
                        width:25%;
                    }
                }
                
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 1600px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            h3{
                font-size: 16px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 20px;
                // margin-left: 180px;
                
            }
        }
        .sub-titles{
            margin-left: 80px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 32%;
            top:91%;
            cursor: pointer;
            margin-top: 60px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 90%;
            .squares{
                margin-left: 90px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                }
                h4{
                    font-weight: 650;
                    font-size: 25px;
                    line-height: 35px;
                    margin-top: -30px;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    
                }
            }
            .whoweare{
                margin-left: 15%;
                margin-top: 30%;
                margin-bottom: 20%;
                width: 130%;
            }

        }
    }
.service5{
        min-height: 500px;
        margin-bottom: -50px;
        
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 15px;
        width:95%;
        padding: 30px 30px;
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            padding-bottom: 40px !important;
            // min-height: 80px;
            
            margin-left: 0px;
            
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 140px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
                
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            min-height: 300px;
            margin-left: 0px;
            margin-top: 30px;
            
    
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 140px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 500px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -90px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .left{
            width: auto;
        }
        .right{
            width: auto;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 11px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                // margin-right: 120px;
                margin-top:10px;

                p{
                    font-size: 13px;
                    
                }
                h5{
                    font-size: 13px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 26.3%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .main{
        .navbar-dark .navbar-nav{
            color:#000;
            // margin-left: 50px;
            margin-top: -10px;
            text-align: left;
            font-size: 14px;
            // transform: translate(40%,0%);
        
        
        .nav-link {
            color: #000;
            margin-left: 5px;
            margin-top: 5px;
            &:hover{
                color:#3377EF;
            }
        }
        
        .btn1{
            background-color: #fee706;
            border-radius: 10px;
            border: 0px;
            padding: 10px 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            text-align: right;
            // position: absolute;
            // left:75%;
            cursor: pointer;
            // margin-left: 40px;
            z-index: 1001;
    
            &:hover{
                background-color:#ffea00;
                color: #fff;
            }
            
        }
        }
        .brand{
            width: 40%!important;
            margin-top: -5px!important;
            margin-left: 5px!important;
        }
        .navbar-toggler-icon {
            display: inline-block;
            width: 1.5em!important;
            height: 1.5em!important;
            vertical-align: middle;
            content: "";
            // background: 50% / 100% 100% no-repeat;
            background-color:#3377ef;
            border: 0px;
            color: #fff;
            border-radius: 8px;
            margin-top: 10px;
        }
        .bold-text{
            h2{
                font-size: 35px;
                // margin-top: -30px;
                
            }
            
        }
        .light-text{
            p{
                font-size:15px;
                // margin-top: -60px;
                // width: 50%;
            }
        }
        .image{
            margin-top: 29px;
        }
        .image2{
            margin-top: 113px;
        }
        // .image3 {
        //     width: 14%;
        // }
        // .image4 {
        //     width: 16%;
        // }
        }
.service{
        background-color: #ffffff;
        min-height: 620px;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            h3{
                font-size: 17px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: 60px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                padding-right: 20px;
                border: 0!important;
                margin-right: 50px!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                }
    
                &:hover {
                    transform: scale(1.1);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
        margin-bottom: -50px;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            
                
                h5{
                    color: #1E1A1A;
                    font-weight: 500;
                    font-size: 21px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 20px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 20px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
                .saleswoman{
                    margin-top: 100px!important;
                    position: relative;
}
            
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 1580px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 50px;
            margin-top: 20px;
            h3{
                font-size: 18px;
                font-weight: 700;
                color: #1a1a1a;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 17px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 20px;
                // margin-left: 310px;
            }
        }
        .sub-titles{
            margin-left: 180px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                    margin-left: -5px;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 40%;
            top:90%;
            cursor: pointer;
            margin-top: 80px;
           margin-left: -50px;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 90%;
            .squares{
                margin-left: 30px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    // font-weight: 600;
                }
                h4{
                    font-weight: 650;
                    font-size: 25px;
                    line-height: 35px;
                    margin-top: -30px;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    
                }
            }
            .whoweare{
                margin-left: -5%!important;
                margin-top: 10%;
                
            }

        }
    }
.service5{
        min-height: 650px;
        margin-bottom: -50px;
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 15px;
        width:95%;
        padding: 30px 30px;
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            padding-bottom: 40px !important;
            // min-height: 80px;
            
            margin-left: 0px;
            
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 200px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 30px;
                
            }
            p{
                font-size: 17px;
                line-height: 20px;
                color:#777679;
                font-weight: 500;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            min-height: 300px;
            margin-left: 0px;
            margin-top: 30px;
            
    
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 200px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 30px;
            }
            p{
                font-size: 17px;
                line-height: 20px;
                color:#777679;
                font-weight: 500;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 600px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .carousel-indicators{
            margin-bottom: -50px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .left{
            width: auto;
        }
        .right{
            width: auto;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 14px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                margin-right: 250px;
                margin-top:60px;

                p{
                    font-size: 14px;
                    
                }
                h5{
                    font-size: 14px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           margin-top: 0;
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 700px;
    .questions {
        .header {
            margin-bottom: 50px;
            margin-top: -20px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 20px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 90px;
                    display: inline-block;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    display: inline-block;
                    right: 200px;
                    width: 1%;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 5px!important;
            cursor: pointer;
            .collapse{
                height: 100px!important;
                p{
                    font-size: 15px;
                    margin-left: -30px!important;
                    margin-right: -50px!important;
                    margin-bottom: -80px!important;
                }
            }
            .faq-row {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
                
                h5{
                    font-size: 17px;
                }
                .indicator-wrapper {
                    .indicator {
                        width: 50%;
                    }
                }

                .arrow-wrapper {
                    .arrow {
                        width: 12px !important;
                        height: 12px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}
.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: 6.5%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
.service9{
        background-color: #3377EF;
        // min-height: 400px;
        width:100%;
        overflow: hidden;
        .footer{
            display: flex;
            flex-direction: row;
            width:100%;
            .company {
                
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                }
                p{
                    font-size: 12px;
                    font-weight: lighter;
                    color: #fff;
                }
            }
            .content {
                
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                }
                ul{
                    list-style-type: none;
                    // text-align: right;
                    
                    li{
                        // text-align: left;
                        font-size: 12px;
                        font-weight: lighter;
                        color: #fff;
                    }
                }
            }
    
            
        }
        .social{
            display: flex;
            justify-content: center;
            text-align:center;
            margin-left: 40px;
        .dash{
            width: 5%;
        }
        .tweet{
            width: 4%;
        }
        .insta{
            width: 4%;
        }
        .link{
            width: 4%;
        }
        .face{
            width: 4%;
        }
        }
        .copyright{
            display: flex;
            text-align: center;
            justify-content: center;
            margin-left: 80px;
            color: #fff;
            font-size: 12px;
        }
    }

 }

 
 // Large devices (desktops, 992px and up)
 
@media (min-width: 992px) {
    
    .service{
        background-color: #ffffff;
        min-height: 600px;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            h3{
                font-size: 17px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: 50px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                margin-right: 60px!important;
                padding-right: 20px;
                border: 0.5px solid rgba(51, 119, 239, 0.43)!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                }
    
                &:hover {
                    transform: scale(1.1);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 600px;
        position: relative;
        margin-bottom: -50px;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            .squares{
                margin-top: 0px;
                margin-left: 175px;
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 600;
                    font-size: 16px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 18px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 13px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
            }
            .square{
                position: relative;
                margin-top: 30px;
                margin-left: 80px;
    
                .saleswoman{
                    width:150%;
                    // margin-left: -350px;
                    position: relative;
                    
                }
                .profit{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width: 40%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position: absolute;
                    top:240px;
                    left:-60px;
                    p{
                        font-size: 11px;
                        color: #1a1a1a;
                        font-weight: 600;
                    }
                }
                .increase{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width: 40%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:360px;
                    left:330px;
                    p{
                        font-size: 11px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                }
                .customer{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width:35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:10px;
                    left:360px;
                   
                    p{
                        font-size: 11px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                }
                
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 850px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            margin-left: 50px;
            h3{
                font-size: 18px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .subtitle{
            // margin-left: 180px;
            p{
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 20px;
                margin-left: 100px;
                width: 80%;
            }
        }
        .sub-titles{
            margin-left: 180px;
            .cards{
                overflow: hidden;
                margin-right: 15px;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                    margin-left: -5px;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 42%;
            top:75%;
            cursor: pointer;
            margin-top: 120px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        margin-bottom: -50px;
        background-color: #f4f8fe;
        .sub-titles{
            width: 90%;
            margin-left: -20px;
            .squares{
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                }
                h4{
                    font-weight: 650;
                    font-size: 25px;
                    line-height: 35px;
                    margin-top: -30px;
                    color: #1a1a1a;
                }
                p{
                    font-size: 13px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    
                }
            }
            .whoweare{
                margin-left: 25%;
                margin-top: 55%;
            }

        }
    }
.service5{
        min-height: 500px;
        background-color: #fff;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-left: 30px;
        width:95%;
        
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            // min-height: 80px;
            
            margin-left: 35px;
            
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 110px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
                
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            margin-left: 50px;
            margin-bottom: 15px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            
    
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 110px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 700px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;

        .quote1{
            position: absolute;
            left: 8%;
            top: 22%;
        }
        .quote2{
        position: absolute;
        left:40%;
        top:65%;
        
        }
        .carousel-indicators{
            margin-bottom: -50px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .left{
            width: auto;
        }
        .right{
            width: auto;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 15px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                margin-right: 220px;
                margin-top:100px;

                p{
                    font-size: 19px;
                    
                }
                h5{
                    font-size: 19px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;   
            }   
        }
}
.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position:relative;
                    .phones{
                        position:absolute;
                        top: -80px;
                        margin-bottom: -13px!important;
                    }
                }

                }
        }
    }
}

@media (min-width:1024px) { 

.service{
        background-color: #ffffff;
        min-height: 620px;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            h3{
                font-size: 17px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .sub-titles{
            margin-top: 40px;
            margin-left: 60px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 5px;
                padding-bottom: 20px;
                min-height: 60px!important;
                padding-right: 20px;
                border: 0!important;
                margin-right: 50px!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:20%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                }
    
                // &:hover {
                //     transform: scale(1.1);
                //     border: 1px solid #3377EF!important;
                // }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 500px;
        position: relative;
        margin-bottom: -50px;
    
        .sub{
            position: relative;
            // margin-top: 100px;
    
            
            .squares{
                margin-top: 20px;
                margin-left: 105px;
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 500;
                    font-size: 28px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 30px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 20px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                    margin-top: 15px;
                    z-index: 1001;
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 2px solid #ffc107
                    }
                    
                }
    
            }
            .square{
                position: relative;
                margin-top: 150px;
                margin-left: 100px;
    
                .saleswoman{
                    width:130%;

                    position: relative;
                    
                }
                .profit{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width: 40%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position: absolute;
                    top:330px;
                    left:-60px;
                    p{
                        font-size: 13px;
                        color: #1a1a1a;
                        font-weight: 600;
                    }
                }
                .increase{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width: 40%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:330px;
                    left:300px;
                    p{
                        font-size: 13px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                }
                .customer{
                    background-color: #ffffff;
                    padding: 20px 10px;
                    width:35%;
                    border-radius: 20px;
                    box-shadow: 0px 13px 39px -3px rgba(51, 119, 239, 0.2);
                    text-align: center;
                    position:absolute;
                    top:10px;
                    left:300px;
                   
                    p{
                        font-size: 13px;
                        font-weight: 600;
                        color: #1a1a1a;
                    }
                }
                
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 850px;
        position: relative;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 50px;
            margin-top: 20px;
            h3{
                font-size: 18px;
                font-weight: 700;
                color: #1a1a1a;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 20px;
                // margin-left: 310px;
            }
        }
        .sub-titles{
            margin-left: 180px;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                    margin-left: -5px;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            left: 40%;
            top:80%;
            cursor: pointer;
            margin-top: 80px;
            margin-left: -10px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        min-height: 580px;
        background-color: #f4f8fe;
        margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                    // font-weight: 600;
                }
                h4{
                    font-weight: 650;
                    font-size: 25px;
                    line-height: 35px;
                    margin-top: -30px;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    
                }
            }
            .whoweare{
                margin-left: 15%;
                margin-top: 30%!important;
                
            }

        }
    }
.service5{
        min-height: 500px;
        background-color: #fff;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-left: 30px;
        width:95%;
        
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            // min-height: 80px;
            
            margin-left: 35px;
            
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 110px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
                
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            margin-left: 50px;
            margin-bottom: 15px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            
    
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 110px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 700px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;


      
        .carousel-indicators{
            margin-bottom: -50px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .left{
            width: auto;
        }
        .right{
            width: auto;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 16px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 40px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                margin-right: 170px;
                margin-top:60px;

                p{
                    font-size: 14px;
                    
                }
                h5{
                    font-size: 14px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 750px;
    .questions {
        .header {
            margin-bottom: 50px;
            margin-top: -20px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 20px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 200px;
                    display: inline-block;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    display: inline-block;
                    right: 310px;
                    width: 1%;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 5px!important;
            cursor: pointer;
            .collapse{
                height: 100px!important;
                p{
                    font-size: 15px;
                    margin-left: -30px!important;
                    margin-right: -50px!important;
                    margin-bottom: -80px!important;
                }
            }
            .faq-row {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
                
                h5{
                    font-size: 17px;
                }
                .indicator-wrapper {
                    .indicator {
                        width: 50%;
                    }
                }

                .arrow-wrapper {
                    .arrow {
                        width: 12px !important;
                        height: 12px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}
.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position: relative;
                    .phones{
                        position: absolute;
                        top: -31%;
                        margin-bottom: -43px!important;
                    }
                }

                }
        }
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
.service{
        background-color: #ffffff;
        min-height: 550px;
        margin-bottom: -50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 17px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .sub-titles{
            margin-top: 40px;
            
    
            .square{
               
                border-radius: 27px;
                padding-top: 10px;
                padding-bottom: 30px;
                padding-left: 30px;
                border: 0!important;
                margin-right: 60px!important;
                box-sizing: border-box;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:15%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                }
    
                &:hover {
                    transform: scale(1.16);
                    border: 1px solid #3377EF!important;
                }
            }
        }
    }
.service2{
        background-color: #fffcf2;
        min-height: 550px;
        .sub{
          
    
                
                h5{
                    color: #1E1A1A;
                    font-weight: 500;
                    font-size: 20px;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                h4{
                    color: #1E1A1A;
                    font-size: 22px;
                    font-weight: 700;
    
                }
    
                p{
                    color: #7d7975;
                    font-size: 16px;
                    word-spacing: 50%;
                    font-family: 'Sofia Pro', sans-serif;
    
                }
    
                .btn4{
                    background-color: #ffc107;
                    border-radius: 7px;
                    border: 0px;
                    padding: 11px 35px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: right; 
                    border:0px;
                    border-radius: 10px;
                    cursor: pointer;
                   
                    
    
                    &:hover{
                        text-decoration: none;
                        background-color: #fffcf2;
                        color:#ffc107;
                        border: 1px solid #ffc107
                    }
            }
            
                .saleswoman{
                    margin-top: 0px!important;
                    width: 170% !important; 
                   
            }
        }
    }
.service3{
        background-color: #ffffff;
        min-height: 650px;
        position: relative;
        margin-bottom: -50px;
        margin-top: 50px;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0px;
            h3{
                font-size: 18px;
                font-weight: 700;
                margin: 5px 9px;
                color: #1a1a1a;
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        .subtitle{
            p{
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color:rgba(30, 26, 26, 0.64);
                margin-top: 20px;
                margin-left: 0px;
                width: 80%;
            }
        }
        .sub-titles{
            margin-left: 0px;
            margin-top: 50px!important;
            .cards{
                overflow: hidden;
                border: 1px solid rgba(51, 119, 239, 0.25)!important;
                box-sizing: border-box;
                box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                border-radius: 20px;
                
                .title{
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: #1a1a1a;
                    margin-left: -5px;
                }
                
                .text{
                    color: #3377ef;
                    text-align: center;
                    font-weight: bolder;
                    font-size: 20px;
                }
                
                .inner{
                    overflow: hidden;
                }
                .image{
                    transition: all 1.5s ease;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
    
            }
        }
        .btn5{
    
            background-color: #fff;
    
            border-radius: 10px;
            border: 2px solid #3377ef;
            padding: 12px 30px;
            color: #3377ef;
            font-size: 14px;
            font-weight: 600;
            
            margin-left: 110px!important;
            cursor: pointer;
            margin-top: 120px;
            z-index: 1001;
    
            &:hover{
                text-decoration: none;
                background-color: #3377ef;
                color: #fff;
            }
    
        }
    }
.service4{
        // min-height: 480px;
        background-color: #f4f8fe;
        // margin-bottom: -50px;
        .sub-titles{
            width: 100%;
            .squares{
                margin-left: 80px;
                h5{
                    font-size: 20px;
                    color: #1a1a1a;
                }
                h4{
                    font-weight: 650;
                    font-size: 25px;
                    line-height: 35px;
                    margin-top: -30px;
                    color: #1a1a1a;
                }
                p{
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(30, 26, 26, 0.64);
                    font-weight: 600;
                    font-style: normal;
                    
                    
                }
            }
            .whoweare{
                margin-left: 0%!important;
                margin-top: 3%!important;
                height: 500px;
                float: left;
            }

        }
    }
.service5{
        min-height: 500px;
        background-color: #fff;
        
        
    
        .bigbox{
        background-color: #fff;
        box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
        border-radius: 12px;
        min-height: 400px;
        margin-top: 50px;
        margin-left: 30px;
        width:95%;
        
        
    
        .smallbox3{
            background-color: #ffedf5;
            border-radius: 10px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            // min-height: 80px;
            
            margin-left: 50px;
            
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 145px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
                
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
    
            }
        
        .smallbox2{
            background-color: #e2ecfd;
            border-radius: 10px;
            margin-left: 50px;
            margin-bottom: 15px;
            margin-top: 10px;
            // padding-bottom: 40px !important;
            
    
    
            .smallbox-img{
                width: 22%;
                text-align: center;
                margin-left: 145px;
                margin-top: 20px;
            }
            h4{
                text-align: center;
                font-weight: 650;
                font-size: 25px;
            }
            p{
                font-size: 13px;
                line-height: 20px;
                color:#777679;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                margin-top: 20px;
            }
        }
    
        }
    }
.service6{
        min-height: 750px;
        background-color: #3377EF;
        position: relative;
        margin-bottom: -50px;


        .quote1{
            position: absolute;
            left: 8%;
            top: 25%;
        }
        .quote2{
        position: absolute;
        left:40%;
        top:65%;
        
        }
        .carousel-indicators{
            margin-bottom: -50px;
        }
        .carousel-control-prev{
            .visually-hidden{
                display: none;
            }
            .carousel-control-prev-icon{
                position:absolute;
                top:40%;
                left:0%;
               
                
            }
        }
        .carousel-control-next{
            .visually-hidden{
                display: none;
            }
            .carousel-control-next-icon{
                position:absolute;
                top:40%;
                right:0%;
            }
        }
        .carousel-indicators{
            li{

                height: 10px !important;
                width: 10px !important;
                border-radius: 50%!important;
               
            }
        }
        .title{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color:#fff;
            margin-top: 5px;
            font-size: 23px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .left{
            width: auto;
        }
        .right{
            width: auto;
        }
        }

        .subtitle{
            p{
                color: rgba(255, 255, 255, 0.64);
                text-align: center;
                font-size: 17px;
                font-weight: lighter;
            }
        }
        .carouseltext{
           
            margin-top: 0px;
            
            .caption{
             display: block !important;;
            //  z-index: 3001;
                // position: absolute;
                // top: 0%;
                // left: 100%;
                // text-align: left;
                margin-right: 280px;
                margin-top:120px;

                p{
                    font-size: 19px;
                    
                }
                h5{
                    font-size: 19px;
                }

               
            }
        }
        .carouselimage{
            // float: right;
            width: 45%!important;
            margin-top: 50px!important;
            // margin-left: -370px!important;
           
            .image{
                
                // float: right;
                // margin-left: -30px;
                display: inline-flex!important;
                
    
               
                
                
            }
            
           
            
        }

}
.service7 {
    padding-top: 80px;
    background-color: #fff;
    margin-bottom: -50px;
    min-height: 1200px;
    .questions {
        .header {
            margin-bottom: 70px;
            .title {
                text-align: center;
                position: relative;
                margin: 0;
                font-weight: 600;
                font-size: 25px;
                &::before {
                    content: url('../../_assets/icon/left.svg');
                    position: absolute;
                    left: 270px;
                }
                &::after {
                    content: url('../../_assets/icon/right.svg');
                    position: absolute;
                    right: 370px;
                }
            }
        }

        .faq-wrapper {
            box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
            margin-bottom: 20px;
            border-radius: 7px;
            width:95%;
            margin-left: 10px;
            cursor: pointer;
            
            .collapse{
               
                p{
                   margin-right: 30px!important;
                    margin-left: 55px!important;
                   
                }
            }
            .faq-row {
                padding-top: 36px;
                padding-bottom: 36px;
                padding-left: 45px;
                padding-right: 45px;
               

                .indicator-wrapper {
                    .indicator {
                        width: 40%!important;
                    }
                }

                .arrow-wrapper {
                    .arrow {
                        width: 22px !important;
                        height: 22px !important;
                        transform: rotate(0deg); 
                        transition: transform .3s linear;
                    
                    &.open{
                        transition: transform .3s linear;
                        transform: rotate(180deg);
                            }
                    
                    }
                }
            }
        }
    }
}

.service8{
    background-color: #fff;
    position: relative;

    .title{

        .subtitles{
            position: relative;
            .text{
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                
                    .image3{
                        
                        margin-top: 20px;
                        
                        width: 25%;
                    }
                    .image4{
                        width:30%;
                        margin-top: 20px;
                        margin-left: 20px;
                    }
                    h3{
                        color: #3377EF;
                        font-size: 22px;
                        font-weight:700;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        margin-top: 160px!important;
                        margin-bottom: 20px;
                    }
                    p{
                        color: #333436;
                        line-height: 27px;
                        font-size: 14px;
                        font-weight: 550;
                    }
                    
                }
                .image{
                    position:relative;
                    .phones{
                        position:absolute;
                        top: -131px;
                        // margin-bottom: -13px!important;
                    }
                }

                }
        }
    }
.service9{
        background-color: #3377EF;
        // min-height: 400px;
        width:100%;
        overflow: hidden;
        .footer{
            display: flex;
            flex-direction: row;
            width:100%;
            .company{
                
            }
            .contact{
            }
            .content{
            }
            ul{
                list-style-type: none;
                
                li{
                    color: #fff;
                    font-size: 12px;
                    width: max-content;
                    margin-left: -32px;
                    margin-top: 10px;
                }
            }
    
            h3{
                font-size: 18px;
                color: #fff;
            }
            p{
                font-size: 12px;
                font-weight: lighter;
                color: #fff;
            }
        }
        .social{
            display: flex;
            justify-content: center;
            text-align:center;
            margin-left: 70px;
        .dash{
            width: 5%;
        }
        .tweet{
            width: 2%;
        }
        .insta{
            width: 2%;
        }
        .link{
            width: 2%;
        }
        .face{
            width: 2%;
        }
        }
        .copyright{
            display: flex;
            text-align: center;
            justify-content: center;
            margin-left: 80px;
            color: #fff;
            font-size: 15px;
        }
    }

 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 

    .service{
        background-color: #ffffff;
    
        .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            
            h3{
                font-size: 19px;
                font-weight: 700;
                // margin: 5px 9px;
                color: #1a1a1a;
                text-align: center;
            
            }
            .left{
                width: auto;
            }
            .right{
                width: auto;
            }
        }
        
            // margin-top: 40px;
            
            
            
    
            .square{
                
                border-radius: 27px;
                // padding-top: 5px;
                // padding-bottom: 20px;
                min-height: 60px!important;
                // padding-right: 20px;
                border: 0!important;
                box-sizing: border-box;
                // margin-right: 50px!important;
                box-shadow: 0px 20px 45px rgba(51, 119, 239, 0.08);
                transition: transform .2s;
    
                
    
    
                .eligible{
                    width:18%;
                    
                }
                h5{
                    font-size: 19px;
                    font-weight: 650;
                    color: #1a1a1a;
                }
                p{
                    font-size: 15px;
                }
    
                &:hover {
                    transform: scale(1.16);
                    border: 1px solid #3377EF!important;
                }
            }
        
    }
    .service2{
            background-color: #fffcf2;
            min-height: 550px;
            .sub{
                
        
                    
                    h5{
                        color: #1E1A1A;
                        font-weight: 500;
                        font-size: 20px;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    h4{
                        color: #1E1A1A;
                        font-size: 22px;
                        font-weight: 700;
        
                    }
        
                    p{
                        color: #7d7975;
                        font-size: 16px;
                        word-spacing: 50%;
                        font-family: 'Sofia Pro', sans-serif;
        
                    }
        
                    .btn4{
                        background-color: #ffc107;
                        border-radius: 7px;
                        border: 0px;
                        padding: 11px 35px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: right; 
                        border:0px;
                        border-radius: 10px;
                        cursor: pointer;
                        
                        
        
                        &:hover{
                            text-decoration: none;
                            background-color: #fffcf2;
                            color:#ffc107;
                            border: 1px solid #ffc107
                        }
                }
                
                    .saleswoman{
                        margin-top: 0px!important;
                        width: 170% !important; 
                        
                }
            }
    }
    .service3{
            background-color: #ffffff;
            // min-height: 750px;
            // position: relative;
            // margin-bottom: -50px;
        
            .title{
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                margin-top: 10px;
                h3{
                    font-size: 19px;
                    font-weight: 700;
                    margin: 5px 9px;
                    color: #1a1a1a;
                }
                .left{
                    width: auto;
                }
                .right{
                    width: auto;
                }
            }
            .subtitle{
                p{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    width:80%;
                    color:rgba(30, 26, 26, 0.64);
                    
                }
            }
            .sub-titles{
                
                .cards{
                    overflow: hidden;
                    min-height: 240px!important;
                    border: 1px solid rgba(51, 119, 239, 0.25)!important;
                    box-sizing: border-box;
                    box-shadow: 0px 17px 25px rgba(51, 119, 239, 0.10);
                    border-radius: 20px;
                    
                    .title{
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        color: #1a1a1a;
                    }
                    
                    .text{
                        color: #3377ef;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 20px;
                    }
                    
                    .inner{
                        overflow: hidden;
                    }
                    .image{
                        transition: all 1.5s ease;
                        
                        &:hover{
                            transform: scale(1.3);
                        }
                    }
        
                }
            }
            .btn5{
        
                background-color: #fff;
                border-radius: 10px;
                border: .3px solid #3377ef;
                color: #3377ef;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;

                &:hover{
                    text-decoration: none;
                    background-color: #3377ef;
                    color: #fff;
                }
        
            }
    }
    .service4{
            // min-height: 480px;
            background-color: #f4f8fe;
            // margin-bottom: -50px;
            .sub-titles{
                width: 100%;
                .squares{
                    margin-left: 80px;
                    h5{
                        font-size: 20px;
                        color: #1a1a1a;
                    }
                    h4{
                        font-weight: 650;
                        font-size: 25px;
                        line-height: 35px;
                        margin-top: -30px;
                        color: #1a1a1a;
                    }
                    p{
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(30, 26, 26, 0.64);
                        font-weight: 600;
                        font-style: normal;
                        
                        
                    }
                }
                .whoweare{
                    margin-left: 0%!important;
                    margin-top: 3%!important;
                    height: 500px;
                    float: left;
                }

            }
    }
    .service5{
            min-height: 500px;
            background-color: #fff;
            
            
        
            .bigbox{
            background-color: #fff;
            box-shadow: 0px 13px 47px rgba(51, 119, 239, 0.15);
            border-radius: 12px;
            min-height: 400px;
            margin-top: 50px;
            margin-left: 30px;
            width:95%;
            
            
        
            .smallbox3{
                background-color: #ffedf5;
                border-radius: 10px;
                margin-top: 10px;
                margin-bottom: 0px;
                // padding-bottom: 40px !important;
                min-height: 80px;
                margin-left: 50px;
                
        
                .smallbox-img{
                    width: 22%;
                    text-align: center;
                    margin-left: 145px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 25px;
                    
                }
                p{
                    font-size: 13px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
        
                }
            
            .smallbox2{
                background-color: #e2ecfd;
                border-radius: 10px;
                margin-left: 50px;
                margin-bottom: 0px;
                margin-top: 10px;
                // min-height: 280px;
                // padding-bottom: 40px !important;
                
        
        
                .smallbox-img{
                    width: 22%;
                    text-align: center;
                    margin-left: 145px;
                    margin-top: 20px;
                }
                h4{
                    text-align: center;
                    font-weight: 650;
                    font-size: 25px;
                }
                p{
                    font-size: 13px;
                    line-height: 20px;
                    color:#777679;
                    font-weight: 600;
                    font-style: normal;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        
            }
    }
    .service6{
        
            background-color: #3377EF;
            position: relative;
            margin-bottom: -50px;
            // padding-top: 10px;
    
            .carousel-indicators{
                margin-bottom: 0px;
            }
            .carousel-control-prev{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-prev-icon{
                    position:absolute;
                    top:40%;
                    left:0%;
                    
                    
                }
            }
            .carousel-control-next{
                .visually-hidden{
                    display: none;
                }
                .carousel-control-next-icon{
                    position:absolute;
                    top:40%;
                    right:0%;
                }
            }
            .carousel-indicators{
                li{
    
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 50%!important;
                    
                }
            }
            .title{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                color:#fff;
                margin-top: 5px;
                font-size: 23px;
                margin-right: 10px;
                margin-left: 10px;
                }
                .left{
                width: auto;
            }
            .right{
                width: auto;
            }
            }
    
            .subtitle{
                p{
                    color: rgba(255, 255, 255, 0.64);
                    text-align: center;
                    font-size: 17px;
                    font-weight: lighter;
                }
            }
            .carouseltext{
                
                margin-top: 0px;
                
                .caption{
                display: block !important;
                margin-right: 280px;
                margin-top:120px;
    
                    p{
                        font-size: 19px;
                        
                    }
                    h5{
                        font-size: 19px;
                    }
                    
                }
            }
            .carouselimage{
                width: 45%!important;
                margin-top: 50px!important;
                
                .image{
                    display: inline-flex!important;    
                }    
            }
    }
    .service7 {
        padding-top: 80px;
        background-color: #fff;
        margin-bottom: -50px;
        min-height: 1100px;
        .questions {
            .header {
                margin-bottom: 70px;
                .title {
                    text-align: center;
                    position: relative;
                    margin: 0;
                    font-weight: 600;
                    font-size: 25px;
                    &::before {
                        content: url('../../_assets/icon/left.svg');
                        position: absolute;
                        left: 270px;
                    }
                    &::after {
                        content: url('../../_assets/icon/right.svg');
                        position: absolute;
                        right: 370px;
                    }
                }
            }
    
            .faq-wrapper {
                box-shadow: 2px 1px 5px 1px rgba(196, 196, 196, 0.2);
                margin-bottom: 20px;
                border-radius: 7px;
                width:95%;
                margin-left: 10px;
                cursor: pointer;
                
                .collapse{
                    
                    p{
                        margin-right: 30px!important;
                        margin-left: 55px!important;
                        
                    }
                }
                .faq-row {
                    padding-top: 36px;
                    padding-bottom: 36px;
                    padding-left: 45px;
                    padding-right: 45px;
                    
    
                    .indicator-wrapper {
                        .indicator {
                            width: 40%!important;
                        }
                    }
    
                    .arrow-wrapper {
                        .arrow {
                            width: 22px !important;
                            height: 22px !important;
                            transform: rotate(0deg); 
                            transition: transform .3s linear;
                        
                        &.open{
                            transition: transform .3s linear;
                            transform: rotate(180deg);
                                }
                        
                        }
                    }
                }
            }
        }
    }
    .service8{
        background-color: #fff;
        position: relative;
        min-height: 400px;
        .title{
    
            .subtitles{
                position: relative;
                .text{
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    
                        .image3{
                            
                            margin-top: -100px;
                            
                            width: 25%;
                        }
                        .image4{
                            width:30%;
                            margin-top: -100px;
                            margin-left: 20px;
                        }
                        h3{
                            color: #3377EF;
                            font-size: 22px;
                            font-weight:700;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 160px!important;
                            margin-bottom: 90px;
                        }
                        p{
                            color: #333436;
                            line-height: 27px;
                            font-size: 14px;
                            font-weight: 550;
                            margin-top: -70px!important;
                            margin-bottom: 90px;

                        }
                        
                    }
                    .image{
                        position:relative;
                        .phones{
                            position:absolute;
                            top: 4px;
                            // margin-bottom: -13px!important;
                        }
                    }
    
                    }
            }
    }
    .service9{
        background-color: #3377EF;
        // min-height: 450px;
        width:100%;
        overflow: hidden;
        .footer{
            display: flex;
            flex-direction: row;
            width:100%;
            .company{
                font-size: 16px!important;
            }
            .contact{
            }
            .content{
                
            }
            ul{
                list-style-type: none;
                // text-align: right;
                
                li{
                    text-align: left;
                    color: #fff;
                    font-size: 12px;
                    // margin-left: -25px;
                    text-align: left;
                    margin-top: -2px;
                }
            }
    
            h3{
                font-size: 18px;
                color: #fff;
            }
            p{
                font-size: 12px;
                font-weight: lighter;
                color: #fff;
            }
        }
        .social{
            display: flex;
            justify-content: center;
            text-align:center;
            margin-left: 70px;
        .dash{
            width: 5%;
        }
        .tweet{
            width: 2%;
        }
        .insta{
            width: 2%;
        }
        .link{
            width: 2%;
        }
        .face{
            width: 2%;
        }
        }
        .copyright{
            display: flex;
            text-align: center;
            justify-content: center;
            margin-left: 80px;
            color: #fff;
            font-size: 15px;
        }
    } 
 }
}
@import "~bootstrap/scss/bootstrap";

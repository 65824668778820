// @import "~bootstrap/scss/bootstrap";
.wrap {
   height: 100vh;
   width: 100vw;
  background-color: #f7f8fc;
 

  

  .switch {

      &.content {
        height: calc(100% - 8rem);
        overflow-y: scroll;
        
        // height: calc(100% - 5rem);
          width:100%;
        //   margin-bottom: 15px;
     -ms-overflow-style: none; /* IE and Edge */
     scrollbar-width: none; /* Firefox */
 
     &::-webkit-scrollbar {
     display: none; /* Chrome, Safari */
     }
      }

      &.full{
       overflow: hidden;
      }

      
     
  // }
     }
  }
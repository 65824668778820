.account-page {
  padding: 2.6rem 0;
  padding-right: 2.5rem;
  margin-left: 50px;
  // overflow: hidden;

  .account {
    margin-bottom: 2rem;

    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 60px;
      // margin-top: 20px;

      li {
        position: relative;

        &:nth-child(n) {
          margin-right: 2rem;
        }

        div {
          font-size: 14px;
          font-weight: 600;
          color: #c4c4c4;
          cursor: pointer;

          &:hover {
            color: #3377ef98;
          }

          &.active {
            color: #3377ef;

            &::before {
              content: "";
              height: 2px;
              width: 40%;
              background: #3377ef;
              position: absolute;
              left: 0;
              top: 1.3rem;
              transition: 0.5s;
            }

            &:hover::before {
              width: 100%;
            }
          }
        }
      }
    }

    @media (min-width: 280px) {
      
    ul {
      list-style: none;
      padding-left: 0;
      margin-left:25px;
      // margin-top: 20px;

      li {
        position: relative;
       

        &:nth-child(n) {
          margin-right: 2rem;
        }

        div {
          font-size: 10px;
          font-weight: 600;
          color: #c4c4c4;
          cursor: pointer;
          margin-left: -20px;
          margin-right: 50px;
          

          &:hover {
            color: #3377ef98;
          }

          &.active {
            color: #3377ef;

            &::before {
              content: "";
              height: 2px;
              width: 30%;
              background: #3377ef;
              position: absolute;
              left: -1rem;
              top: 1.7rem;
              transition: 0.5s;
            }

            &:hover::before {
              width: 75%;
            }
          }
        }
      }
    }
    }
    @media (min-width: 320px) {
      
    ul {
      list-style: none;
      padding-left: 0;
      margin-left:35px;
      // margin-top: 20px;

      li {
        position: relative;
       

        &:nth-child(n) {
          margin-right: 2rem;
        }

        div {
          font-size: 11px;
          font-weight: 600;
          color: #c4c4c4;
          cursor: pointer;
          margin-left: -20px;
          margin-right: 50px;
          

          &:hover {
            color: #3377ef98;
          }

          &.active {
            color: #3377ef;

            &::before {
              content: "";
              height: 2px;
              width: 30%;
              background: #3377ef;
              position: absolute;
              left: -1rem;
              top: 1.7rem;
              transition: 0.5s;
            }

            &:hover::before {
              width: 75%;
            }
          }
        }
      }
    }
    }
    @media (min-width: 360px) {
      
    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 60px;
      // margin-top: 20px;

      li {
        position: relative;
       

        &:nth-child(n) {
          margin-right: 2rem;
        }

        div {
          font-size: 10px;
          font-weight: 600;
          color: #c4c4c4;
          cursor: pointer;
          margin-left: -20px;
          margin-right: 60px;
          

          &:hover {
            color: #3377ef98;
          }

          &.active {
            color: #3377ef;

            &::before {
              content: "";
              height: 2px;
              width: 30%;
              background: #3377ef;
              position: absolute;
              left: -1rem;
              top: 1.7rem;
              transition: 0.5s;
            }

            &:hover::before {
              width: 75%;
            }
          }
        }
      }
    }
    }
    @media (min-width: 375px) {
      
    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 60px;
      // margin-top: 20px;

      li {
        position: relative;
       

        &:nth-child(n) {
          margin-right: 2rem;
        }

        div {
          font-size: 12px;
          font-weight: 600;
          color: #c4c4c4;
          cursor: pointer;
          margin-left: -20px;
          margin-right: 60px;
          

          &:hover {
            color: #3377ef98;
          }

          &.active {
            color: #3377ef;

            &::before {
              content: "";
              height: 2px;
              width: 30%;
              background: #3377ef;
              position: absolute;
              left: -1rem;
              top: 1.7rem;
              transition: 0.5s;
            }

            &:hover::before {
              width: 75%;
            }
          }
        }
      }
    }
    }
  }
  .basic-info {
    background: #ffffff;
    border-radius: 20px;
    margin-left: 50px;
    width: 94%;
    min-height: 450px;

    .smallbox2 {
      padding: 40px 20px;
      #form {
        margin-bottom: 40px;
        margin-left: 50px;
      }
      .forms {
        margin-left: 40px;
        .MuiAvatar-colorDefault {
          background-color: #3377ef;
          // margin-bottom: 45px;
        }
        .error-text {
          color: #f5390a !important;
          font-weight: 400;
          font-size: 13px;
          margin-top: -30px !important;
        }
        .form-text {
          font-size: 12px !important;
          font-weight: 700;
          margin-bottom: -5px;
        }
        .text {
          font-size: 12px !important;
          font-weight: 700;
          margin-top: 5px;
          margin-left: -250px;
        }
        .css-wmw4vi-ReactDropdownSelect {
          border-radius: 5px;
          
          &:focus-within {
            outline: none;
            box-shadow: 0 0 0 0px;
          }
        }
        .input {
          outline: none;
          font-size: 14px;
          font-weight: 400;
          border-radius: 5px;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 22px;
          //   color:#c0c0c0;
        }
        .input1 {
          outline: none;
          border: 0 !important;
          font-size: 14px;
          font-weight: 400;
          border-radius: 5px;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 22px;
        }

        i {
          position: absolute;
          left: 90%;
          top: 5%;
          cursor: pointer;
          color: #c4c4c4 !important;
        }

        .input-wrapper {
          border: 1px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;

          &:focus-within {
            border: 1px solid #3377ef !important;
          }
        }
        .input1-wrapper {
          border: 0px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;
          background-color: #f3f3f3;
          color: #000 !important;

          &:focus-within {
            border: 1px solid #3377ef !important;
          }
        }

        .error-text {
          color: #f5390a !important;
          font-weight: 400;
          font-size: 13px;
        }

      
        .state {
          position: absolute;
          // top:0px;
          // right: 53px!important;
          position: relative;
          padding: 7px 20px;
          border: 1px solid #c4c4c4 !important;
          // color: #757575!important;
          font-size: 14px;
          font-weight: 500;
          border-radius: 5px;
          margin-top: 23px;
          margin-bottom: 30px;
          // overflow-y: scroll;

          .states {
            list-style-type: none;
            right: 0px;
            left: 0px;
            top: 40px;
            max-height: 120px !important;
            overflow-y: scroll;
            z-index: 30001;
            font-size: 12px;
            color: #000;
            // margin-right: -100px!important;
            // padding: 10px 10px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            li {
              background-color: #fff;
              padding: 13px 20px;
              cursor: pointer;
              // border: 1px solid #606060;
              // position: absolute;
              // left: 80px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

              &:active {
                background-color: #3377ef;
                color: #fff;
              }
              &:hover {
                background-color: #3377ef;
                color: #fff;
              }
            }
          }
        }
      }
      .signupbtn {
        background-color: #3377ef;
        color: #fff;
        // text-align:right;
        float: right;
        padding: 10px 30px;
        border-radius: 10px;
        margin-right: 60px;
        margin-top: 25px;
        font-size: 13px;
        font-weight: 600;
        border: 0px;
      }
      .disabled{
         background-color: #D7E5FE;
         color: #606060;
         padding: 10px 30px!important;
         font-size: 13px;
         margin-right: 55px;
         margin-top: 25px;
      }
      .button {
        // margin-left: 50px;
        margin-top: 35px;
        display: block !important;

        .btn {
          text-transform: none !important;
        }
        .btn-primary {
          background-color: #3377ef !important;
        }

        .pad {
          padding: 15px 150px;
        }
      }

      p {
        color: #3377ef !important;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        margin-top: 20% !important;
        padding-top: 20px;
      }
      .images {
        display: flex;

        .custom {
          margin-top: 20px;
          margin-left: 60px;
          transition: all 1.5s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
        .merchant {
          margin-top: 20px;
          margin-left: 50px;
          transition: all 1.5s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
        .customer {
          font-weight: 500;
          text-align: center;
          margin-left: 55px;
          margin-top: -10px !important;

          &:hover {
            color: #0e52c1 !important;
          }
        }
      }
    }
    @media (min-width:280px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 450px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 40px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          .error {
            color: #e2574c;
            font-size: 14px;
          }
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 80%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(0%,-120%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(0%,-120%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:320px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 450px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 40px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 85%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(25%,-120%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(25%,-120%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:360px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 450px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 40px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 90%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(0%,-120%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(0%,-120%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:411px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 450px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 40px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 90%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(-20%,-120%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(-20%,-120%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:768px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 450px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 40px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 85%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(-20%,-120%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(30%,-80%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:1024px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 20px;
      width: 100%;
      min-height: 650px;

      .smallbox2 {
        padding: 40px 20px;
        margin-bottom: -70px;
        #form {
          margin-bottom: 65px;
          margin-left: 20px;
        }
        .forms {
          margin-left: 10px;
          margin-bottom: 50px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 85%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 90px;
          margin-top: 50px;
          font-size: 13px;
          transform: translate(30%,0%);
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 90px;
           margin-top: 50px;
           transform: translate(30%,0%);
        }
       
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }
    @media (min-width:1200px) {

      background: #ffffff;
      border-radius: 20px;
      margin-left: 50px;
      width: 94%;
      min-height: 450px;
  
      .smallbox2 {
        padding: 40px 20px;
        #form {
          margin-bottom: 40px;
          margin-left: 50px;
        }
        .forms {
          margin-left: 40px;
          margin-bottom: 0px;
          .MuiAvatar-colorDefault {
            background-color: #3377ef;
            // margin-bottom: 45px;
          }
          .form-text {
            font-size: 12px !important;
            font-weight: 700;
            margin-bottom: -5px;
          }
          .text {
            font-size: 12px !important;
            font-weight: 700;
            margin-top: 5px;
            margin-left: -250px;
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
            //   color:#c0c0c0;
          }
          .input1 {
            outline: none;
            border: 0 !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
  
          i {
            position: absolute;
            left: 90%;
            top: 5%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
  
          .input-wrapper {
            border: 1px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
          .input1-wrapper {
            border: 0px solid #c4c4c4 !important;
            border-radius: 5px;
            position: relative;
            background-color: #f3f3f3;
            color: #000 !important;
  
            &:focus-within {
              border: 1px solid #3377ef !important;
            }
          }
  
          .error-text {
            color: #f5390a !important;
            font-weight: 400;
            font-size: 13px;
          }
  
        
          .state {
            position: absolute;
            // top:0px;
            // right: 53px!important;
            position: relative;
            padding: 7px 20px;
            border: 1px solid #c4c4c4 !important;
            // color: #757575!important;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;
            margin-top: 23px;
            margin-bottom: 30px;
            // overflow-y: scroll;
  
            .states {
              list-style-type: none;
              right: 0px;
              left: 0px;
              top: 40px;
              max-height: 120px !important;
              overflow-y: scroll;
              z-index: 30001;
              font-size: 12px;
              color: #000;
              // margin-right: -100px!important;
              // padding: 10px 10px;
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              li {
                background-color: #fff;
                padding: 13px 20px;
                cursor: pointer;
                // border: 1px solid #606060;
                // position: absolute;
                // left: 80px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  
                &:active {
                  background-color: #3377ef;
                  color: #fff;
                }
                &:hover {
                  background-color: #3377ef;
                  color: #fff;
                }
              }
            }
          }
        }
        .signupbtn {
          background-color: #3377ef;
          color: #fff;
          // text-align:right;
          float: right;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 87px;
          margin-top: 25px;
          font-size: 13px;
          font-weight: 600;
          border: 0px;
        }
        .disabled{
           background-color: #D7E5FE;
           color: #606060;
           padding: 10px 30px!important;
           font-size: 13px;
           margin-right: 87px;
           margin-top: 25px;
        }
        .button {
          // margin-left: 50px;
          margin-top: 35px;
          display: block !important;
  
          .btn {
            text-transform: none !important;
          }
          .btn-primary {
            background-color: #3377ef !important;
          }
  
          .pad {
            padding: 15px 150px;
          }
        }
  
        p {
          color: #3377ef !important;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-top: 20% !important;
          padding-top: 20px;
        }
        .images {
          display: flex;
  
          .custom {
            margin-top: 20px;
            margin-left: 60px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .merchant {
            margin-top: 20px;
            margin-left: 50px;
            transition: all 1.5s ease;
  
            &:hover {
              transform: scale(1.1);
            }
          }
          .customer {
            font-weight: 500;
            text-align: center;
            margin-left: 55px;
            margin-top: -10px !important;
  
            &:hover {
              color: #0e52c1 !important;
            }
          }
        }
      }
    }

  }
  
  .security {
    .smallbox2 {
      padding: 30px 30px;
      
      .forms {
        padding: 0px 10px;
        margin-left: 40px;
       
       
        .form-text {
          //   font-size: 150x!important;
          font-weight: 550;
          //   margin-bottom: -5px;
        }
        .smalltext {
          //  margin:0;
          //  margin-bottom:10px;
          .pen {
            cursor: pointer;
          }
          h5 {
            color: #c4c4c4;
            font-size: 13px;
            //  margin-top:5px;
            margin-right: 15px;
          }

          &:hover a {
            text-decoration: none !important;
          }
        }
        .input {
          outline: none;
          font-size: 14px;
          font-weight: 400;
          border-radius: 5px;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 22px;
        }
        i {
          position: absolute;
          left: 85%;
          top: 7%;
          cursor: pointer;
          color: #c4c4c4 !important;
        }
      }

      .input-wrapper {
        border: 1px solid #c4c4c4 !important;
        border-radius: 5px;
        position: relative;

        &:focus-within {
          border: 1px solid #3377ef !important;
        }
      }

      .error-text {
        color: #f5390a !important;
        font-weight: 400;
        font-size: 13px;
      }

      
    }
    .form {
      margin-left: -150px;
      padding: 20px 30px;
    }
    .signupbtn {
      background-color: #3377ef;
      color: #fff;
      // text-align:right;
      // float: right;
      padding: 10px 70px;
      border-radius: 10px;
      // margin-right: 0px;
      margin-bottom: 30px;
      font-size: 13px;
      font-weight: 600;
      border: 0px;
    }
    .signup {
      background-color: #3377ef;
      color: #fff;
      border: 0px;
      border-radius: 5px;
      font-size: 13px;
      padding: 10px 45px;
    }
    .disabled {
      background-color: #d7e5fe;
      color: #606060;
      padding: 10px 70px;
      border-radius: 10px;
      margin-right: 0px;
      margin-bottom: 30px;
      font-size: 13px;
    }
    @media (min-width: 280px) {
      margin-left: 20px;
      width: 100%;
      
      .smallbox2 {
        padding: 30px 20px;
        
        .forms {
          padding: 0px 10px;
          margin-left: 5px;
         
         
          .form-text {
            //   font-size: 150x!important;
            font-weight: 550;
            //   margin-bottom: -5px;
          }
          .smalltext {
            //  margin:0;
            //  margin-bottom:10px;
            .pen {
              cursor: pointer;
            }
            h5 {
              color: #c4c4c4;
              font-size: 13px;
              //  margin-top:5px;
              margin-right: 15px;
            }
  
            &:hover a {
              text-decoration: none !important;
            }
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
          i {
            position: absolute;
            left: 85%;
            top: 7%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
        }
  
        .input-wrapper {
          border: 1px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;
  
          &:focus-within {
            border: 1px solid #3377ef !important;
          }
        }
  
        .error-text {
          color: #f5390a !important;
          font-weight: 400;
          font-size: 13px;
        }
  
        
      }
      .signupbtn {
        background-color: #3377ef;
        color: #fff;
        // text-align:right;
        float: right;
        padding: 10px 10px;
        width: 90%;
        border-radius: 10px;
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 13px;
        font-weight: 600;
        border: 0px;
      }
      .signup {
        background-color: #3377ef;
        color: #fff;
        border: 0px;
        border-radius: 5px;
        font-size: 13px;
        padding: 10px 45px;
      }
      .disabled {
        background-color: #d7e5fe;
        color: #606060;
        padding: 10px 10px;
        width: 90%;
        border-radius: 10px;
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 13px;
      }
    }  
    @media (min-width: 320px) {
      margin-left: 20px;
      width: 100%;
      
      .smallbox2 {
        padding: 30px 20px;
        
        .forms {
          padding: 0px 10px;
          margin-left: 20px;
         
         
          .form-text {
            //   font-size: 150x!important;
            font-weight: 550;
            //   margin-bottom: -5px;
          }
          .smalltext {
            //  margin:0;
            //  margin-bottom:10px;
            .pen {
              cursor: pointer;
            }
            h5 {
              color: #c4c4c4;
              font-size: 13px;
              //  margin-top:5px;
              margin-right: 15px;
            }
  
            &:hover a {
              text-decoration: none !important;
            }
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
          i {
            position: absolute;
            left: 85%;
            top: 7%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
        }
  
        .input-wrapper {
          border: 1px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;
  
          &:focus-within {
            border: 1px solid #3377ef !important;
          }
        }
  
        .error-text {
          color: #f5390a !important;
          font-weight: 400;
          font-size: 13px;
        }
  
        
      }
    }  
    @media (min-width: 360px) {
      margin-left: 20px;
      width: 100%;
      
      .smallbox2 {
        padding: 30px 20px;
        
        .forms {
          padding: 0px 10px;
          margin-left: 20px;
         
         
          .form-text {
            //   font-size: 150x!important;
            font-weight: 550;
            //   margin-bottom: -5px;
          }
          .smalltext {
            //  margin:0;
            //  margin-bottom:10px;
            .pen {
              cursor: pointer;
            }
            h5 {
              color: #c4c4c4;
              font-size: 13px;
              //  margin-top:5px;
              margin-right: 15px;
            }
  
            &:hover a {
              text-decoration: none !important;
            }
          }
          .input {
            outline: none;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 22px;
          }
          i {
            position: absolute;
            left: 85%;
            top: 7%;
            cursor: pointer;
            color: #c4c4c4 !important;
          }
        }
  
        .input-wrapper {
          border: 1px solid #c4c4c4 !important;
          border-radius: 5px;
          position: relative;
  
          &:focus-within {
            border: 1px solid #3377ef !important;
          }
        }
  
        .error-text {
          color: #f5390a !important;
          font-weight: 400;
          font-size: 13px;
        }
  
        
      }
    }  
  
  }
}

.user {
  width: 94%;
  min-height: 450px;
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 850px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  .customer-list {
    background: #ffffff;
    border-radius: 20px;
    margin-left: 40px;
    width: 94%;
    padding-bottom: -60px;
    .profile {
      padding-left: 2rem !important;
      .name {
      }
      thead {
        margin-right: 120px !important;
        tr {
          th {
            font: size 13px !important;
            font-weight: 700 !important;
            color: #000000;
            padding: 1.5rem 1rem 1rem 2.5rem;
          }
        }
      }

      tbody {
        .d-table-row {
          .verified {
            color: #34cf4d;
          }
          .unverified {
            color: #e2574c;
          }

          .more {
            border: 1px solid #e2574c;
            border-radius: 5px;
            color: #e2574c;
            width: 100%;
            // padding: 10px 25px;

            &:hover {
              text-decoration: none !important;
            }

            .dropdownmenu {
              display: none;
              background: #ffffff;
              box-shadow: 0px 8px 24px rgba(51, 119, 239, 0.15);
              left: 0;
              right: 0;
              top: 2.4rem !important;
              z-index: -2;
              border-radius: 0px 0px 10px 10px;

              li {
                list-style: none;
                font-size: 11px;
                // margin-left: -15px; 3377ef

                &:first-child {
                  // margin-left: -15px;
                  .text-center {
                    padding: 10px 20px;
                    color: #3377ef;
                    margin-left: -10px;

                    &:hover {
                      background: #3377ef;
                      color: #ffffff;
                      margin-left: 0px;
                    }
                  }
                }

                &:last-child {
                  .text-center {
                    padding: 10px 20px;
                    color: #e2574c;
                    border-radius: 0px 0px 10px 10px;

                    &:hover {
                      background: #e2574c;
                      color: #ffffff;
                    }
                  }
                }
              }
            }

            &:hover {
              background: #ffffff;

              .dropdownmenu {
                display: block;
              }
            }

            // &.active {
            //    background: #3377EF !important;
            //    color: #ffffff;
            // }
          }

          /*.approve {
                        background: rgba(52, 207, 77, 0.2);
                        border-radius: 5px;
                        font: normal .8rem 'SofiaPro';
                        color: #34CF4D;
                        padding: .6rem 1.45rem;
                        
                        &:hover {
                           background: #34CF4D;
                           color: #ffffff;
                        }
                     }
                     
                     .disapprove {
                        background: rgba(244, 67, 54, 0.2);
                        border-radius: 5px;
                        font: normal .8rem 'SofiaPro';
                        color: #E2574C;
                        padding: .6rem .9rem;
      
                        &:hover {
                           background: #E2574C;
                           color: #ffffff;
                        }
                     } */

          &:nth-child(odd) {
            background: #fbfcff;
          }

          th,
          td {
            font: normal 0.8rem;
            padding: 1.2rem;
            color: #000000;
          }

          th {
            span {
              padding-left: 0.6rem;
            }
          }

          &:hover {
            background: #f7f8fc;
          }

          .form-check {
            padding: 0;

            input {
              margin: 0;
              margin-left: -0.8rem;
            }
          }

          &:nth-child(odd) {
            background: #fbfcff;
          }

          th {
            font-size: 12px;
            // padding: 1.2rem;
            color: #000000;
          }
          td {
            font-size: 12px;
            color: #000;
            vertical-align: middle;
          }

          th {
            span {
              padding-left: 0.6rem;
            }
          }

          &:hover {
            background: #fbfbfc;
          }

          .form-check {
            padding: 0;

            input {
              margin: 0;
              margin-left: -0.8rem;
            }
          }
        }

        .t-row {
          th,
          td {
            padding: 1.7rem 0 1.7rem 2rem;
          }
        }
      }
    }
  }
  @media (min-width: 320px) {
    width: 100%;
    min-height: 450px;
    
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 100px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 360px) {
    width: 100%;
    min-height: 450px;
    
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 100px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 411px) {
    width: 100%;
    min-height: 450px;
    
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 768px) {
    width: 100%;
    min-height: 450px;
    
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 600px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 1024px) {
    width: 100%;
    min-height: 450px;
    
  .number {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 60px;
  }
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 650px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 1200px) {
    width: 100%;
    min-height: 450px;
    
  
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 750px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  @media (min-width: 1400px) {
    width: 100%;
    min-height: 450px;
    
  
  .newuse {
    .new {
      color: #fff;
      // border: 1px solid #3377ef;
      background: #3377ef;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 11px;
      float: right;
      justify-content: right;
      place-items: right;
      // margin-left: 900px;
      // margin-top: 40px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  
  }
  
}
.security {
  background: #ffffff;
  border-radius: 20px;
  margin-left: 50px;
  width: 94%;
  min-height: 450px;
}

#mode {
 
  p {
    color: #606060;
    font-weight: 600;
  }
  .delete {
   margin-top: 10px;
  }
  .white {
    border: 1px solid #3377ef;
    color: #3377ef;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
     
    &:hover a{
      background-color: #3377ef;
      color:#fff;
      text-decoration: none!important;
    }
  }
  .blue {
    background-color: #3377ef;
    border: 1px solid #3377ef;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;

    &:hover {
      background-color: #fff;
      color: #3377ef;
    }
    
  }

  @media (min-width:'360px') {
    p{
      font-size: 15px;
    }
  }
}
.ReactModal__Content,
.ReactModal__Content--after-open {
  // position: relative;
  &::-webkit-scrollbar {
    /* Chrome, Safari */
    width: .2rem; /* Safari and Chrome */
    height:10%!important;
    position: absolute;
    right: 0px;
    top: -40px!important;
 }
 
 &::-webkit-scrollbar-track {
  background-color: #fff;
    border-radius: 30px;
    height:10px;
    position: absolute;
    right: 0;
 }
 
 &::-webkit-scrollbar-thumb {
    background-color: #3377ef;
    border-radius: 30px;
    width: .2rem;
 }
  .lock {
    margin-left: 20px;
    
  }
  .pin-lock {
    margin-left: 40%;
    margin-bottom: 10px;
  }
  .formed {
    margin-left: 60px !important;
    // margin-bottom: -30px;
    padding: 5px 0px;

    .formtext {
      font-size: 15px !important;
      font-weight: 700;
      // margin-bottom: 20px !important;
      margin-left: -60px !important;
    }
    .input {
      outline: none;
      font-size: 17px; 
      font-weight: 600;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      padding-top: 10px;
      text-align: center;
      padding-bottom: 10px;
      width:60px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 40px;
      margin-left: -30px!important;
      
      &:focus-within{
         border: 1px solid #3377ef!important;  
      }
   }
    
  }
  .userbtn {
    background-color: #3377ef;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 165px!important;
  }
  .pinbtn {
    background-color: #3377ef;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin-top: 30px;
    margin-left: 140px!important;
  }

  @media (min-width: 280px) {
    .formed{
      margin-top: 60px;
      margin-left: -20px!important;
      .input{
        padding-top: 5px;
        padding-bottom: 5px;
        width: 40px;
      }
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(60%,-20%);
        margin-left: 0px !important;
        
      }

    }
    .signupbtn{
      margin-left: 100px!important;
      margin-top: 30px!important;
    }
    .userbtn {
      margin-left: 120px!important;
    }
    .lock {
      margin-left: -140px!important;
    }
  }
  @media (min-width: 320px) {
    .formed{
      margin-top: 60px;
      margin-left: 20px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(-10%,-15%);
        margin-left: 0px !important;
        
      }
    }
    .signupbtn{
      margin-left: 110px!important;
      margin-top: 0px!important;
    }
    .lock {
      margin-left: 30px!important;
    }
    .pinbtn {
      background-color: #3377ef;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      margin-top: 10px!important;
      margin-left: 100px!important;
    }
  }
  @media (min-width: 360px) {
    .formed{
      margin-top: 60px;
      margin-left: 5px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(45%,-15%);
        margin-left: 0px !important;
        
      }

    }
    .lock {
      margin-left: 20px!important;
    }
    .signupbtn{
      margin-left: 150px!important;
    }
    .pinbtn {
      background-color: #3377ef;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      margin-top: 10px!important;
      margin-left: 120px!important;
    }
  }
  @media (min-width: 375px) {
    .formed{
      margin-top: 60px;
      margin-left: 10px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(20%,-15%);
        margin-left: 0px !important;
        
      }

    }
    .pin-lock {
      margin-left: 43%;
      margin-bottom: 10px;
    }
    .signupbtn{
      margin-left: 130px!important;
    }
    .pinbtn {
      background-color: #3377ef;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      margin-top: 30px;
      margin-left: 140px!important;
    }
  }
  @media (min-width: 411px) {
    .formed{
      margin-top: 60px;
      margin-left: 15px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(45%,-15%);
        margin-left: 0px !important;
        
      }

    }
    .lock {
      margin-left: 10px!important;
    }
    .pin-lock {
      margin-left: 42%;
      margin-bottom: 10px;
    }
    .signupbtn{
      margin-left: 150px!important;
    }
    .userbtn {
      margin-left: 150px!important;
      margin-bottom: 10px!important;
    }
    .pinbtn {
      background-color: #3377ef;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      margin-top: 10px!important;
      margin-left: 155px!important;
    }
  }
  @media (min-width: 540px) {
    .formed{
      margin-top: 60px;
      margin-left: 40px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(65%,-20%);
        margin-left: 0px !important;
        
      }

    }
    p {
      margin-left: -50px;
    }
    .userbtn {
      margin-left: 180px!important;
    }
    .newbtn{
      margin-left: 170px!important;
    }
    .lock {
      margin-left: 30px!important;
    }
  }
  @media (min-width: 768px) {
    .lock {
      margin-left: 30px!important;
    }
    .formed{
      margin-top: 60px;
      margin-left: -20px!important;
      .formtext {
        font-size: 10px !important;
        font-weight: 700;
        transform: translate(95%,-25%);
        margin-left: 0px !important;
        
      }

    }
    .pin-lock {
      margin-left: 40%;
      margin-bottom: 10px;
    }
    .newbtn{
      margin-left: 175px!important;
    }
  
  }

  @media (min-width: 1200px) {
    .lock {
      margin-left: 2%!important;
    }
    .formed {
      margin-left: 5px !important;
      // margin-bottom: -30px;
      padding: 5px 0px;
  
      .formtext {
        font-size: 13px !important;
        font-weight: 700;
        transform: translate(85%,-25%);
        margin-left: -60px !important;
      }
    }
    .newbtn{
      margin-top: 40px!important;
      margin-left: 280px!important;
    }
    .userbtn {
      margin-left: 230px!important;
    }
    .pinbtn {
      background-color: #3377ef;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      margin-top: 50px!important;
      margin-left: 220px!important;
    }
  
    p {
      margin-left: -20px;
    }
  }
}
